/*


** - Default CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - Features Area CSS
** - Listings Area CSS
** - Listings Details Area CSS
** - Listings Widget Sidebar CSS
** - About Area CSS
** - Category Area CSS
** - Destinations Area CSS
** - Places Area CSS
** - Feedback Area CSS
** - How It Works Area CSS
** - Events Area CSS
** - Events Details Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Claim Your Business Area CSS
** - Team Area CSS
** - Manage Your Business Area CSS
** - App Download Area CSS
** - Timeline Area CSS
** - Subscribe Area CSS
** - Page Title Area CSS
** - Pricing Area CSS
** - Partner Area CSS
** - Funfacts Area CSS
** - Products Area CSS
** - Products Details Area CSS
** - Cart Area CSS
** - Checkout Area CSS
** - Gallery Area CSS
** - FAQ Area CSS
** - Author Area CSS
** - Pagination CSS
** - Widget Sidebar CSS
** - 404 Error Area CSS
** - Coming Soon Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Go Top CSS
** - All Modal CSS
** - Dashboard CSS
*/

/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
	--fontFamily: "Work Sans", sans-serif;
	--mainColor: #ED1C5F;
	--mainColor2: #0AB3DB;
	--optionalColor: #666666;
	--whiteColor: #ffffff;
	--blackColor: #221638;
	--lightblueColor: #0AB3DB;
	--fontSize: 15px;
	--transition: 0.5s;
}
body {
	padding: 0;
	margin: 0;
	font: {
		size: var(--fontSize);
		family: var(--fontFamily);
	}
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
}
a {
	color: var(--blackColor);
	transition: var(--transition);
	text-decoration: none;
	outline: 0 !important;

	&:hover {
		color: var(--mainColor);
		text-decoration: none;
	}
}
:focus {
	outline: 0 !important;
}
.d-table {
	width: 100%;
	height: 100%;

	&-cell {
		vertical-align: middle;
	}
}
img {
	max-width: 100%;
	height: auto;
}
p {
	color: var(--optionalColor);
	font-size: var(--fontSize);
	margin-bottom: 15px;
	line-height: 1.8;

	&:last-child {
		margin-bottom: 0;
	}
}
.ptb-100 {
	padding: {
		top: 100px;
		bottom: 100px;
	}
}
.pt-100 {
	padding-top: 100px;
}
.pb-100 {
	padding-bottom: 100px;
}
.ptb-70 {
	padding: {
		top: 70px;
		bottom: 70px;
	}
}
.pt-70 {
	padding-top: 70px;
}
.pb-70 {
	padding-bottom: 70px;
}
.container {
	max-width: 1230px;
}
.bg-f9f9f9 {
	background-color: #f9f9f9;
}
.bg-f5f5f5 {
	background-color: #f5f5f5;
}
.bg-main-color {
	background-color: var(--mainColor2);

	.section-title {
		p {
			color: var(--blackColor);
		}
	}
}
/*section-title*/
.section-title {
	text-align: center;
	max-width: 760px;
	margin: {
		left: auto;
		right: auto;
		bottom: 60px;
	}
	h2 {
		font-size: 30px;
		margin-bottom: 15px;

		span {
			color: var(--mainColor);
		}
	}
	&.text-left {
		position: relative;
		max-width: 100%;
		margin: {
			left: 0;
			right: 0;
			bottom: 40px;
		}
		h2 {
			margin-bottom: 0;
		}
		.link-btn {
			position: absolute;
			display: inline-block;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			font-weight: 600;

			i {
				transition: var(--transition);
				color: var(--blackColor);
			}
			&:hover {
				i {
					margin-left: 5px;
					color: var(--mainColor);
				}
			}
		}
	}
}
/*default-btn*/
.default-btn {
	display: inline-block;
	transition: var(--transition);
	border: none;
	text-align: center;
	border: 1px solid #96e3e3;
	box-shadow: 4px 4px #b2eaea;
	background-color: var(--whiteColor);
	color: var(--blackColor);
	font: {
		size: var(--fontSize);
		weight: 600;
	}
	padding: {
		left: 30px;
		right: 30px;
		top: 13px;
		bottom: 13px;
	}
	&:hover {
		border-color: var(--mainColor);
		box-shadow: 4px 4px var(--mainColor);
		color: var(--blackColor);
	}
}
/*form-control*/
.form-control {
	border-radius: 0;
	background-color: #f5f5f5 !important;
	box-shadow: unset !important;
	transition: var(--transition);
	border: none !important;
	height: 45px;
	padding-left: 15px;
	color: var(--blackColor);
	font: {
		size: 14.5px;
		weight: 400;
	}
	&::placeholder {
		color: #999999;
		transition: var(--transition);
	}
	&:focus {
		&::placeholder {
			color: transparent;
		}
	}
}
textarea.form-control {
	padding-top: 15px;
	height: auto;
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area {
	position: relative;
	padding: 0;

	&.is-sticky {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999;
		box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
		background-color: var(--whiteColor) !important;
		animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	}
}
.miran-responsive-nav {
	display: none;
}
.miran-nav {
	.container-fluid {
		padding: {
			left: 20px;
			right: 20px;
		}
	}
	.navbar {
		position: inherit;
		padding: 0;

		.navbar-brand {
			font-size: inherit;
			line-height: 1;
			padding: 0;
		}
		ul {
			padding-left: 0;
			list-style-type: none;
			margin-bottom: 0;
		}
		.navbar-nav {
			margin: {
				left: auto;
				right: auto;
			}
			.nav-item {
				position: relative;
				margin: {
					left: 15px;
					right: 15px;
				}
				a {
					color: var(--blackColor);
					font: {
						size: 16px;
						weight: 500;
					}
					padding: {
						left: 0;
						right: 0;
						top: 30px;
						bottom: 30px;
					}
					&:hover,
					&:focus,
					&.active {
						color: var(--mainColor);
					}
				}
				.dropdown-toggle {
					padding-right: 18px;

					&::after {
						display: none;
					}
					&::before {
						content: "\f106";
						position: absolute;
						right: 0;
						top: 32px;
						font: {
							size: 15px;
							family: Flaticon;
						}
					}
				}
				&:last-child {
					margin-right: 0;
				}
				&:first-child {
					margin-left: 0;
				}
				&:hover,
				&.active {
					a {
						color: var(--mainColor);
					}
				}
				.dropdown-menu {
					border: none;
					top: 84px;
					left: 0;
					z-index: 99;
					opacity: 0;
					width: 250px;
					display: block;
					border-radius: 0;
					padding: 10px 0;
					margin-top: 15px;
					position: absolute;
					visibility: hidden;
					background: var(--whiteColor);
					transition: all 0.2s ease-in-out;
					border-top: 3px solid var(--mainColor);
					box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

					li {
						margin: 0;

						a {
							padding: 8px 20px;
							position: relative;
							display: block;
							color: var(--blackColor);
							cursor: pointer;
							font: {
								size: 16px;
								weight: 500;
							}
							i {
								margin: 0;
								position: absolute;
								top: 50%;
								font-size: 20px;
								transform: translateY(-50%);
								right: 15px;
							}
							&:hover,
							&:focus,
							&.active {
								color: var(--mainColor);
							}
						}
						.dropdown-menu {
							top: 0;
							opacity: 0;
							left: -250px;
							margin-top: 15px;
							visibility: hidden;

							li {
								a {
									color: var(--blackColor);

									&:hover,
									&:focus,
									&.active {
										color: var(--mainColor);
									}
								}
								.dropdown-menu {
									top: 0;
									opacity: 0;
									left: 250px;
									visibility: hidden;

									li {
										a {
											color: var(--blackColor);

											&:hover,
											&:focus,
											&.active {
												color: var(--mainColor);
											}
										}
										.dropdown-menu {
											top: 0;
											opacity: 0;
											left: -250px;
											visibility: hidden;

											li {
												a {
													color: var(--blackColor);

													&:hover,
													&:focus,
													&.active {
														color: var(--mainColor);
													}
												}
												.dropdown-menu {
													top: 0;
													opacity: 0;
													left: 250px;
													visibility: hidden;

													li {
														a {
															color: var(
																--blackColor
															);

															&:hover,
															&:focus,
															&.active {
																color: var(
																	--mainColor
																);
															}
														}
														.dropdown-menu {
															top: 0;
															opacity: 0;
															left: -250px;
															visibility: hidden;

															li {
																a {
																	color: var(
																		--blackColor
																	);

																	&:hover,
																	&:focus,
																	&.active {
																		color: var(
																			--mainColor
																		);
																	}
																}
																.dropdown-menu {
																	top: 0;
																	opacity: 0;
																	left: 250px;
																	visibility: hidden;

																	li {
																		a {
																			color: var(
																				--blackColor
																			);

																			&:hover,
																			&:focus,
																			&.active {
																				color: var(
																					--mainColor
																				);
																			}
																		}
																	}
																}
																&.active {
																	a {
																		color: var(
																			--mainColor
																		);
																	}
																}
																&:hover {
																	.dropdown-menu {
																		opacity: 1;
																		visibility: visible;
																	}
																}
															}
														}
														&.active {
															a {
																color: var(
																	--mainColor
																);
															}
														}
														&:hover {
															.dropdown-menu {
																opacity: 1;
																visibility: visible;
															}
														}
													}
												}
												&.active {
													a {
														color: var(--mainColor);
													}
												}
												&:hover {
													.dropdown-menu {
														opacity: 1;
														visibility: visible;
													}
												}
											}
										}
										&.active {
											a {
												color: var(--mainColor);
											}
										}
										&:hover {
											.dropdown-menu {
												opacity: 1;
												visibility: visible;
											}
										}
									}
								}
								&.active {
									a {
										color: var(--mainColor);
									}
								}
								&:hover {
									.dropdown-menu {
										opacity: 1;
										visibility: visible;
									}
								}
							}
						}
						&.active {
							a {
								color: var(--mainColor);
							}
						}
						&:hover {
							.dropdown-menu {
								opacity: 1;
								visibility: visible;
								margin-top: 0;
							}
						}
					}
				}
				&:hover {
					.dropdown-menu {
						opacity: 1;
						visibility: visible;
						margin-top: 0;
					}
				}
			}
		}
		.navbar-search-box {
			margin-left: 40px;
			position: relative;
			width: 210px;

			label {
				margin-bottom: 0;
				position: absolute;
				left: 0;
				top: 1.5px;
				color: var(--mainColor);
				font-size: 18px;
			}
			.input-search {
				border: none;
				border-bottom: 1px solid #e0e0e0;
				display: block;
				width: 100%;
				height: 33px;
				line-height: 33px;
				font-size: 14px;
				background-color: transparent;
				color: var(--blackColor);
				padding: 0 0 0 25px;

				&::placeholder {
					color: #888888;
					transition: var(--transition);
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
		}
		.others-option {
			.option-item {
				margin-left: 25px;

				&:first-child {
					margin-left: 0;
				}
				span {
					display: inline-block;
					cursor: pointer;
					color: #221638;
					transition: var(--transition);
					font-weight: 500;
					position: relative;

					i {
						margin-right: 2px;
						color: var(--mainColor);
						position: relative;
						top: 1px;
					}
					&::before {
						content: "";
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 1px;
						background-color: var(--mainColor);
						transition: var(--transition);
						transform: scaleX(0);
					}
					&:hover {
						color: var(--mainColor);

						&::before {
							transform: scaleX(1);
						}
					}
				}
				.default-btn {
					position: relative;
					box-shadow: unset !important;
					border-radius: 30px;
					background-color: transparent;
					color: var(--mainColor);
					font-weight: 500;
					padding: {
						top: 11px;
						bottom: 11px;
					}
					i {
						margin-right: 5px;
					}
					&:hover {
						background-color: var(--mainColor);
						color: var(--whiteColor);
					}
				}
			}
		}
	}
}
.navbar-style-two {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.16);
	top: 0;
	left: 0;
	right: 0;
	height: auto;
	z-index: 999;

	.miran-nav {
		.navbar {
			.navbar-nav {
				.nav-item {
					a {
						color: var(--whiteColor);

						&:hover,
						&:focus,
						&.active {
							color: var(--mainColor);
						}
					}
					&:hover,
					&.active {
						a {
							color: var(--mainColor);
						}
					}
					.dropdown-menu {
						li {
							a {
								color: var(--blackColor);

								&:hover,
								&:focus,
								&.active {
									color: var(--mainColor);
								}
							}
							.dropdown-menu {
								li {
									a {
										color: var(--blackColor);

										&:hover,
										&:focus,
										&.active {
											color: var(--mainColor);
										}
									}
									.dropdown-menu {
										li {
											a {
												color: var(--blackColor);

												&:hover,
												&:focus,
												&.active {
													color: var(--mainColor);
												}
											}
											.dropdown-menu {
												li {
													a {
														color: var(
															--blackColor
														);

														&:hover,
														&:focus,
														&.active {
															color: var(
																--mainColor
															);
														}
													}
													.dropdown-menu {
														li {
															a {
																color: var(
																	--blackColor
																);

																&:hover,
																&:focus,
																&.active {
																	color: var(
																		--mainColor
																	);
																}
															}
															.dropdown-menu {
																li {
																	a {
																		color: var(
																			--blackColor
																		);

																		&:hover,
																		&:focus,
																		&.active {
																			color: var(
																				--mainColor
																			);
																		}
																	}
																	.dropdown-menu {
																		li {
																			a {
																				color: var(
																					--blackColor
																				);

																				&:hover,
																				&:focus,
																				&.active {
																					color: var(
																						--mainColor
																					);
																				}
																			}
																		}
																	}
																	&.active {
																		a {
																			color: var(
																				--mainColor
																			);
																		}
																	}
																}
															}
															&.active {
																a {
																	color: var(
																		--mainColor
																	);
																}
															}
														}
													}
													&.active {
														a {
															color: var(
																--mainColor
															);
														}
													}
												}
											}
											&.active {
												a {
													color: var(--mainColor);
												}
											}
										}
									}
									&.active {
										a {
											color: var(--mainColor);
										}
									}
								}
							}
							&.active {
								a {
									color: var(--mainColor);
								}
							}
						}
					}
				}
			}
			.navbar-search-box {
				label {
					color: var(--whiteColor);
				}
				.input-search {
					border-bottom: 1px solid rgba(255, 255, 255, 0.88);
					color: var(--whiteColor);

					&::placeholder {
						color: rgba(255, 255, 255, 0.88);
					}
					&:focus {
						&::placeholder {
							color: transparent;
						}
					}
				}
			}
			.others-option {
				.option-item {
					span {
						color: var(--whiteColor);

						i {
							color: var(--whiteColor);
							transition: var(--transition);
						}
						&:hover {
							color: var(--mainColor2);

							i {
								color: var(--mainColor2);
							}
						}
					}
					.default-btn {
						color: var(--mainColor2);
						border-color: var(--mainColor2);

						&:hover {
							background-color: var(--mainColor);
							color: var(--whiteColor);
							border-color: var(--mainColor);
						}
					}
				}
			}
		}
	}
	&.is-sticky {
		background-color: var(--blackColor) !important;
	}
	.others-option-for-responsive {
		.dot-menu {
			.inner {
				.circle {
					background-color: var(--whiteColor);
				}
			}
		}
	}
}
.others-option-for-responsive {
	display: none;

	.dot-menu {
		padding: 0 10px;
		height: 30px;
		cursor: pointer;
		z-index: 9991;
		position: absolute;
		right: 60px;
		top: -34px;

		.inner {
			display: flex;
			align-items: center;
			height: 30px;

			.circle {
				height: 5px;
				width: 5px;
				border-radius: 100%;
				margin: 0 2px;
				transition: var(--transition);
				background-color: var(--blackColor);
			}
		}
		&:hover {
			.inner {
				.circle {
					background-color: var(--mainColor);
				}
			}
		}
	}
	.container {
		position: relative;

		.container {
			position: absolute;
			right: 0;
			top: 15px;
			max-width: 260px;
			margin-left: auto;
			opacity: 0;
			visibility: hidden;
			transition: var(--transition);
			transform: scaleX(0);
			z-index: 2;
			padding: {
				left: 15px;
				right: 15px;
			}
			&.active {
				opacity: 1;
				visibility: visible;
				transform: scaleX(1);
			}
		}
	}
	.option-inner {
		padding: 15px;
		box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
		background-color: var(--whiteColor);

		.others-option {
			text-align: center;

			.option-item {
				display: block;
				margin-top: 15px;

				&:first-child {
					margin-top: 0;
				}
				span {
					display: inline-block;
					cursor: pointer;
					color: #221638;
					transition: var(--transition);
					font-weight: 500;
					position: relative;

					i {
						margin-right: 2px;
						color: var(--mainColor);
						position: relative;
						top: 1px;
					}
					&::before {
						content: "";
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 1px;
						background-color: var(--mainColor);
						transition: var(--transition);
						transform: scaleX(0);
					}
					&:hover {
						color: var(--mainColor);

						&::before {
							transform: scaleX(1);
						}
					}
				}
				.default-btn {
					position: relative;
					box-shadow: unset !important;
					border-radius: 30px;
					background-color: transparent;
					color: var(--mainColor);
					font-weight: 500;
					display: block;
					width: 100%;
					padding: {
						top: 11px;
						bottom: 11px;
					}
					i {
						margin-right: 5px;
					}
					&:hover {
						background-color: var(--mainColor);
						color: var(--whiteColor);
					}
				}
				.navbar-search-box {
					position: relative;

					label {
						margin-bottom: 0;
						position: absolute;
						left: 0;
						top: 1.5px;
						color: var(--mainColor);
						font-size: 18px;
					}
					.input-search {
						border: none;
						border-bottom: 1px solid #e0e0e0;
						display: block;
						width: 100%;
						height: 33px;
						line-height: 33px;
						font-size: 14px;
						background-color: transparent;
						color: var(--blackColor);
						padding: 0 0 0 25px;

						&::placeholder {
							color: #888888;
							transition: var(--transition);
						}
						&:focus {
							&::placeholder {
								color: transparent;
							}
						}
					}
				}
			}
		}
	}
}
// Mobile and iPad Navbar
@media only screen and (max-width: 1199px) {
	.navbar-area {
		padding: {
			top: 15px;
			bottom: 15px;
		}
		&.is-sticky {
			padding: {
				top: 15px;
				bottom: 15px;
			}
		}
		&.navbar-style-two {
			.miran-responsive-nav {
				.mean-container {
					a {
						&.meanmenu-reveal {
							color: var(--whiteColor);

							span {
								background: var(--whiteColor);
							}
						}
					}
				}
			}
		}
	}
	.miran-responsive-nav {
		display: block;

		.miran-responsive-menu {
			position: relative;

			&.mean-container {
				.mean-nav {
					margin-top: 57px;

					ul {
						font-size: 15px;

						li {
							a {
								&.active {
									color: var(--mainColor);
								}
							}
							li {
								a {
									font-size: 14.5px;
								}
							}
						}
					}
				}
				.navbar-nav {
					overflow-y: scroll;
					height: 306px;
					box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
				}
			}
		}
		.mean-container {
			a {
				&.meanmenu-reveal {
					color: var(--blackColor);

					span {
						background: var(--blackColor);
					}
				}
			}
		}
		.dropdown-toggle {
			&::after {
				display: none !important;
			}
		}
		.others-option {
			display: none !important;

			.option-item {
				margin-left: 25px;

				&:first-child {
					margin-left: 0;
				}
				span {
					display: inline-block;
					cursor: pointer;
					color: #221638;
					transition: var(--transition);
					font-weight: 500;
					position: relative;

					i {
						margin-right: 2px;
						color: var(--mainColor);
						position: relative;
						top: 1px;
					}
					&::before {
						content: "";
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 1px;
						background-color: var(--mainColor);
						transition: var(--transition);
						transform: scaleX(0);
					}
					&:hover {
						color: var(--mainColor);

						&::before {
							transform: scaleX(1);
						}
					}
				}
				.default-btn {
					position: relative;
					box-shadow: unset !important;
					border-radius: 30px;
					background-color: transparent;
					color: var(--mainColor);
					font-weight: 500;
					padding: {
						top: 11px;
						bottom: 11px;
					}
					i {
						margin-right: 5px;
					}
					&:hover {
						background-color: var(--mainColor);
						color: var(--whiteColor);
					}
				}
			}
		}
		.navbar-search-box {
			position: absolute;
			top: 0;
			right: 0;
			display: none;

			label {
				margin-bottom: 0;
				position: absolute;
				left: 0;
				top: 1.5px;
				color: var(--mainColor);
				font-size: 18px;
			}
			.input-search {
				border: none;
				border-bottom: 1px solid #e0e0e0;
				display: block;
				width: 100%;
				height: 33px;
				line-height: 33px;
				font-size: 14px;
				background-color: transparent;
				color: var(--blackColor);
				padding: 0 0 0 25px;

				&::placeholder {
					color: #888888;
					transition: var(--transition);
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
		}
		.logo {
			position: relative;
			width: 60%;
			z-index: 999;
		}
	}
	.miran-nav {
		display: none;
	}
	.others-option-for-responsive {
		display: block;
		position: relative;
		z-index: 1000;
	}
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner-area {
	padding: {
		top: 330px;
		bottom: 250px;
	}
	background: {
		image: url(../images/main-banner-bg1.jpg);
		position: center center;
		size: cover;
		repeat: no-repeat;
	}
}
.main-banner-content {
	text-align: center;
	max-width: 950px;
	margin: {
		left: auto;
		right: auto;
	}
	h1 {
		color: var(--whiteColor);
		font-size: 50px;
		margin-bottom: 15px;
	}
	p {
		line-height: initial;
		color: #bcbcbc;
		font-size: 18px;
		margin: 0;
	}
	form {
		margin-top: 40px;
		background-color: var(--whiteColor);
		box-shadow: 5px 5px #30eded;
		border-radius: 100px;
		padding: {
			left: 20px;
			right: 12px;
		}
		.form-group {
			margin: 0;
			position: relative;
			border-right: 1px solid #30eded;
			margin-left: 12px;

			label {
				margin-bottom: 0;
				position: absolute;
				left: 0;
				top: 19px;
				line-height: 1;
				font-size: 23px;
				color: #646464;
				z-index: 2;
			}
			&.category-select {
				border-right: none;
				padding-right: 20px;
			}
			.form-control {
				border: none !important;
				color: var(--blackColor);
				box-shadow: unset !important;
				background-color: transparent !important;
				height: 60px;
				line-height: 60px;
				font: {
					size: var(--fontSize);
					weight: 400;
				}
				padding: {
					top: 2px;
					bottom: 0;
					left: 30px;
					right: 15px;
				}
				&::placeholder {
					transition: var(--transition);
					color: #666666;
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
			.nice-select {
				border-radius: 0;
				border: none;
				float: unset;
				height: 60px;
				line-height: 60px;
				color: #666666;
				font: {
					size: 15px;
					weight: 400;
				}
				padding: {
					left: 30px;
					right: 15px;
					bottom: 0;
					top: 1px;
				}
				&:after {
					border-color: var(--mainColor);
					height: 8px;
					margin-top: -5px;
					right: 0;
					width: 8px;
				}
				.list {
					background-color: var(--whiteColor);
					border-radius: 0;
					box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
					list-style-type: none;
					border: none;
					width: 100%;
					height: 210px;
					overflow-y: scroll;
					margin: {
						bottom: 0;
						top: 0;
					}
					padding: {
						left: 0;
						top: 10px;
						bottom: 10px;
					}
					.option {
						line-height: 38px;
						min-height: 38px;
						color: var(--blackColor);
						position: relative;
						transition: var(--transition);
						padding: {
							left: 38px;
							right: 15px;
						}
						font: {
							size: var(--fontSize);
							weight: 500;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
						&.focus,
						&.selected.focus {
							background-color: transparent !important;
							color: var(--blackColor);
						}
						&::before {
							content: "\ea0f";
							position: absolute;
							left: 12px;
							top: -1px;
							opacity: 0;
							visibility: hidden;
							transition: var(--transition);
							color: var(--mainColor);
							font: {
								family: "boxicons";
								size: 20px;
							}
						}
						&.selected {
							&::before {
								opacity: 1;
								visibility: visible;
							}
							&:hover {
								background-color: var(--mainColor) !important;
								color: var(--whiteColor);

								&::before {
									color: var(--whiteColor);
								}
							}
						}
					}
				}
			}
		}
		.col-lg-4 {
			.form-group {
				margin-left: 0;
			}
		}
		.submit-btn {
			padding-left: 5px;

			button {
				display: block;
				width: 100%;
				background-color: var(--mainColor);
				border: none;
				color: var(--whiteColor);
				font-size: var(--fontSize);
				font-weight: 600;
				padding: 12px 25px;
				border-radius: 30px;
				transition: var(--transition);

				&:hover {
					background-color: var(--blackColor);
					color: var(--whiteColor);
				}
			}
		}
	}
	.popular-search-list {
		padding-left: 25px;
		list-style-type: none;
		text-align: left;
		margin: {
			bottom: 0;
			top: 25px;
		}
		li {
			display: inline-block;
			margin-right: 15px;
			color: var(--whiteColor);

			a {
				display: block;
				line-height: 1;
				color: var(--whiteColor);
				font-weight: 600;
				position: relative;

				&::before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 1px;
					background-color: var(--whiteColor);
					transition: var(--transition);
				}
				&:hover {
					color: var(--mainColor);

					&::before {
						background-color: var(--mainColor);
					}
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
.banner-area {
	padding-top: 150px;
	background: {
		color: #f6f6f6;
		image: url(../images/main-banner-bg2.jpg);
		position: center center;
		size: cover;
		repeat: no-repeat;
	}
}
.banner-content {
	max-width: 840px;
	padding-right: 50px;
	margin: {
		left: auto;
		top: 50px;
	}
	h1 {
		font-size: 50px;
		margin-bottom: 15px;

	}
	p {
		line-height: initial;
		font-size: 18px;
		margin: 0;
	}
	form {
		margin-top: 40px;
		background-color: var(--whiteColor);
		box-shadow: 5px 5px #30eded;
		border-radius: 5px;
		padding: {
			left: 20px;
			right: 12px;
		}
		.form-group {
			margin: 0;
			position: relative;
			border-right: 1px solid #30eded;
			margin-left: 12px;

			label {
				margin-bottom: 0;
				position: absolute;
				left: 0;
				top: 19px;
				line-height: 1;
				font-size: 23px;
				color: #646464;
				z-index: 2;
			}
			&.category-select {
				border-right: none;
				padding-right: 20px;
			}
			.form-control {
				border: none !important;
				color: var(--blackColor);
				box-shadow: unset !important;
				background-color: transparent !important;
				height: 60px;
				line-height: 60px;
				font: {
					size: var(--fontSize);
					weight: 400;
				}
				padding: {
					top: 2px;
					bottom: 0;
					left: 30px;
					right: 15px;
				}
				&::placeholder {
					transition: var(--transition);
					color: #666666;
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
			.nice-select {
				border-radius: 0;
				border: none;
				float: unset;
				height: 60px;
				line-height: 60px;
				color: #666666;
				font: {
					size: 15px;
					weight: 400;
				}
				padding: {
					left: 30px;
					right: 15px;
					bottom: 0;
					top: 1px;
				}
				&:after {
					border-color: var(--mainColor);
					height: 8px;
					margin-top: -5px;
					right: 0;
					width: 8px;
				}
				.list {
					background-color: var(--whiteColor);
					border-radius: 0;
					box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
					list-style-type: none;
					border: none;
					width: 100%;
					height: 210px;
					overflow-y: scroll;
					margin: {
						bottom: 0;
						top: 0;
					}
					padding: {
						left: 0;
						top: 10px;
						bottom: 10px;
					}
					.option {
						line-height: 38px;
						min-height: 38px;
						color: var(--blackColor);
						position: relative;
						transition: var(--transition);
						padding: {
							left: 38px;
							right: 15px;
						}
						font: {
							size: var(--fontSize);
							weight: 500;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
						&.focus,
						&.selected.focus {
							background-color: transparent !important;
							color: var(--blackColor);
						}
						&::before {
							content: "\ea0f";
							position: absolute;
							left: 12px;
							top: -1px;
							opacity: 0;
							visibility: hidden;
							transition: var(--transition);
							color: var(--mainColor);
							font: {
								family: "boxicons";
								size: 20px;
							}
						}
						&.selected {
							&::before {
								opacity: 1;
								visibility: visible;
							}
							&:hover {
								background-color: var(--mainColor) !important;
								color: var(--whiteColor);

								&::before {
									color: var(--whiteColor);
								}
							}
						}
					}
				}
			}
		}
		.col-lg-4 {
			.form-group {
				margin-left: 0;
			}
		}
		.submit-btn {
			padding-left: 5px;

			button {
				display: block;
				width: 100%;
				background-color: var(--mainColor);
				border: none;
				color: var(--whiteColor);
				font-size: var(--fontSize);
				font-weight: 600;
				padding: 12px 15px;
				border-radius: 5px;
				transition: var(--transition);

				&:hover {
					background-color: var(--blackColor);
					color: var(--whiteColor);
				}
			}
		}
	}
	.popular-search-list {
		padding-left: 0;
		list-style-type: none;
		text-align: left;
		margin: {
			bottom: 0;
			top: 25px;
		}
		li {
			display: inline-block;
			margin-right: 15px;
			color: var(--blackColor);

			a {
				display: block;
				line-height: 1;
				color: var(--blackColor);
				font-weight: 600;
				position: relative;

				&::before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 1px;
					background-color: var(--blackColor);
					transition: var(--transition);
				}
				&:hover {
					color: var(--mainColor);

					&::before {
						background-color: var(--mainColor);
					}
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
.banner-image {
	margin-left: -35px;
}
.banner-wrapper-area {
	padding: {
		top: 250px;
		bottom: 250px;
	}
	background: {
		image: url(../images/main-banner-bg3.jpg);
		position: center center;
		size: cover;
		repeat: no-repeat;
	}
}
.banner-wrapper-content {
	max-width: 850px;

	h1 {
		font-size: 50px;
		margin-bottom: 15px;
		color: var(--whiteColor);
	}
	p {
		line-height: initial;
		font-size: 18px;
		color: #eaeaea;
		margin: 0;
	}
	form {
		margin-top: 40px;
		background-color: var(--whiteColor);
		box-shadow: 5px 5px #30eded;
		border-radius: 5px;
		padding: {
			left: 20px;
			right: 12px;
		}
		.form-group {
			margin: 0;
			position: relative;
			border-right: 1px solid #30eded;
			margin-left: 12px;

			label {
				margin-bottom: 0;
				position: absolute;
				left: 0;
				top: 19px;
				line-height: 1;
				font-size: 23px;
				color: #646464;
				z-index: 2;
			}
			&.category-select {
				border-right: none;
				padding-right: 20px;
			}
			.form-control {
				border: none !important;
				color: var(--blackColor);
				box-shadow: unset !important;
				background-color: transparent !important;
				height: 60px;
				line-height: 60px;
				font: {
					size: var(--fontSize);
					weight: 400;
				}
				padding: {
					top: 2px;
					bottom: 0;
					left: 30px;
					right: 15px;
				}
				&::placeholder {
					transition: var(--transition);
					color: #666666;
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
			.nice-select {
				border-radius: 0;
				border: none;
				float: unset;
				height: 60px;
				line-height: 60px;
				color: #666666;
				font: {
					size: 15px;
					weight: 400;
				}
				padding: {
					left: 30px;
					right: 15px;
					bottom: 0;
					top: 1px;
				}
				&:after {
					border-color: var(--mainColor);
					height: 8px;
					margin-top: -5px;
					right: 0;
					width: 8px;
				}
				.list {
					background-color: var(--whiteColor);
					border-radius: 0;
					box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
					list-style-type: none;
					border: none;
					width: 100%;
					height: 210px;
					overflow-y: scroll;
					margin: {
						bottom: 0;
						top: 0;
					}
					padding: {
						left: 0;
						top: 10px;
						bottom: 10px;
					}
					.option {
						line-height: 38px;
						min-height: 38px;
						color: var(--blackColor);
						position: relative;
						transition: var(--transition);
						padding: {
							left: 38px;
							right: 15px;
						}
						font: {
							size: var(--fontSize);
							weight: 500;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
						&.focus,
						&.selected.focus {
							background-color: transparent !important;
							color: var(--blackColor);
						}
						&::before {
							content: "\ea0f";
							position: absolute;
							left: 12px;
							top: -1px;
							opacity: 0;
							visibility: hidden;
							transition: var(--transition);
							color: var(--mainColor);
							font: {
								family: "boxicons";
								size: 20px;
							}
						}
						&.selected {
							&::before {
								opacity: 1;
								visibility: visible;
							}
							&:hover {
								background-color: var(--mainColor) !important;
								color: var(--whiteColor);

								&::before {
									color: var(--whiteColor);
								}
							}
						}
					}
				}
			}
		}
		.col-lg-4 {
			.form-group {
				margin-left: 0;
			}
		}
		.submit-btn {
			padding-left: 5px;

			button {
				display: block;
				width: 100%;
				background-color: var(--mainColor);
				border: none;
				color: var(--whiteColor);
				font-size: var(--fontSize);
				font-weight: 600;
				padding: 12px 15px;
				border-radius: 5px;
				transition: var(--transition);

				&:hover {
					background-color: var(--blackColor);
					color: var(--whiteColor);
				}
			}
		}
	}
	.popular-search-list {
		padding-left: 0;
		list-style-type: none;
		text-align: left;
		margin: {
			bottom: 0;
			top: 25px;
		}
		li {
			display: inline-block;
			margin-right: 15px;
			color: var(--mainColor);

			a {
				display: block;
				line-height: 1;
				color: var(--whiteColor);
				font-weight: 600;
				position: relative;

				&::before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 1px;
					background-color: var(--whiteColor);
					transition: var(--transition);
				}
				&:hover {
					color: var(--mainColor);

					&::before {
						background-color: var(--mainColor);
					}
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
.home-slider-area {
	position: relative;

	.banner-content {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 80px;
		z-index: 3;
		background-color: var(--whiteColor);
		max-width: 1200px;
		border-radius: 10px;
		padding-right: 0;
		padding: 30px;
		margin: {
			left: auto;
			right: auto;
			top: 0;
		}
		h1 {
			font-size: 40px;
		}
		p {
			font-size: 16px;
		}
		form {
			box-shadow: unset;
			margin-top: 30px;
			background-color: rgb(247, 247, 247);

			.form-group {
				border-color: #ede9e9;

				.nice-select {
					background-color: rgb(247, 247, 247);
				}
			}
		}
	}
	.owl-theme {
		.owl-dots {
			margin-top: 0 !important;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 25px;

			.owl-dot {
				span {
					width: 12px;
					height: 12px;
					margin: 0 4px;
					background: var(--whiteColor);
					border-radius: 50%;
					transition: var(--transition);
				}
				&:hover,
				&.active {
					span {
						background-color: var(--mainColor);
					}
				}
			}
		}
	}
}
.single-banner-item {
	height: 100vh;
	background: {
		color: var(--blackColor);
		position: center center;
		size: cover;
		repeat: no-repeat;
	}
	&.item-bg1 {
		background-image: url(../images/main-banner-bg4.jpg);
	}
	&.item-bg2 {
		background-image: url(../images/main-banner-bg5.jpg);
	}
	&.item-bg3 {
		background-image: url(../images/main-banner-bg6.jpg);
	}
}
.map-home-area {
	position: relative;

	#main-full-map {
		height: 100vh;
		width: 100%;
	}
	form {
		background-color: var(--whiteColor);
		box-shadow: 5px 5px #30eded;
		border-radius: 5px;
		position: absolute;
		left: 0;
		bottom: 30px;
		right: 0;
		height: auto;
		max-width: 1230px;
		padding: {
			left: 20px;
			right: 12px;
		}
		margin: {
			left: auto;
			right: auto;
		}
		.form-group {
			margin: 0;
			position: relative;
			border-right: 1px solid #30eded;
			margin-left: 12px;

			label {
				margin-bottom: 0;
				position: absolute;
				left: 0;
				top: 19px;
				line-height: 1;
				font-size: 23px;
				color: #646464;
				z-index: 2;
			}
			&.category-select {
				border-right: none;
				padding-right: 20px;
			}
			.form-control {
				border: none !important;
				color: var(--blackColor);
				box-shadow: unset !important;
				background-color: transparent !important;
				height: 60px;
				line-height: 60px;
				font: {
					size: var(--fontSize);
					weight: 400;
				}
				padding: {
					top: 2px;
					bottom: 0;
					left: 30px;
					right: 15px;
				}
				&::placeholder {
					transition: var(--transition);
					color: #666666;
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
			.nice-select {
				border-radius: 0;
				border: none;
				float: unset;
				height: 60px;
				line-height: 60px;
				color: #666666;
				font: {
					size: 15px;
					weight: 400;
				}
				padding: {
					left: 30px;
					right: 15px;
					bottom: 0;
					top: 1px;
				}
				&:after {
					border-color: var(--mainColor);
					height: 8px;
					margin-top: -5px;
					right: 0;
					width: 8px;
				}
				.list {
					background-color: var(--whiteColor);
					border-radius: 0;
					box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
					list-style-type: none;
					border: none;
					width: 100%;
					height: 210px;
					overflow-y: scroll;
					margin: {
						bottom: 0;
						top: 0;
					}
					padding: {
						left: 0;
						top: 10px;
						bottom: 10px;
					}
					.option {
						line-height: 38px;
						min-height: 38px;
						color: var(--blackColor);
						position: relative;
						transition: var(--transition);
						padding: {
							left: 38px;
							right: 15px;
						}
						font: {
							size: var(--fontSize);
							weight: 500;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
						&.focus,
						&.selected.focus {
							background-color: transparent !important;
							color: var(--blackColor);
						}
						&::before {
							content: "\ea0f";
							position: absolute;
							left: 12px;
							top: -1px;
							opacity: 0;
							visibility: hidden;
							transition: var(--transition);
							color: var(--mainColor);
							font: {
								family: "boxicons";
								size: 20px;
							}
						}
						&.selected {
							&::before {
								opacity: 1;
								visibility: visible;
							}
							&:hover {
								background-color: var(--mainColor) !important;
								color: var(--whiteColor);

								&::before {
									color: var(--whiteColor);
								}
							}
						}
					}
				}
			}
		}
		.col-lg-4 {
			.form-group {
				margin-left: 0;
			}
		}
		.submit-btn {
			padding-left: 5px;

			button {
				display: block;
				width: 100%;
				background-color: var(--mainColor);
				border: none;
				color: var(--whiteColor);
				font-size: var(--fontSize);
				font-weight: 600;
				padding: 12px 15px;
				border-radius: 5px;
				transition: var(--transition);

				&:hover {
					background-color: var(--blackColor);
					color: var(--whiteColor);
				}
			}
		}
	}
	.mapzoom-in {
		width: 30px;
		height: 30px;
		background-color: var(--whiteColor);
		position: relative;
		cursor: pointer;
		border-radius: 50%;
		text-align: center;
		transition: var(--transition);

		&::before {
			content: "\eb89";
			font-family: "boxicons";
			position: absolute;
			right: 0;
			left: 0;
			font-size: 22px;
			top: 50%;
			transform: translateY(-50%);
		}
		&:hover {
			color: var(--whiteColor);
			background-color: var(--mainColor);
		}
	}
	.mapzoom-out {
		width: 30px;
		height: 30px;
		background-color: var(--whiteColor);
		position: relative;
		cursor: pointer;
		border-radius: 50%;
		text-align: center;
		transition: var(--transition);
		margin-top: 10px;

		&::before {
			content: "\eb56";
			font-family: "boxicons";
			position: absolute;
			right: 0;
			left: 0;
			font-size: 22px;
			top: 50%;
			transform: translateY(-50%);
		}
		&:hover {
			color: var(--whiteColor);
			background-color: var(--mainColor);
		}
	}
}

/*================================================
Features Area CSS
=================================================*/
.features-area {
	position: relative;
	z-index: 1;
}
.single-features-box {
	margin-bottom: 30px;
	text-align: center;
	border: 1px solid #f5f5f5;
	transition: var(--transition);
	padding: 40px 20px;
	border-radius: 5px;

	.icon {
		margin-bottom: 25px;
		position: relative;
		z-index: 1;
		font-size: 60px;
		color: var(--mainColor);
		line-height: 1;
		display: inline-block;
		padding: {
			top: 12px;
			left: 12px;
		}
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 55px;
			height: 55px;
			border-radius: 50%;
			background-color: #ededed;
		}
	}
	h3 {
		font-size: 20px;
		margin-bottom: 15px;
	}
	.link-btn {
		display: inline-block;
		color: var(--mainColor);
		font-weight: 600;
		z-index: 1;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			height: 1px;
			width: 100%;
			background-color: var(--blackColor);
			transition: var(--transition);
			transform: scaleX(0);
			z-index: -1;
		}
		&:hover {
			color: var(--blackColor);

			&::before {
				transform: scaleX(1);
			}
		}
	}
	&:hover {
		border-color: var(--mainColor);
	}
}
.divider {
	width: 100%;
	position: absolute;
	height: 100px;
	pointer-events: none;
	-webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
	mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
	-webkit-mask-size: 100% 101%;
	mask-size: 100% 101%;
	background: #f9f9f9;
	bottom: 0;
	left: 0;
	z-index: -1;
}

/*================================================
Listings Area CSS
=================================================*/
.listings-area {
	position: relative;
	z-index: 1;

	.pagination-area {
		margin-top: 10px;
	}
	.container-fluid {
		padding: {
			right: 0;
			left: 30px;
		}
	}
}
.single-listings-box {
	box-shadow: 5px 5px #b2eaea;
	transition: var(--transition);
	background-color: var(--whiteColor);
	border: 1px solid #96e3e3;
	border-radius: 5px;
	margin: {
		bottom: 35px;
		right: 5px;
	}
	.listings-image {
		border-radius: 5px 5px 0 0;
		position: relative;
		z-index: 1;

		img {
			border-radius: 5px 5px 0 0;
		}
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background-color: #000000;
			border-radius: 5px 5px 0 0;
			opacity: 0.2;
		}
		.link-btn {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			display: block;
			border-radius: 5px 5px 0 0;
			z-index: 2;
			bottom: 0;
		}
		.bookmark-save {
			position: absolute;
			top: 20px;
			right: 20px;
			z-index: 3;
			display: inline-block;
			color: var(--whiteColor);
			text-align: center;
			background-color: rgba(0, 0, 0, 0.55);
			width: 35px;
			height: 35px;
			border-radius: 50%;
			line-height: 38px;
			font-size: 16px;

			&:hover {
				background-color: var(--mainColor);
				color: var(--whiteColor);
			}
		}
		.category {
			position: absolute;
			top: 20px;
			left: 20px;
			z-index: 3;
			display: inline-block;
			color: var(--whiteColor);
			text-align: center;
			background-color: rgba(0, 0, 0, 0.55);
			width: 35px;
			height: 35px;
			border-radius: 50%;
			line-height: 35px;
			font-size: 15px;

			&:hover {
				background-color: var(--mainColor);
				color: var(--whiteColor);
			}
		}
		.owl-theme {
			z-index: 3;

			.owl-nav {
				margin-top: 0;
				opacity: 0 !important;
				visibility: hidden !important;
				transition: var(--transition);

				[class*="owl-"] {
					color: var(--blackColor);
					font-size: 14px;
					margin: 0;
					transition: var(--transition);
					padding: 0;
					background: #d1d2d0;
					border-radius: 50%;
					width: 30px;
					box-shadow: unset !important;
					border: none !important;
					height: 30px;
					line-height: 32px;
					text-align: center;
					position: absolute;
					left: 20px;
					top: 63%;
					transform: translateY(-63%);
					z-index: 3;

					&.owl-next {
						left: auto;
						right: 20px;
					}
					&:hover {
						background-color: var(--mainColor);
						color: var(--whiteColor);
					}
				}
			}
		}
		&:hover {
			.owl-theme {
				.owl-nav {
					opacity: 1 !important;
					visibility: visible !important;
				}
			}
		}
		.single-image {
			position: relative;
			z-index: 1;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				background-color: #000000;
				border-radius: 5px 5px 0 0;
				opacity: 0.25;
			}
		}
	}
	.listings-content {
		padding: 28px 20px 25px;
		position: relative;
		z-index: 2;

		.author {
			position: absolute;
			right: 20px;
			top: -21px;
			background-color: #f9f9f9;
			border-radius: 50px;
			padding: 6px 8px;

			img {
				width: 30px !important;
				height: 30px;
				display: inline-block !important;
				border-radius: 50%;
			}
			span {
				font-weight: 600;
				color: var(--optionalColor);
				margin-left: 6px;
				font-size: 14px;
			}
		}
		.listings-meta {
			padding-left: 0;
			margin-bottom: 5px;
			list-style-type: none;

			li {
				display: inline-block;
				margin-right: 12px;
				color: var(--optionalColor);
				margin-bottom: 10px;
				font: {
					weight: 600;
					size: 13px;
				}
				a {
					color: var(--optionalColor);
					display: inline-block;
				}
				i {
					font-weight: normal;
					color: var(--mainColor);
					margin-right: 2px;
					width: 25px;
					height: 25px;
					text-align: center;
					display: inline-block;
					line-height: 25px;
					background-color: #e8e8e8;
					border-radius: 50%;
					transition: var(--transition);
				}
				&:hover {
					i {
						background-color: var(--mainColor);
						color: var(--whiteColor);
					}
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
		h3 {
			margin-bottom: 14px;
			font-size: 20px;
		}
		.status {
			display: block;
			color: var(--optionalColor);
			margin-bottom: 14px;
			font: {
				weight: 600;
				size: 14px;
			}
			i {
				color: var(--mainColor);
				font-weight: normal;
			}
			&.status-close {
				color: var(--mainColor);
			}
		}
		.rating {
			i {
				color: #efc02f;
				font-size: 14px;
				margin-right: -3px;
				line-height: 1;
			}
			.count {
				display: inline-block;
				margin-left: 3px;
				position: relative;
				top: -1.5px;
				color: var(--optionalColor);
				font-weight: 600;
			}
		}
		.price {
			color: var(--optionalColor);
			position: relative;
			top: -2px;
			font: {
				weight: 600;
				size: 13px;
			}
			span {
				color: var(--blackColor);
				font-weight: 700;
				font-size: 16px;
				display: inline-block;
				margin-left: 10px;
				position: relative;
				top: 1px;
			}
		}
	}
	&.without-boxshadow {
		box-shadow: unset;
	}
	&:hover {
		box-shadow: 5px 5px var(--mainColor);
		border-color: var(--mainColor);
	}
}
.single-listings-item {
	margin-bottom: 30px;
	border: 1px solid var(--mainColor);
	border-radius: 5px;
	background-color: var(--whiteColor);

	.listings-image {
		border-radius: 5px;
		position: relative;
		z-index: 1;
		height: 100%;
		background: {
			position: center center;
			size: cover;
			repeat: no-repeat;
		}
		img {
			border-radius: 5px;
			display: none;
		}
		&.bg-img1 {
			background-image: url(../images/listings/listings9.jpg);
		}
		&.bg-img2 {
			background-image: url(../images/listings/listings10.jpg);
		}
		&.bg-img3 {
			background-image: url(../images/listings/listings11.jpg);
		}
		&.bg-img4 {
			background-image: url(../images/listings/listings12.jpg);
		}
		&.bg-img5 {
			background-image: url(../images/listings/listings16.jpg);
		}
		&.bg-img6 {
			background-image: url(../images/listings/listings17.jpg);
		}
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background-color: #000000;
			border-radius: 5px;
			opacity: 0.2;
		}
		.link-btn {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			display: block;
			border-radius: 5px;
			z-index: 2;
			bottom: 0;
		}
		.bookmark-save {
			position: absolute;
			top: 20px;
			right: 20px;
			z-index: 3;
			display: inline-block;
			color: var(--whiteColor);
			text-align: center;
			background-color: rgba(0, 0, 0, 0.55);
			width: 35px;
			height: 35px;
			border-radius: 50%;
			line-height: 38px;
			font-size: 16px;

			&:hover {
				background-color: var(--mainColor);
				color: var(--whiteColor);
			}
		}
		.category {
			position: absolute;
			top: 20px;
			left: 20px;
			z-index: 3;
			display: inline-block;
			color: var(--whiteColor);
			text-align: center;
			background-color: rgba(0, 0, 0, 0.55);
			width: 35px;
			height: 35px;
			border-radius: 50%;
			line-height: 35px;
			font-size: 15px;

			&:hover {
				background-color: var(--mainColor);
				color: var(--whiteColor);
			}
		}
		.author {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 10px;
			background-color: rgba(255, 255, 255, 0.7);
			border-radius: 50px;
			padding: 6px 8px;
			z-index: 2;

			img {
				width: 30px !important;
				height: 30px;
				display: inline-block !important;
				border-radius: 50%;
			}
			span {
				font-weight: 600;
				color: var(--blackColor);
				margin-left: 6px;
				font-size: 14px;
			}
		}
	}
	.listings-content {
		padding: 45px 30px;

		.status {
			display: inline-block;
			color: var(--whiteColor);
			margin-bottom: 15px;
			background-color: var(--mainColor);
			border-radius: 30px;
			padding: 4px 12px;
			font: {
				weight: 600;
				size: 14px;
			}
			i {
				position: relative;
				top: 1px;
			}
			&.status-close {
				background-color: red;
				color: var(--whiteColor);
			}
		}
		h3 {
			margin-bottom: 12px;
			font-size: 20px;
		}
		.rating {
			i {
				color: #efc02f;
				font-size: 14px;
				margin-right: -3px;
				line-height: 1;
			}
			.count {
				display: inline-block;
				margin-left: 3px;
				position: relative;
				top: -1.5px;
				color: var(--optionalColor);
				font-weight: 600;
			}
		}
		.price {
			color: var(--optionalColor);
			position: relative;
			top: -1px;
			font: {
				weight: 600;
				size: 14px;
			}
			span {
				color: var(--blackColor);
				font-weight: 700;
				font-size: 17px;
				display: inline-block;
				margin-left: 10px;
				position: relative;
				top: 1px;
			}
		}
		.listings-meta {
			list-style-type: none;
			border-top: 1px solid #f4f4f4;
			margin: {
				bottom: 0;
				top: 15px;
			}
			padding: {
				left: 0;
				top: 15px;
			}
			li {
				display: inline-block;
				margin-right: 12px;
				color: var(--optionalColor);
				font: {
					weight: 600;
					size: 14px;
				}
				a {
					color: var(--optionalColor);
					display: inline-block;
				}
				i {
					font-weight: normal;
					color: var(--mainColor);
					margin-right: 2px;
					width: 28px;
					height: 28px;
					text-align: center;
					display: inline-block;
					line-height: 28px;
					background-color: #e8e8e8;
					border-radius: 50%;
					transition: var(--transition);
				}
				&:hover {
					i {
						background-color: var(--mainColor);
						color: var(--whiteColor);
					}
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
.listings-slides {
	&.owl-theme {
		.owl-nav {
			margin-top: 0;
			transition: var(--transition);
			opacity: 0;
			visibility: hidden;

			[class*="owl-"] {
				color: var(--blackColor);
				font-size: 18px;
				margin: 0;
				padding: 0;
				background: var(--whiteColor);
				border-radius: 50%;
				width: 40px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				transition: var(--transition);
				border: 1px solid var(--mainColor);
				position: absolute;
				left: -65px;
				top: 50%;
				transform: translateY(-50%);
				box-shadow: -2px 2px var(--mainColor);

				&.owl-next {
					left: auto;
					right: -65px;
					box-shadow: 2px 2px var(--mainColor);
				}
				&:hover {
					box-shadow: 2px 2px transparent;
					background-color: var(--mainColor);
					color: var(--whiteColor);
				}
				&.disabled {
					cursor: not-allowed;
					background-color: var(--whiteColor);
					color: var(--blackColor);
				}
			}
		}
		&:hover {
			.owl-nav {
				opacity: 1;
				visibility: visible;
			}
		}
		.owl-dots {
			margin-top: 5px;

			.owl-dot {
				span {
					width: 12px;
					height: 12px;
					margin: 0 4px;
					background: #b9b9b9;
					border-radius: 50%;
					transition: var(--transition);
				}
				&:hover,
				&.active {
					span {
						background-color: var(--mainColor);
					}
				}
			}
		}
	}
}
.shorting {
	.mix {
		display: none;
	}
}
.shorting-menu {
	text-align: center;
	margin-bottom: 35px;

	.filter {
		background-color: #efefef;
		border: none;
		padding: 8px 15px;
		margin: 0 1px;
		color: var(--blackColor);
		transition: var(--transition);
		font: {
			size: 15px;
			weight: 600;
		}
		i {
			font-weight: normal;
			margin-right: 2px;
		}
		&:hover,
		&.active {
			background-color: var(--mainColor);
			color: var(--whiteColor);
		}
	}
}
.more-listings-box {
	text-align: center;
	margin-top: 5px;
}
.divider2 {
	width: 100%;
	position: absolute;
	height: 100px;
	pointer-events: none;
	-webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
	mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
	-webkit-mask-size: 100% 101%;
	mask-size: 100% 101%;
	background: #f9f9f9;
	bottom: -100px;
	left: 0;
	z-index: -1;
	transform: scaleY(-1);
}
.listings-grid-sorting {
	margin-bottom: 20px;

	.result-count {
		p {
			.count {
				font-weight: 500;
				color: var(--blackColor);
			}
		}
	}
	.ordering {
		text-align: right;

		label {
			display: inline-block;
			margin-bottom: 0;
			color: var(--optionalColor);
			margin-right: 5px;
			font-weight: 500;
		}
		.nice-select {
			display: inline-block;
			width: 180px;
			float: unset;
			background: transparent;
			border: none;
			color: var(--blackColor);
			transition: var(--transition);
			padding: 0 0 0 0;
			height: auto;
			line-height: initial;
			font: {
				size: var(--fontSize);
				weight: 600;
			}
			.list {
				background-color: var(--whiteColor);
				border-radius: 5px;
				box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
				list-style-type: none;
				border: none;
				width: 100%;
				margin: {
					bottom: 0;
					top: 15px;
				}
				padding: {
					left: 0;
					top: 10px;
					bottom: 10px;
				}
				.option {
					line-height: 38px;
					min-height: 38px;
					color: var(--blackColor);
					position: relative;
					transition: var(--transition);
					padding: {
						left: 32px;
						right: 25px;
					}
					font: {
						size: var(--fontSize);
						weight: 600;
					}
					&:hover {
						background-color: var(--mainColor) !important;
						color: var(--whiteColor);

						&::before {
							color: var(--whiteColor);
						}
					}
					&.focus,
					&.selected.focus {
						background-color: transparent !important;
						color: var(--blackColor);
					}
					&::before {
						content: "\ea0f";
						position: absolute;
						left: 10px;
						top: -3px;
						opacity: 0;
						visibility: hidden;
						transition: var(--transition);
						color: var(--mainColor);
						font: {
							family: "boxicons";
							size: 20px;
						}
					}
					&.selected {
						&::before {
							opacity: 1;
							visibility: visible;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
					}
				}
			}
			&:after {
				border-color: var(--blackColor);
				height: 8px;
				width: 8px;
				margin-top: -7px;
			}
			&:hover {
				border-color: var(--mainColor);
				background-color: transparent;
			}
		}
		.select-box {
			margin-left: 20px;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}
.all-listings-list {
	padding-right: 30px;
	margin-left: -40px;
}
.map-container {
	&.fw-map {
		&.side-full-map {
			width: 100%;
			height: 100%;
			padding-bottom: 82px;

			#main-full-map {
				width: 100%;
				height: 100%;
			}
		}
	}
	.mapzoom-in {
		width: 30px;
		height: 30px;
		background-color: var(--whiteColor);
		position: relative;
		cursor: pointer;
		border-radius: 50%;
		text-align: center;
		transition: var(--transition);

		&::before {
			content: "\eb89";
			font-family: "boxicons";
			position: absolute;
			right: 0;
			left: 0;
			font-size: 22px;
			top: 50%;
			transform: translateY(-50%);
		}
		&:hover {
			color: var(--whiteColor);
			background-color: var(--mainColor);
		}
	}
	.mapzoom-out {
		width: 30px;
		height: 30px;
		background-color: var(--whiteColor);
		position: relative;
		cursor: pointer;
		border-radius: 50%;
		text-align: center;
		transition: var(--transition);
		margin-top: 10px;

		&::before {
			content: "\eb56";
			font-family: "boxicons";
			position: absolute;
			right: 0;
			left: 0;
			font-size: 22px;
			top: 50%;
			transform: translateY(-50%);
		}
		&:hover {
			color: var(--whiteColor);
			background-color: var(--mainColor);
		}
	}
}
.map-popup-box {
	display: block;
	width: 300px !important;
	position: relative;

	.map-popup {
		display: block;
		width: 100%;
		position: relative;
		overflow: hidden;
		border-radius: 5px;
		background: var(--whiteColor);
		box-shadow: 0 9px 16px rgba(58, 87, 135, 0.15);

		img {
			width: 100%;
			height: auto;
			-webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
			-webkit-transform: translateZ(0);
			border-radius: 5px 5px 0 0;
			transform: translateZ(0);
			transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
		}
	}
	&:before {
		top: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		margin-top: -1px;
		z-index: 20;
	}
	&:before {
		border-color: transparent;
		border-top-color: var(--whiteColor);
		border-width: 15px;
		margin-left: -30px;
	}
	.mp-img-box {
		overflow: hidden;
		border-radius: 5px 5px 0 0;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			border-radius: 5px 5px 0 0;
			height: 100%;
			background: #292929;
			opacity: 0.5;
		}
		&:hover {
			img {
				-webkit-transform: scale(1.15);
				-moz-transform: scale(1.15);
				transform: scale(1.15);
			}
		}
	}
	.mp-list-content {
		background: var(--whiteColor);
		padding: 20px;
		z-index: 20;
		border: {
			bottom: {
				left-radius: 5px;
				right-radius: 5px;
			}
		}
		.mpl-title {
			h3 {
				font-size: 18px;
				margin-bottom: 15px;
			}
			.mp-location-info {
				display: block;
				color: var(--optionalColor);
				margin-bottom: 10px;
				font-size: 13px;

				i {
					font-weight: normal;
					color: var(--mainColor);
					margin-right: 7px;
					width: 25px;
					height: 25px;
					text-align: center;
					display: inline-block;
					line-height: 25px;
					background-color: #e8e8e8;
					border-radius: 50%;
					transition: var(--transition);
				}
				&:hover {
					i {
						background-color: var(--mainColor);
						color: var(--whiteColor);
					}
				}
			}
			.mp-store-call {
				color: var(--optionalColor);
				display: block;
				font-size: 13px;

				i {
					font-weight: normal;
					color: var(--mainColor);
					margin-right: 7px;
					width: 25px;
					height: 25px;
					text-align: center;
					display: inline-block;
					line-height: 25px;
					background-color: #e8e8e8;
					border-radius: 50%;
					transition: var(--transition);
				}
				&:hover {
					i {
						background-color: var(--mainColor);
						color: var(--whiteColor);
					}
				}
			}
		}
	}
	.close-infobox {
		position: absolute;
		width: 30px;
		height: 30px;
		line-height: 33.5px;
		top: 20px;
		text-align: center;
		right: 20px;
		color: #121212;
		border-radius: 100%;
		z-index: 20;
		cursor: pointer;
		background: var(--whiteColor);
		font-size: 20px;
		transition: var(--transition);

		&:hover {
			background: red;
			color: var(--whiteColor);
		}
	}
	.fl-wrap {
		float: left;
		width: 100%;
		position: relative;
	}
	.mp-ratting {
		position: absolute;
		z-index: 12;
		left: 20px;
		top: -30px;

		i {
			float: left;
			color: #facc39;
			margin-top: -2px;
			margin-right: 4px;
			font-size: 15px;
		}
	}
	.mp-review-count {
		color: rgba(255, 255, 255, 0.9);
		position: relative;
		top: -2px;
		font-size: 14px;
	}
	.mp-category {
		position: absolute;
		top: 20px;
		left: 20px;
		color: var(--whiteColor);
		z-index: 20;
		padding: 8px 13px 7px;
		border-radius: 5px;
		background: rgba(255, 255, 255, 0.17);
		border: 1px solid rgba(255, 255, 255, 0.2);
		font: {
			weight: 600;
			size: 12px;
		}
	}
}
.cluster {
	width: 40px !important;
	height: 40px !important;

	img {
		display: none;
	}
	div {
		text-align: center;
		font-size: 15px !important;
		background: var(--whiteColor);
		color: var(--blackColor) !important;
		font-weight: 600;
		border-radius: 100%;
		width: 40px !important;
		height: 40px !important;
		line-height: 37px !important;
		box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5);
		border: 2px solid var(--whiteColor);
		transition: var(--transition);
		animation: cluster-animation 1.5s infinite;

		&:hover {
			background: var(--mainColor);
			color: var(--whiteColor) !important;
			border-color: var(--mainColor);
		}
	}
}
@keyframes cluster-animation {
	0%,
	100% {
		box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
	}
	50% {
		box-shadow: 0 0 0 9px rgba(0, 0, 0, 0.1);
	}
}

/*================================================
Listings Details Area CSS
=================================================*/
.listings-details-image {
	position: relative;

	.container {
		position: relative;

		.container {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: auto;
			z-index: 2;
		}
	}
	.listings-details-content {
		color: var(--whiteColor);
		padding-bottom: 35px;

		.meta {
			display: inline-block;
			text-transform: uppercase;
			color: var(--mainColor);
			font-weight: 600;
			font-size: 14px;

			i {
				font-weight: normal;
				color: var(--mainColor);
				margin-right: 3px;
				width: 28px;
				height: 28px;
				text-align: center;
				display: inline-block;
				font-size: var(--fontSize);
				line-height: 28px;
				background-color: #e8e8e8;
				border-radius: 50%;
				transition: var(--transition);
			}
		}
		h3 {
			color: var(--whiteColor);
			font: {
				size: 35px;
				weight: 800;
			}
			margin: {
				top: 15px;
				bottom: 14px;
			}
		}
		.rating {
			margin-bottom: 5px;

			.bx {
				color: #cecfd2;
				font-size: var(--fontSize);
				margin-right: 2px;

				&.checked {
					color: #efc02f;
				}
			}
			.rating-count {
				position: relative;
				color: var(--mainColor);
				top: 1px;
				font: {
					size: var(--fontSize);
					weight: 600;
				}
			}
		}
		ul {
			padding-left: 0;
			list-style-type: none;
			margin: {
				bottom: 0;
				top: 25px;
			}
			li {
				display: inline-block;
				margin-right: 25px;

				&:last-child {
					margin-right: 0;
				}
				&.phone-number {
					a {
						background-color: var(--mainColor);
						border-radius: 30px;
						color: var(--whiteColor);
						position: relative;
						display: block;
						padding: {
							left: 60px;
							right: 25px;
							top: 12px;
							bottom: 12px;
						}
						font: {
							size: 18px;
							weight: 700;
						}
						i {
							position: absolute;
							left: 25px;
							top: 50%;
							margin-top: 1px;
							transform: translateY(-50%);
							font-size: 25px;
						}
					}
				}
				&.time {
					position: relative;
					color: var(--mainColor);
					padding-left: 58px;

					span {
						display: block;
						color: var(--whiteColor);
						margin-bottom: 3px;
						font: {
							size: 17px;
							weight: 700;
						}
					}
					i {
						position: absolute;
						left: 0;
						top: 0;
						color: #a2a1a1;
						font-size: 48px;
					}
				}
				&.location {
					position: relative;
					color: var(--mainColor);
					padding-left: 55px;

					span {
						display: block;
						color: var(--whiteColor);
						margin-bottom: 3px;
						font: {
							size: 17px;
							weight: 700;
						}
					}
					i {
						position: absolute;
						left: 0;
						top: 0;
						color: #a2a1a1;
						font-size: 48px;
					}
				}
			}
		}
	}
	.container-fluid {
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		height: auto;
		text-align: right;
		padding: {
			left: 100px;
			right: 100px;
			top: 30px;
		}
		.share-save {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				display: inline-block;
				position: relative;

				a,
				.share {
					background-color: rgba(0, 0, 0, 0.77);
					color: var(--whiteColor);
					position: relative;
					font-size: var(--fontSize);
					display: block;
					cursor: pointer;
					padding: 6px 15px 6px 38px;
					transition: var(--transition);
					margin-right: 5px;

					i {
						position: absolute;
						left: 15px;
						top: 50%;
						transform: translateY(-50%);
					}
					&:hover {
						background-color: var(--whiteColor);
						color: var(--blackColor);
					}
				}
				.social {
					position: absolute;
					background-color: var(--whiteColor);
					right: 0;
					top: 40px;
					text-align: center;
					width: 150px;
					opacity: 0;
					visibility: hidden;
					transition: var(--transition);

					a {
						background-color: var(--whiteColor);
						color: var(--mainColor);
						display: inline-block;
						padding: 7px 0 5px;
						font-size: 18px;
						margin: {
							left: 3px;
							right: 3px;
						}
						i {
							position: relative;
							top: 0;
							left: 0;
							transform: translateY(0);
						}
						&:hover {
							color: var(--blackColor);
						}
					}
					&.active {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
}
.listings-details-nav {
	background-color: #f5f5f5;
	position: sticky;

	.container-fluid {
		padding: {
			left: 0;
			right: 0;
		}
	}
	&.is-sticky {
		position: fixed;
		top: 84px;
		left: 0;
		width: 100%;
		z-index: 999;
		animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	}
	.nav-tabs {
		margin-bottom: 0;
		border: none;

		.nav-item {
			margin-bottom: 0;

			.nav-link {
				border: none;
				background-color: transparent;
				padding: 0;
				border-radius: 0;
				margin-right: 25px;
				position: relative;
				transition: var(--transition);
				color: #666666;
				font: {
					size: 16px;
					weight: 600;
				}
				padding: {
					top: 27px;
					bottom: 20px;
				}
				&::before {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					height: 2px;
					background-color: var(--mainColor);
					transition: var(--transition);
					transform: scaleX(0);
				}
				&.active,
				&:hover {
					color: var(--blackColor);

					&::before {
						transform: scaleX(1);
					}
				}
			}
		}
	}
}
.listings-details-desc {
	margin-top: 60px;

	h3 {
		font-size: 22px;
		margin: {
			top: 30px;
			bottom: 20px;
		}
		&:first-child {
			margin-top: 0;
		}
	}
	p {
		margin: {
			top: -5px;
			bottom: 18px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.amenities-list {
		list-style-type: none;
		padding-left: 0;
		display: flex;
		flex-wrap: wrap;
		margin: {
			bottom: 35px;
			left: -10px;
			right: -10px;
			top: -20px;
		}
		li {
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
			padding: {
				left: 10px;
				right: 10px;
				top: 25px;
			}
			span {
				display: block;
				color: var(--optionalColor);
				position: relative;
				padding-left: 38px;
				font: {
					size: var(--fontSize);
					weight: 600;
				}
				i {
					width: 30px;
					height: 30px;
					display: inline-block;
					border-radius: 50%;
					line-height: 30px;
					color: var(--blackColor);
					background-color: #f5f5f5;
					text-align: center;
					font-size: 25px;
					position: absolute;
					left: 0;
					top: -5px;
					transition: var(--transition);
				}
				&:hover {
					i {
						background-color: var(--mainColor);
						color: var(--whiteColor);
					}
				}
			}
		}
	}
	.pricing-list {
		padding-left: 0;
		list-style-type: none;
		margin-bottom: 40px;

		li {
			margin-bottom: 15px;
			color: var(--optionalColor);
			position: relative;
			background-color: #f5f5f5;
			box-shadow: 5px 5px #dedede;
			transition: var(--transition);
			font: {
				size: 16px;
				weight: 600;
			}
			padding: {
				left: 25px;
				right: 25px;
				top: 15px;
				bottom: 15px;
			}
			span {
				position: absolute;
				right: 25px;
				top: 50%;
				transform: translateY(-50%);
				color: var(--blackColor);
			}
			&:last-child {
				margin-bottom: 5px;
			}
			&:hover {
				box-shadow: 5px 5px var(--mainColor);
			}
		}
	}
	.single-listings-box {
		margin-top: 5px;

		h3 {
			margin-top: 0;
		}
	}
	.listings-review {
		background-color: #f5f5f5;
		box-shadow: 5px 5px #dedede;
		padding: 30px;

		.rating {
			margin-bottom: 5px;

			.bx {
				color: #cecfd2;
				font-size: 20px;
				margin-right: 2px;

				&.checked {
					color: #efc02f;
				}
			}
			.overall-rating {
				position: relative;
				top: 1px;
				margin: {
					left: 5px;
					right: 5px;
				}
				font: {
					size: 20px;
					weight: 600;
				}
			}
			.rating-count {
				position: relative;
				color: var(--mainColor);
				top: 1px;
				font: {
					size: 20px;
					weight: 600;
				}
			}
		}
		.row {
			.row {
				overflow: hidden;
			}
		}
		.side {
			float: left;
			width: 35%;
			margin-top: 12px;
			font-size: 14px;

			div {
				font-weight: 600;
			}
		}
		.middle {
			margin-top: 20px;
			float: left;
			width: 55%;
		}
		.right {
			text-align: right;
			width: 10%;
		}
		.bar-container {
			width: 100%;
			background-color: #a6a6a6;
			text-align: center;
			color: var(--whiteColor);
		}
		.bar-5 {
			width: 100%;
			height: 4px;
			background-color: var(--blackColor);
		}
		.bar-4 {
			width: 75%;
			height: 4px;
			background-color: var(--blackColor);
		}
		.bar-3 {
			width: 50%;
			height: 4px;
			background-color: var(--blackColor);
		}
		.bar-2 {
			width: 25%;
			height: 4px;
			background-color: var(--blackColor);
		}
		.bar-1 {
			width: 0;
			height: 4px;
			background-color: var(--blackColor);
		}
	}
	.write-a-review {
		background-color: #f5f5f5;
		box-shadow: 5px 5px #dedede;
		padding: 30px 230px 30px 30px;
		position: relative;
		margin-top: 30px;

		h4 {
			margin-bottom: 10px;
			font: {
				size: 17px;
				weight: 600;
			}
		}
		p {
			font-size: 14.5px;
			margin: 0;
		}
		.default-btn {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 30px;
			background-color: var(--mainColor);
			color: var(--whiteColor);
			border-color: var(--mainColor);

			&:hover {
				box-shadow: 5px 5px var(--blackColor);
			}
		}
	}
	.listings-review-comments {
		margin: {
			top: 30px;
			bottom: 35px;
		}
		.user-review {
			border: 1px solid #dedede;
			padding: 30px;
			margin-top: 20px;

			&:first-child {
				margin-top: 0;
			}
			.user {
				img {
					width: 60px;
					border-radius: 3px;
				}
				.title {
					margin-left: 15px;

					h4 {
						margin-bottom: 4px;
						font: {
							size: 16.5px;
							weight: 600;
						}
					}
					span {
						display: block;
						font-size: 14.5px;
						color: var(--optionalColor);
					}
				}
			}
			.comments {
				.rating {
					margin-bottom: 15px;

					.bx {
						color: #cecfd2;
						font-size: 17px;
						margin-right: -2px;

						&.checked {
							color: #efc02f;
						}
					}
				}
				p {
					margin-bottom: 15px;
				}
				a {
					display: inline-block;
					color: var(--optionalColor);
					font-weight: 600;

					&:hover {
						color: var(--mainColor);
					}
				}
				.like-unlike {
					padding-left: 0;
					margin-bottom: 0;
					list-style-type: none;

					li {
						margin-right: 20px;
						display: inline-block;

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
	.review-form-wrapper {
		background-color: #f5f5f5;
		box-shadow: 5px 5px #dedede;
		padding: 30px;
		margin-bottom: 35px;

		h3 {
			margin-bottom: 12px;
		}
		.comment-notes {
			span {
				color: red;
			}
		}
		form {
			margin-top: 20px;

			.form-group {
				margin-bottom: 25px;
				text-align: left;
			}
			.add-sub-rating {
				margin-bottom: 10px;
			}
			.sub-ratings {
				border-bottom: 1px solid #e3e3e3;
				padding-bottom: 5px;
				margin-bottom: 25px;

				h4 {
					margin-bottom: 12px;
					font: {
						size: 16px;
						weight: 600;
					}
				}
				.cleanliness-rating,
				.accuracy-rating,
				.location-rating,
				.value-rating,
				.communication-rating,
				.checkin-rating {
					text-align: left;
					overflow: hidden;
					max-width: 115px;
					margin-top: -5px;

					label {
						float: right;
						position: relative;
						width: 23px;
						height: 23px;
						cursor: pointer;

						&:not(:first-of-type) {
							padding-right: 5px;
						}
						&:before {
							content: "\2605";
							transition: var(--transition);
							font-size: 25px;
							color: #cccccc;
							line-height: 1;
						}
					}
					input {
						display: none;
					}
					input:checked ~ label:before,
					&:not(:checked) > label:hover:before,
					&:not(:checked) > label:hover ~ label:before {
						color: #f6b500;
					}
				}
			}
			.comment-form-cookies-consent {
				text-align: left;
				margin: {
					bottom: 0;
					top: 3px;
				}
				[type="checkbox"]:checked,
				[type="checkbox"]:not(:checked) {
					display: none;
				}
				[type="checkbox"]:checked + label,
				[type="checkbox"]:not(:checked) + label {
					position: relative;
					padding-left: 28px;
					cursor: pointer;
					line-height: 20px;
					display: inline-block;
					margin-bottom: 0;
					color: var(--optionalColor);
					font-weight: 500;
				}
				[type="checkbox"]:checked + label:before,
				[type="checkbox"]:not(:checked) + label:before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 19px;
					height: 19px;
					transition: all 0.2s ease;
					border: 1px solid var(--mainColor);
					border-radius: 3px;
					background: #f5f5f5;
				}
				[type="checkbox"]:checked + label:after,
				[type="checkbox"]:not(:checked) + label:after {
					content: "";
					width: 8px;
					height: 8px;
					background: var(--mainColor);
					position: absolute;
					top: 5.5px;
					left: 6px;
					-webkit-transition: all 0.2s ease;
					transition: all 0.2s ease;
				}
				[type="checkbox"]:not(:checked) + label:after {
					opacity: 0;
					-webkit-transform: scale(0);
					transform: scale(0);
				}
				[type="checkbox"]:checked + label:after {
					opacity: 1;
					-webkit-transform: scale(1);
					transform: scale(1);
				}
				[type="checkbox"]:hover + label:before {
					border-color: var(--mainColor);
				}
				[type="checkbox"]:checked + label:before {
					border-color: var(--mainColor);
				}
			}
			button {
				margin-top: 25px;
				border: none;
				border-radius: 30px;
				display: inline-block;
				text-align: center;
				overflow: hidden;
				color: var(--whiteColor);
				background-color: var(--mainColor);
				transition: var(--transition);
				padding: 12px 50px;
				font: {
					weight: 600;
					size: var(--fontSize);
				}
				&:hover {
					background-color: var(--mainColor2);
					color: var(--whiteColor);
				}
			}
			.form-control {
				background-color: var(--whiteColor) !important;
				box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12) !important;
			}
		}
	}
}
.gallery-slides {
	&.owl-theme {
		margin: {
			bottom: 35px;
			top: 25px;
		}
		.owl-nav {
			margin-top: 0;
			transition: var(--transition);
			opacity: 0;
			visibility: hidden;

			[class*="owl-"] {
				color: var(--blackColor);
				font-size: 18px;
				margin: 0;
				padding: 0;
				background: var(--whiteColor);
				border-radius: 50%;
				width: 40px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				transition: var(--transition);
				border: 1px solid var(--mainColor);
				position: absolute;
				left: 20px;
				top: 55%;
				transform: translateY(-55%);
				box-shadow: -2px 2px var(--mainColor);

				&.owl-next {
					left: auto;
					right: 20px;
					box-shadow: 2px 2px var(--mainColor);
				}
				&:hover {
					box-shadow: 2px 2px transparent;
					background-color: var(--mainColor);
					color: var(--whiteColor);
				}
				&.disabled {
					cursor: not-allowed;
					background-color: var(--whiteColor);
					color: var(--blackColor);
				}
			}
		}
		&:hover {
			.owl-nav {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
.listings-sidebar {
	margin-top: 60px;

	.listings-widget {
		margin-bottom: 30px;
		background-color: #fbfbfb;
		border: 1px solid #e0e0e0;
		border-radius: 5px;
		padding: 30px;

		&:last-child {
			margin-bottom: 0;
		}
		h3 {
			font-size: 20px;
			margin-bottom: 25px;
		}
	}
	.book_listings {
		.default-btn {
			display: block;
			background-color: var(--mainColor);
			color: var(--whiteColor);
			border-color: var(--mainColor);

			&:hover {
				box-shadow: 5px 5px var(--blackColor);
			}
		}
		span {
			display: block;
			color: var(--optionalColor);
			margin-top: 15px;

			a {
				color: var(--optionalColor);

				&:hover {
					color: var(--mainColor);
				}
			}
		}
	}
	.listings_contact_details {
		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				position: relative;
				border-top: 1px solid #e3e3e3;
				color: var(--optionalColor);
				padding: {
					top: 14px;
					bottom: 14px;
					left: 25px;
				}
				a {
					display: inline-block;
					color: var(--optionalColor);

					&:hover {
						color: var(--mainColor);
					}
				}
				i {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					margin-top: 1px;
					font-size: 20px;
					color: var(--blackColor);

					&.bx-map {
						margin-top: 7px;
					}
				}
				&:nth-child(1),
				&:nth-child(3) {
					color: var(--mainColor);

					a {
						color: var(--mainColor);

						&:hover {
							color: var(--blackColor);
						}
					}
				}
				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}
	.listings_author {
		.author {
			img {
				width: 50px;
				height: 50px;
				border-radius: 50%;
			}
			.title {
				margin-left: 15px;

				h4 {
					margin-bottom: 4px;
					font: {
						size: 16.5px;
						weight: 600;
					}
				}
				span {
					display: block;
					font-size: 14.5px;
					color: var(--optionalColor);
				}
			}
			.author-profile {
				border-top: 1px solid #e3e3e3;
				padding-top: 20px;
				margin-top: 20px;

				.view-profile {
					display: inline-block;
					color: var(--mainColor);
					font-weight: 600;

					&:hover {
						color: var(--blackColor);
					}
				}
				.social {
					padding-left: 0;
					margin-bottom: 0;
					list-style-type: none;
					text-align: right;

					li {
						display: inline-block;
						margin-left: 5px;

						a {
							display: block;
							width: 35px;
							height: 35px;
							border-radius: 5px;
							line-height: 38px;
							background-color: #f1f1f1;
							text-align: center;
							font-size: 20px;

							&:hover {
								background-color: var(--mainColor);
								color: var(--whiteColor);
							}
						}
						&:first-child {
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}

/*================================================
Listings Widget Sidebar CSS
=================================================*/
.listings-widget-area {
	padding-right: 50px;

	&.widget-right-sidebar {
		padding: {
			right: 0;
			left: 50px;
		}
	}
	.widget {
		border-bottom: 1px solid #eaeaea;
		margin-bottom: 30px;
		padding-bottom: 30px;

		.widget-title {
			font-size: 19px;
			margin-bottom: 25px;
			position: relative;

			&::before {
				font-family: Flaticon;
				content: "\f106";
				position: absolute;
				right: 0;
				top: 0;
				font-size: 19px;
				color: var(--blackColor);
			}
		}
		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
	.widget_filters {
		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				display: inline-block;
				margin-right: -6px;

				button {
					display: block;
					background-color: transparent;
					border: 1px solid #dedede;
					transition: var(--transition);
					padding: 7px 20px 6px;
					font: {
						size: var(--fontSize);
						weight: 600;
					}
					&:hover,
					&.active {
						background-color: var(--mainColor);
						color: var(--whiteColor);
						border-color: var(--mainColor);
					}
				}
			}
		}
	}
	.widget_categories {
		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				position: relative;
				margin-bottom: 11px;
				color: var(--optionalColor);

				&:last-child {
					margin-bottom: 0;
				}
				input[type="checkbox"] {
					--active: var(--mainColor);
					--active-inner: var(--whiteColor);
					--focus: 2px rgba(39, 94, 254, 0.3);
					--border: #d5d5d5;
					--border-hover: var(--mainColor);
					--background: var(--whiteColor);
					--disabled: #f6f8ff;
					--disabled-inner: #e1e6f9;
					-webkit-appearance: none;
					-moz-appearance: none;
					height: 19px;
					outline: none;
					display: inline-block;
					vertical-align: top;
					position: relative;
					margin: 0;
					cursor: pointer;
					border: 1px solid var(--bc, var(--border));
					background: var(--b, var(--background));
					transition: background 0.3s, border-color 0.3s,
						box-shadow 0.2s;

					&:not(.switch) {
						border-radius: 0;
					}
					&:not(.switch),
					&:not(.switch) {
						width: 19px;
					}
					&:not(.switch):after {
						width: 5px;
						height: 9px;
						border: 2px solid var(--active-inner);
						border-top: 0;
						border-left: 0;
						left: 6px;
						top: 3.5px;
						-webkit-transform: rotate(var(--r, 20deg));
						transform: rotate(var(--r, 20deg));
					}
					&:not(.switch):after,
					&:not(.switch):after {
						opacity: var(--o, 0);
					}
					&:after,
					&:after {
						content: "";
						display: block;
						left: 0;
						top: 0;
						position: absolute;
						transition: opacity var(--d-o, 0.2s),
							-webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
						transition: transform var(--d-t, 0.3s)
								var(--d-t-e, ease),
							opacity var(--d-o, 0.2s);
						transition: transform var(--d-t, 0.3s)
								var(--d-t-e, ease),
							opacity var(--d-o, 0.2s),
							-webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
					}
					&:not(.switch):checked {
						--r: 43deg;
					}
					&:not(.switch):checked,
					&:not(.switch):checked {
						--o: 1;
					}
					&:checked,
					&:checked {
						--b: var(--active);
						--bc: var(--active);
						--d-o: 0.3s;
						--d-t: 0.6s;
						--d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
					}
				}
				label {
					cursor: pointer;
					position: relative;
					top: -0.8px;
					margin: {
						bottom: 0;
						left: 3px;
					}
				}
				span {
					display: inline-block;
					cursor: pointer;
					color: var(--mainColor);
					transition: var(--transition);
					font-weight: 600;

					&:hover {
						color: var(--mainColor2);
					}
				}
				&.hide {
					display: none;

					&.active {
						display: block;
					}
				}
				&.see-all-btn {
					&.active {
						display: none;
					}
				}
			}
		}
	}
	.widget_features {
		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				position: relative;
				margin-bottom: 11px;
				color: var(--optionalColor);

				&:last-child {
					margin-bottom: 0;
				}
				input[type="checkbox"] {
					--active: var(--mainColor);
					--active-inner: var(--whiteColor);
					--focus: 2px rgba(39, 94, 254, 0.3);
					--border: #d5d5d5;
					--border-hover: var(--mainColor);
					--background: var(--whiteColor);
					--disabled: #f6f8ff;
					--disabled-inner: #e1e6f9;
					-webkit-appearance: none;
					-moz-appearance: none;
					height: 19px;
					outline: none;
					display: inline-block;
					vertical-align: top;
					position: relative;
					margin: 0;
					cursor: pointer;
					border: 1px solid var(--bc, var(--border));
					background: var(--b, var(--background));
					transition: background 0.3s, border-color 0.3s,
						box-shadow 0.2s;

					&:not(.switch) {
						border-radius: 0;
					}
					&:not(.switch),
					&:not(.switch) {
						width: 19px;
					}
					&:not(.switch):after {
						width: 5px;
						height: 9px;
						border: 2px solid var(--active-inner);
						border-top: 0;
						border-left: 0;
						left: 6px;
						top: 3.5px;
						-webkit-transform: rotate(var(--r, 20deg));
						transform: rotate(var(--r, 20deg));
					}
					&:not(.switch):after,
					&:not(.switch):after {
						opacity: var(--o, 0);
					}
					&:after,
					&:after {
						content: "";
						display: block;
						left: 0;
						top: 0;
						position: absolute;
						transition: opacity var(--d-o, 0.2s),
							-webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
						transition: transform var(--d-t, 0.3s)
								var(--d-t-e, ease),
							opacity var(--d-o, 0.2s);
						transition: transform var(--d-t, 0.3s)
								var(--d-t-e, ease),
							opacity var(--d-o, 0.2s),
							-webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
					}
					&:not(.switch):checked {
						--r: 43deg;
					}
					&:not(.switch):checked,
					&:not(.switch):checked {
						--o: 1;
					}
					&:checked,
					&:checked {
						--b: var(--active);
						--bc: var(--active);
						--d-o: 0.3s;
						--d-t: 0.6s;
						--d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
					}
				}
				label {
					cursor: pointer;
					position: relative;
					top: -0.8px;
					margin: {
						bottom: 0;
						left: 3px;
					}
				}
				span {
					display: inline-block;
					cursor: pointer;
					color: var(--mainColor);
					transition: var(--transition);
					font-weight: 600;

					&:hover {
						color: var(--mainColor2);
					}
				}
				&.hide {
					display: none;

					&.active {
						display: block;
					}
				}
				&.see-all-btn {
					&.active {
						display: none;
					}
				}
			}
		}
	}
	.widget_distance {
		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				position: relative;
				margin-bottom: 11px;
				color: var(--optionalColor);

				&:last-child {
					margin-bottom: 0;
				}
				input[type="checkbox"] {
					--active: var(--mainColor);
					--active-inner: var(--whiteColor);
					--focus: 2px rgba(39, 94, 254, 0.3);
					--border: #d5d5d5;
					--border-hover: var(--mainColor);
					--background: var(--whiteColor);
					--disabled: #f6f8ff;
					--disabled-inner: #e1e6f9;
					-webkit-appearance: none;
					-moz-appearance: none;
					height: 19px;
					outline: none;
					display: inline-block;
					vertical-align: top;
					position: relative;
					margin: 0;
					cursor: pointer;
					border: 1px solid var(--bc, var(--border));
					background: var(--b, var(--background));
					transition: background 0.3s, border-color 0.3s,
						box-shadow 0.2s;

					&:not(.switch) {
						border-radius: 0;
					}
					&:not(.switch),
					&:not(.switch) {
						width: 19px;
					}
					&:not(.switch):after {
						width: 5px;
						height: 9px;
						border: 2px solid var(--active-inner);
						border-top: 0;
						border-left: 0;
						left: 6px;
						top: 3.5px;
						-webkit-transform: rotate(var(--r, 20deg));
						transform: rotate(var(--r, 20deg));
					}
					&:not(.switch):after,
					&:not(.switch):after {
						opacity: var(--o, 0);
					}
					&:after,
					&:after {
						content: "";
						display: block;
						left: 0;
						top: 0;
						position: absolute;
						transition: opacity var(--d-o, 0.2s),
							-webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
						transition: transform var(--d-t, 0.3s)
								var(--d-t-e, ease),
							opacity var(--d-o, 0.2s);
						transition: transform var(--d-t, 0.3s)
								var(--d-t-e, ease),
							opacity var(--d-o, 0.2s),
							-webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
					}
					&:not(.switch):checked {
						--r: 43deg;
					}
					&:not(.switch):checked,
					&:not(.switch):checked {
						--o: 1;
					}
					&:checked,
					&:checked {
						--b: var(--active);
						--bc: var(--active);
						--d-o: 0.3s;
						--d-t: 0.6s;
						--d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
					}
				}
				label {
					cursor: pointer;
					position: relative;
					top: -0.8px;
					margin: {
						bottom: 0;
						left: 3px;
					}
				}
				span {
					display: inline-block;
					cursor: pointer;
					color: var(--mainColor);
					transition: var(--transition);
					font-weight: 600;

					&:hover {
						color: var(--mainColor2);
					}
				}
				&.hide {
					display: none;

					&.active {
						display: block;
					}
				}
				&.see-all-btn {
					&.active {
						display: none;
					}
				}
			}
		}
	}
}

/*================================================
About Area CSS
=================================================*/
.about-content {
	padding-right: 25px;

	h2 {
		font-size: 30px;
		margin-bottom: 18px;
	}
	span {
		display: block;
		margin-bottom: 12px;
	}
}
.about-image {
	position: relative;

	.video-btn {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		width: 80px;
		height: 80px;
		line-height: 91px;
		text-align: center;
		border-radius: 50%;
		background: var(--mainColor);
		color: var(--whiteColor);
		font-size: 65px;
		z-index: 1;

		&::after,
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			z-index: -1;
			width: 80px;
			height: 80px;
			left: 0;
			transition: var(--transition);
			border-radius: 50%;
			border: 1px solid rgba(255, 255, 255, 0.6);
		}
		&::before {
			animation: ripple 2s linear infinite;
		}
		&::after {
			animation: ripple 2s linear 1s infinite;
		}
		&:hover {
			background-color: var(--whiteColor);
			color: var(--mainColor);
		}
	}
}
@keyframes ripple {
	0% {
		transform: scale(1);
	}
	75% {
		transform: scale(1.75);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		opacity: 0;
	}
}

/*================================================
Category Area CSS
=================================================*/
.single-category-box {
	margin-bottom: 35px;
	box-shadow: 5px 5px #b2eaea;
	border: 1px solid #96e3e3;
	border-radius: 5px;
	position: relative;
	transition: var(--transition);
	text-align: center;
	background-color: var(--whiteColor);
	padding: 25px 14px;

	.icon {
		margin-bottom: 15px;
		width: 55px;
		height: 55px;
		line-height: 55px;
		color: var(--mainColor);
		border-radius: 50%;
		transition: var(--transition);
		background-color: #ededed;
		display: inline-block;
		font-size: 27px;
	}
	h3 {
		font-size: 18px;
		margin-bottom: 6px;
	}
	span {
		display: block;
		color: var(--optionalColor);
		font-weight: 500;
	}
	.link-btn {
		border-radius: 5px;
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 2;
	}
	&.more-categories {
		background-color: var(--mainColor);
		border-color: var(--mainColor);
		color: var(--whiteColor);
		box-shadow: 5px 5px var(--mainColor);

		.icon {
			margin-top: 14px;
			background-color: var(--whiteColor) !important;
			color: var(--mainColor) !important;
		}
		h3 {
			margin-bottom: 14px;
		}
	}
	&:hover {
		border-color: var(--mainColor);
		box-shadow: 5px 5px var(--mainColor);

		.icon {
			background-color: var(--mainColor);
			color: var(--whiteColor);
		}
	}
}
.single-category-item {
	position: relative;
	margin-bottom: 30px;
	border-radius: 5px;
	background-color: var(--whiteColor);
	transition: var(--transition);

	.image {
		position: relative;
		height: 100%;
		border-radius: 5px;
		text-align: center;
		background: {
			position: center center;
			size: cover;
			repeat: no-repeat;
		}
		img {
			border-radius: 5px;
			display: none;
		}
		i {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			z-index: 2;
			color: var(--whiteColor);
			line-height: 1;
			font-size: 50px;
		}
		&.bg-img1 {
			background-image: url(../images/category/category1.jpg);
		}
		&.bg-img2 {
			background-image: url(../images/category/category2.jpg);
		}
		&.bg-img3 {
			background-image: url(../images/category/category3.jpg);
		}
		&.bg-img4 {
			background-image: url(../images/category/category4.jpg);
		}
		&.bg-img5 {
			background-image: url(../images/category/category5.jpg);
		}
		&.bg-img6 {
			background-image: url(../images/category/category6.jpg);
		}
		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			border-radius: 5px;
			background-color: #000000;
			z-index: 1;
			opacity: 0.7;
		}
	}
	.content {
		padding: 30px 20px;
		border: 1px solid var(--mainColor2);
		border-left: none;
		border-radius: 0 5px 5px 0;

		h3 {
			font-size: 18px;
			margin-bottom: 6px;

			a {
				color: var(--blackColor);
			}
		}
		span {
			display: block;
			color: var(--optionalColor);
			font-weight: 500;
			transition: var(--transition);
		}
	}
	.link-btn {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 3;
		border-radius: 5px;
		display: block;
	}
	&:hover {
		background-color: var(--mainColor2);

		.content {
			span {
				color: var(--blackColor);
			}
		}
	}
}

/*================================================
Destinations Area CSS
=================================================*/
.single-destinations-box {
	position: relative;
	z-index: 1;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 5px 5px var(--whiteColor);
	margin-bottom: 35px;

	img {
		width: 100%;
		border-radius: 10px;
		transition: var(--transition);
	}
	.link-btn {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 3;
		border-radius: 10px;
		display: block;
	}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		transition: var(--transition);
		border-radius: 10px;
		z-index: 1;
		background-color: #000000;
		opacity: 0.45;
	}
	.country {
		right: 0;
		position: absolute;
		padding: 25px;
		z-index: 2;
		color: var(--whiteColor);
		font-weight: 600;
		left: 0;
		top: 0;
	}
	.content {
		position: absolute;
		left: 0;
		right: 0;
		padding: 25px;
		z-index: 2;
		bottom: 0;

		h3 {
			color: var(--whiteColor);
			font-size: 21px;
			margin-bottom: 13px;
		}
		span {
			display: inline-block;
			color: var(--whiteColor);
			position: relative;
			z-index: 1;
			font-size: 16.3px;
			line-height: 1;

			&::before {
				width: 100%;
				position: absolute;
				left: 0;
				bottom: 2px;
				content: "";
				background-color: var(--mainColor);
				z-index: -1;
				height: 3px;
			}
		}
	}
	&.color-box-shadow {
		box-shadow: 5px 5px #b2eaea;
		transition: var(--transition);

		&:hover {
			box-shadow: 5px 5px var(--mainColor);
		}
	}
	&:hover {
		&::before {
			opacity: 0.3;
		}
		img {
			transform: scale(1.12);
		}
	}
}

/*================================================
Places Area CSS
=================================================*/
.single-places-box {
	margin-bottom: 30px;
	border-radius: 10px;
	position: relative;
	background-color: #f9f9f9;
	box-shadow: 0 5px #e3e3e3;

	.image {
		position: relative;
		border-radius: 10px 10px 0 0;
		overflow: hidden;

		img {
			border-radius: 10px 10px 0 0;
			transition: var(--transition);
		}
		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			transition: var(--transition);
			border-radius: 10px 10px 0 0;
			z-index: 1;
			background-color: #000000;
			opacity: 0.45;
		}
		.country {
			right: 0;
			position: absolute;
			padding: 25px;
			z-index: 2;
			color: var(--whiteColor);
			font-weight: 600;
			left: 0;
			top: 0;
		}
	}
	.content {
		padding: 20px;

		h3 {
			font-size: 22px;
			margin-bottom: 8px;
		}
		span {
			display: inline-block;
			position: relative;
			z-index: 1;
			font-size: 16.3px;
			line-height: 1;

			&::before {
				width: 100%;
				position: absolute;
				left: 0;
				bottom: 2px;
				content: "";
				background-color: var(--mainColor);
				z-index: -1;
				height: 3px;
			}
		}
	}
	.link-btn {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 3;
		border-radius: 10px;
		display: block;
	}
	&:hover {
		.image {
			img {
				transform: scale(1.12);
			}
		}
	}
}
.places-slides {
	&.owl-theme {
		.owl-nav {
			margin-top: 0;
			transition: var(--transition);
			opacity: 0;
			visibility: hidden;

			[class*="owl-"] {
				color: var(--blackColor);
				font-size: 18px;
				margin: 0;
				padding: 0;
				background: var(--whiteColor);
				border-radius: 50%;
				width: 40px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				transition: var(--transition);
				border: 1px solid var(--mainColor);
				position: absolute;
				left: -20px;
				top: 50%;
				transform: translateY(-50%);
				box-shadow: -2px 2px var(--mainColor);

				&.owl-next {
					left: auto;
					right: -20px;
					box-shadow: 2px 2px var(--mainColor);
				}
				&:hover {
					box-shadow: 2px 2px transparent;
					background-color: var(--mainColor);
					color: var(--whiteColor);
				}
				&.disabled {
					cursor: not-allowed;
					background-color: var(--whiteColor);
					color: var(--blackColor);
				}
			}
		}
		&:hover {
			.owl-nav {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
	&.bg-image {
		background: {
			image: url(../images/feedback-bg.jpg);
			position: center center;
			size: cover;
			repeat: no-repeat;
		}
		.section-title {
			h2 {
				color: var(--whiteColor);
			}
			p {
				color: var(--whiteColor);
			}
		}
	}
}
.single-feedback-box {
	box-shadow: 5px 5px var(--mainColor);
	border: 1px solid #f9f9f9;
	border-radius: 5px;
	position: relative;
	z-index: 1;
	transition: var(--transition);
	background-color: var(--whiteColor);
	padding: 35px 30px;
	margin: {
		bottom: 35px;
		right: 5px;
	}
	.rating {
		margin-bottom: 8px;

		i {
			color: #efc02f;
			margin-right: -3px;
		}
	}
	&::before {
		content: "\f128";
		position: absolute;
		right: 20px;
		bottom: 30px;
		line-height: 1;
		color: #ededed;
		z-index: -1;
		font: {
			family: Flaticon;
			size: 90px;
		}
	}
	.client-info {
		margin-top: 20px;

		img {
			width: 60px !important;
			display: inline-block !important;
			height: 60px;
			border-radius: 50%;
		}
		.title {
			margin-left: 13px;

			h3 {
				margin-bottom: 5px;
				font: {
					size: 17px;
					weight: 600;
				}
			}
			span {
				display: block;
				font-size: 14.5px;
				color: var(--optionalColor);
			}
		}
	}
}
.single-feedback-item {
	position: relative;
	z-index: 1;
	transition: var(--transition);
	text-align: center;
	background-color: var(--whiteColor);
	padding: 35px 30px;
	margin: {
		bottom: 35px;
		right: 5px;
	}
	.rating {
		margin-bottom: 8px;

		i {
			color: #efc02f;
			margin: {
				left: -1px;
				right: -1px;
			}
		}
	}
	&::before {
		content: "\f128";
		position: absolute;
		right: 20px;
		bottom: 30px;
		line-height: 1;
		color: #ededed;
		z-index: -1;
		font: {
			family: Flaticon;
			size: 90px;
		}
	}
	.client-info {
		margin-top: 20px;

		img {
			width: 60px !important;
			display: inline-block !important;
			height: 60px;
			border-radius: 50%;
			margin-right: 7px;
		}
		.title {
			text-align: left;
			margin-left: 7px;

			h3 {
				margin-bottom: 5px;
				font: {
					size: 17px;
					weight: 600;
				}
			}
			span {
				display: block;
				font-size: 14.5px;
				color: var(--optionalColor);
			}
		}
	}
}
.feedback-slides {
	&.owl-theme {
		.owl-nav {
			margin-top: 0;
			transition: var(--transition);
			opacity: 0;
			visibility: hidden;

			[class*="owl-"] {
				color: var(--blackColor);
				font-size: 18px;
				margin: 0;
				padding: 0;
				background: var(--whiteColor);
				border-radius: 50%;
				width: 40px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				transition: var(--transition);
				border: 1px solid var(--mainColor);
				position: absolute;
				left: -65px;
				top: 50%;
				transform: translateY(-50%);
				box-shadow: -2px 2px var(--mainColor);

				&.owl-next {
					left: auto;
					right: -65px;
					box-shadow: 2px 2px var(--mainColor);
				}
				&:hover {
					box-shadow: 2px 2px transparent;
					background-color: var(--mainColor);
					color: var(--whiteColor);
				}
				&.disabled {
					cursor: not-allowed;
					background-color: var(--whiteColor);
					color: var(--blackColor);
				}
			}
		}
		&:hover {
			.owl-nav {
				opacity: 1;
				visibility: visible;
			}
		}
		.owl-dots {
			margin-top: 5px;

			.owl-dot {
				span {
					width: 12px;
					height: 12px;
					margin: 0 4px;
					background: #b9b9b9;
					border-radius: 50%;
					transition: var(--transition);
				}
				&:hover,
				&.active {
					span {
						background-color: var(--mainColor);
					}
				}
			}
		}
	}
}

/*================================================
How It Works Area CSS
=================================================*/
.single-how-it-works-box {
	margin-bottom: 30px;
	text-align: center;
	position: relative;
	z-index: 1;
	padding: {
		left: 26px;
		right: 26px;
	}
	.icon {
		margin-bottom: 30px;
		display: inline-block;
		width: 80px;
		height: 80px;
		line-height: 80px;
		background-color: var(--whiteColor);
		border: 1px solid var(--mainColor);
		box-shadow: 5px 5px var(--mainColor);
		text-align: center;
		color: var(--mainColor);
		font-size: 40px;
	}
	h3 {
		font-size: 22px;
		margin-bottom: 12px;
	}
	&::before {
		width: 197px;
		height: 73px;
		content: "";
		position: absolute;
		right: -100px;
		top: 0;
		z-index: -1;
		background: {
			image: url(../images/arrow-right.png);
			position: center center;
			size: cover;
			repeat: no-repeat;
		}
	}
	&:hover {
		.icon {
			animation: bounce 2s;
		}
	}
}
.col-lg-4 {
	&:nth-child(3) {
		.single-how-it-works-box {
			&::before {
				display: none;
			}
		}
	}
}

/*================================================
Events Area CSS
=================================================*/
.events-item-list {
	margin-bottom: 30px;

	h2 {
		margin-bottom: 35px;
		font-size: 30px;
	}
	.single-events-item {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
	.single-events-box {
		margin-bottom: 17px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
.single-events-item {
	margin-bottom: 35px;
	box-shadow: 5px 5px #b2eaea;
	border: 1px solid #96e3e3;
	border-radius: 5px;
	transition: var(--transition);
	background-color: var(--whiteColor);
	padding: 21.5px 20px;
	position: relative;

	.meta {
		display: block;
		border-bottom: 1px solid #efefef;
		padding-bottom: 14px;
		margin-bottom: 12px;
		color: var(--optionalColor);
		font: {
			size: 14px;
			weight: 600;
		}
		i {
			font-weight: normal;
			color: var(--mainColor);
			margin-right: 2px;
			width: 28px;
			height: 28px;
			text-align: center;
			display: inline-block;
			line-height: 28px;
			background-color: #e8e8e8;
			border-radius: 50%;
			transition: var(--transition);
		}
		&:hover {
			i {
				background-color: var(--mainColor);
				color: var(--whiteColor);
			}
		}
	}
	h3 {
		font-size: 20px;
		margin-bottom: 0;
		line-height: 1.4;
	}
	.link-btn {
		position: absolute;
		right: -15px;
		top: 50%;
		transform: translateY(-50%);
		box-shadow: 3px 3px #b2eaea;
		border: 1px solid #96e3e3;
		display: inline-block;
		width: 44px;
		height: 44px;
		text-align: center;
		background-color: var(--whiteColor);
		border-radius: 50%;
		line-height: 44px;
		font-size: 22px;
		opacity: 0;
		visibility: hidden;

		&:hover {
			background-color: var(--mainColor);
			color: var(--whiteColor);
			border-color: var(--mainColor);
			box-shadow: unset;
		}
	}
	&:hover {
		border-color: var(--mainColor);
		box-shadow: 5px 5px var(--mainColor);

		.link-btn {
			opacity: 1;
			visibility: visible;
			right: -22px;
		}
	}
}
.single-events-box {
	margin-bottom: 35px;
	box-shadow: 5px 5px #b2eaea;
	border: 1px solid #96e3e3;
	border-radius: 5px;
	transition: var(--transition);
	background-color: var(--whiteColor);

	.image {
		height: 100%;
		border-radius: 5px;
		border-right: 1px solid #96e3e3;
		position: relative;
		background: {
			position: center center;
			size: cover;
			repeat: no-repeat;
		}
		img {
			border-radius: 5px;
			display: none;
		}
		&.bg-1 {
			background-image: url(../images/events/events1.jpg);
		}
		&.bg-2 {
			background-image: url(../images/events/events2.jpg);
		}
		&.bg-3 {
			background-image: url(../images/events/events3.jpg);
		}
		&.bg-4 {
			background-image: url(../images/events/events4.jpg);
		}
		&.bg-5 {
			background-image: url(../images/events/events5.jpg);
		}
		&.bg-6 {
			background-image: url(../images/events/events6.jpg);
		}
		.link-btn {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			border-radius: 5px;
			display: block;
			z-index: 2;
		}
	}
	.content {
		padding: 11.5px 20px;

		.meta {
			display: block;
			border-bottom: 1px solid #efefef;
			padding-bottom: 14px;
			margin-bottom: 12px;
			color: var(--optionalColor);
			font: {
				size: 14px;
				weight: 600;
			}
			i {
				font-weight: normal;
				color: var(--mainColor);
				margin-right: 2px;
				width: 28px;
				height: 28px;
				text-align: center;
				display: inline-block;
				line-height: 28px;
				background-color: #e8e8e8;
				border-radius: 50%;
				transition: var(--transition);
			}
			&:hover {
				i {
					background-color: var(--mainColor);
					color: var(--whiteColor);
				}
			}
		}
		h3 {
			font-size: 20px;
			margin-bottom: 0;
			line-height: 1.4;
		}
	}
	&:hover {
		border-color: var(--mainColor);
		box-shadow: 5px 5px var(--mainColor);
	}
}
.events-box {
	margin-bottom: 35px;
	border-radius: 5px;
	box-shadow: 5px 5px #b2eaea;
	overflow: hidden;
	transition: var(--transition);
	position: relative;

	img {
		border-radius: 5px;
		transition: var(--transition);
	}
	&::before {
		z-index: 1;
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: 5px;
		background-color: #000000;
		opacity: 0.5;
	}
	.link-btn {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: 5px;
		display: block;
		z-index: 3;
	}
	.content {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		padding: 25px;

		.meta {
			display: block;
			border-top: 1px solid #6c6c6c;
			padding-top: 14px;
			margin-top: 12px;
			color: var(--whiteColor);
			font: {
				size: 14px;
				weight: 600;
			}
			i {
				font-weight: normal;
				color: var(--mainColor);
				margin-right: 2px;
				width: 28px;
				height: 28px;
				text-align: center;
				display: inline-block;
				line-height: 28px;
				background-color: #e8e8e8;
				border-radius: 50%;
				transition: var(--transition);
			}
			&:hover {
				i {
					background-color: var(--mainColor);
					color: var(--whiteColor);
				}
			}
		}
		h3 {
			font-size: 20px;
			color: var(--whiteColor);
			margin-bottom: 0;
		}
	}
	&:hover {
		box-shadow: 5px 5px var(--mainColor);

		img {
			transform: scale(1.12);
		}
	}
}

/*================================================
Events Details Area CSS
=================================================*/
.events-details-header {
	margin-bottom: 40px;

	ul {
		margin-bottom: 0;
		list-style-type: none;
		background-color: #f5f5f5;
		padding: {
			left: 30px;
			right: 30px;
			top: 14px;
			bottom: 14px;
		}
		li {
			display: inline-block;
			margin-right: 18px;
			color: var(--optionalColor);
			font-weight: 500;
			position: relative;
			padding-left: 22px;

			i {
				color: var(--mainColor);
				position: absolute;
				left: 0;
				top: 1px;
				font-size: 18px;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
.events-details-desc {
	padding: {
		left: 25px;
		right: 25px;
	}
	h3 {
		margin-bottom: 12px;
		font-size: 20px;

		&:not(:first-child) {
			margin-top: 30px;
		}
	}
}
.events-details-image {
	position: relative;
	margin-bottom: 50px;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		background-color: #000000;
		opacity: 0.25;
	}
	#timer {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 2;
		transform: translateY(-50%) translateX(-50%);

		div {
			background-color: transparent;
			color: var(--whiteColor);
			width: 130px;
			height: 130px;
			position: relative;
			z-index: 1;
			border-radius: 5px;
			font: {
				size: 40px;
				weight: 700;
			}
			margin: {
				left: 8px;
				right: 8px;
			}
			span {
				display: block;
				margin-top: -6px;
				text-transform: uppercase;
				font: {
					size: 16px;
					weight: 500;
				}
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				border-radius: 5px;
				background-color: var(--mainColor);
				opacity: 0.7;
				z-index: -1;
			}
		}
	}
}
.events-details-info {
	background-color: #f5f5f5;
	border-radius: 5px;
	padding: 35px;

	.info {
		margin-bottom: 0;
		list-style-type: none;
		padding-left: 0;

		li {
			border-bottom: 1px solid #e5e5e7;
			color: var(--optionalColor);
			font: {
				size: 17px;
				weight: 500;
			}
			padding: {
				top: 15px;
				bottom: 14px;
			}
			span {
				font-weight: 600;
				color: var(--blackColor);
				position: relative;
			}
			&.price {
				padding-bottom: 10px;
				color: var(--mainColor);
				font-weight: 700;
			}
			&:first-child {
				padding-top: 0;
			}
		}
	}
	.btn-box {
		text-align: center;
		margin-top: 30px;

		.default-btn {
			display: block;
			padding: {
				right: 30px;
				left: 30px;
				top: 12px;
				bottom: 12px;
			}
			i {
				position: relative;
				top: 2px;
				margin-right: 8px;
				line-height: 1;
				font-size: 20px;
			}
		}
		p {
			margin-top: 15px;
			font-weight: 500;

			a {
				color: var(--mainColor);

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.events-share {
		text-align: center;
		margin-top: 25px;

		.share-info {
			display: inline-block;
			position: relative;
			cursor: pointer;

			span {
				display: inline-block;
				color: var(--mainColor);
				line-height: 1;
				font: {
					size: var(--fontSize);
					weight: 500;
				}
				i {
					color: var(--blackColor);
				}
			}
			.social-link {
				padding-left: 0;
				list-style-type: none;
				margin: {
					bottom: 0;
					top: 12px;
				}
				li {
					display: inline-block;
					margin-right: 3px;

					&:last-child {
						margin-right: 0;
					}
					a {
						width: 30px;
						height: 30px;
						text-align: center;
						background-color: #e1e1e1;
						font-size: 18px;
						color: var(--blackColor);
						position: relative;
						border-radius: 2px;

						i {
							position: absolute;
							left: 0;
							top: 50%;
							right: 0;
							transform: translateY(-48%);
						}
						&:hover {
							background-color: var(--mainColor);
							color: var(--whiteColor);
						}
					}
				}
			}
		}
	}
}
.events-details-location {
	margin-bottom: 40px;

	#map {
		iframe {
			height: 400px;
			width: 100%;
		}
	}
}

/*================================================
Blog Area CSS
=================================================*/
.blog-item-list {
	h2 {
		margin-bottom: 35px;
		font-size: 30px;
	}
}
.single-blog-post {
	margin-bottom: 35px;
	box-shadow: 5px 5px #b2eaea;
	border: 1px solid #96e3e3;
	border-radius: 5px;
	transition: var(--transition);
	background-color: var(--whiteColor);

	.post-image {
		border-radius: 5px 5px 0 0;

		a {
			border-radius: 5px 5px 0 0;

			img {
				border-radius: 5px 5px 0 0;
				width: 100%;
			}
		}
	}
	.post-content {
		padding: 25px 20px;
		position: relative;

		.post-meta {
			margin-bottom: 14px;
			border-bottom: 1px solid #efefef;
			list-style-type: none;
			padding: {
				left: 0;
				bottom: 15px;
			}
			li {
				margin-right: 12px;
				color: var(--optionalColor);
				font: {
					size: 14px;
					weight: 600;
				}
				&:last-child {
					margin-right: 0;
				}
				img {
					width: 40px;
					border-radius: 50%;
					height: 40px;
				}
				span {
					display: inline-block;
					margin-left: 8px;

					a {
						display: inline-block;
						color: var(--optionalColor);
					}
				}
				i {
					font-weight: normal;
					color: var(--mainColor);
					margin-right: 2px;
					width: 28px;
					height: 28px;
					text-align: center;
					display: inline-block;
					line-height: 28px;
					background-color: #e8e8e8;
					border-radius: 50%;
					transition: var(--transition);
				}
				&:hover {
					i {
						background-color: var(--mainColor);
						color: var(--whiteColor);
					}
				}
			}
		}
		h3 {
			margin-bottom: 0;
			font-size: 20px;
			line-height: 1.4;
		}
		.link-btn {
			position: absolute;
			right: 20px;
			top: -22px;
			box-shadow: 3px 3px #b2eaea;
			border: 1px solid #96e3e3;
			display: inline-block;
			width: 44px;
			height: 44px;
			text-align: center;
			background-color: var(--whiteColor);
			border-radius: 50%;
			line-height: 44px;
			font-size: 22px;

			&:hover {
				background-color: var(--mainColor);
				color: var(--whiteColor);
				border-color: var(--mainColor);
				box-shadow: unset;
			}
		}
	}
	&.without-boxshadow {
		box-shadow: unset;
	}
	&:hover {
		border-color: var(--mainColor);
		box-shadow: 5px 5px var(--mainColor);
	}
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
	background-color: var(--whiteColor);
	padding: 25px;

	.article-image {
		img {
			width: 100%;
		}
	}
	.article-image-slides {
		&.owl-theme {
			.owl-nav {
				margin-top: 0;
				opacity: 0;
				visibility: hidden;
				transition: var(--transition);

				[class*="owl-"] {
					color: #87a1c1;
					font-size: 18px;
					margin: 0;
					padding: 0;
					background: var(--whiteColor);
					width: 45px;
					height: 45px;
					line-height: 40px;
					position: absolute;
					left: 20px;
					transition: var(--transition);
					top: 50%;
					transform: translateY(-50%);
					border: {
						radius: 50%;
						style: solid;
						color: var(--whiteColor);
						width: 1.5px;
					}
					&.owl-next {
						left: auto;
						right: 20px;
					}
					&:hover {
						background-color: var(--mainColor);
						color: var(--whiteColor);
						border-color: var(--mainColor);
					}
				}
			}
			&:hover {
				.owl-nav {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	.article-video {
		iframe {
			width: 100%;
			height: 400px;
			border: none !important;
		}
	}
	.article-content {
		margin-top: 30px;

		.entry-meta {
			ul {
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;

				li {
					margin-right: 20px;
					position: relative;
					display: inline-block;
					border-right: 1px solid #eeeeee;
					font: {
						weight: 500;
					}
					padding: {
						right: 20px;
						left: 42px;
					}
					i {
						font-size: 32px;
						color: #cfcfcf;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}
					span {
						display: block;
						color: var(--mainColor);
						text-transform: uppercase;
						margin-bottom: 2px;
						font: {
							size: 14px;
							weight: 500;
						}
					}
					&:last-child {
						padding-right: 0;
						margin-right: 0;
						border-right: none;
					}
				}
			}
		}
		h3 {
			font-size: 22px;
			margin: {
				bottom: 17px;
				top: 25px;
			}
		}
		.wp-block-gallery {
			&.columns-3 {
				padding-left: 0;
				list-style-type: none;
				display: flex;
				flex-wrap: wrap;
				margin: {
					right: -10px;
					left: -10px;
					bottom: 30px;
					top: 30px;
				}
				li {
					flex: 0 0 33.3333%;
					max-width: 33.3333%;
					padding: {
						right: 10px;
						left: 10px;
					}
					figure {
						margin-bottom: 0;
					}
				}
			}
		}
		.features-list {
			padding-left: 0;
			list-style-type: none;
			margin: {
				top: 25px;
				bottom: 30px;
			}
			li {
				margin-bottom: 15px;
				position: relative;
				padding-left: 26px;
				color: var(--optionalColor);

				i {
					color: var(--mainColor);
					display: inline-block;
					font-size: 20px;
					position: absolute;
					left: 0;
					top: 1px;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.article-footer {
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid #eeeeee;
		padding-top: 30px;
		margin: {
			top: 30px;
		}
		.article-tags {
			flex: 0 0 50%;
			max-width: 50%;
			color: var(--optionalColor);

			span {
				display: inline-block;
				color: var(--blackColor);
				margin-right: 3px;
				position: relative;
				top: 3px;
				font: {
					size: 20px;
				}
			}
			a {
				display: inline-block;
				font: {
					weight: 600;
				}
			}
		}
		.article-share {
			flex: 0 0 50%;
			max-width: 50%;

			.social {
				padding-left: 0;
				list-style-type: none;
				text-align: right;
				margin-bottom: 0;

				li {
					display: inline-block;

					span {
						display: inline-block;
						margin-right: 3px;
						font-weight: 600;
						position: relative;
						top: -1px;
					}
					a {
						display: block;
						width: 32px;
						height: 32px;
						line-height: 34px;
						border-radius: 50%;
						background-color: var(--mainColor);
						color: var(--whiteColor);
						border: 1px solid var(--mainColor);
						text-align: center;
						font-size: 17px;
						margin-left: 2px;

						&:hover,
						&:focus {
							color: var(--mainColor);
							background-color: transparent;
						}
						&.facebook {
							background-color: #3b5998;
							border-color: #3b5998;
							color: var(--whiteColor);

							&:hover,
							&:focus {
								color: #3b5998;
								background-color: transparent;
							}
						}
						&.twitter {
							background-color: #1da1f2;
							border-color: #1da1f2;
							color: var(--whiteColor);

							&:hover,
							&:focus {
								color: #1da1f2;
								background-color: transparent;
							}
						}
						&.linkedin {
							background-color: #007bb5;
							border-color: #007bb5;
							color: var(--whiteColor);

							&:hover,
							&:focus {
								color: #007bb5;
								background-color: transparent;
							}
						}
						&.instagram {
							background-color: #c13584;
							border-color: #c13584;
							color: var(--whiteColor);

							&:hover,
							&:focus {
								color: #c13584;
								background-color: transparent;
							}
						}
					}
				}
			}
		}
	}
	.article-author {
		margin-top: 30px;
		border-radius: 5px;
		background: #fdfcfc;

		.author-profile-header {
			height: 115px;
			border-radius: 5px 5px 0 0;
			background: {
				color: var(--mainColor);
				image: url(../images/bg.jpg);
				position: center center;
				size: cover;
				repeat: no-repeat;
			}
		}
		.author-profile {
			padding: 0 25px 25px;

			.author-profile-title {
				position: relative;
				z-index: 1;
				margin: {
					top: -45px;
				}
				img {
					display: inline-block;
					border: 3px solid var(--whiteColor);
					width: 100px;
					height: 100px;
					margin-bottom: 25px;
				}
				h4 {
					margin-bottom: 8px;
					font-size: 20px;
				}
				span {
					display: block;
					margin-bottom: 12px;
					color: var(--optionalColor);
				}
			}
		}
	}
}
blockquote,
.blockquote {
	overflow: hidden;
	background-color: #fafafa;
	padding: 50px !important;
	position: relative;
	z-index: 1;
	margin: {
		bottom: 20px;
		top: 20px;
	}
	p {
		color: var(--blackColor);
		line-height: 1.6;
		margin-bottom: 0;
		font: {
			style: italic;
			weight: 700;
			size: 20px !important;
		}
	}
	cite {
		display: none;
	}
	&::before {
		color: #efefef;
		position: absolute;
		animation: fade-up 1.5s infinite linear;
		left: 50px;
		top: -50px;
		z-index: -1;
		content: "\f128";
		font: {
			family: Flaticon;
			size: 135px;
		}
	}
	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 3px;
		background-color: var(--mainColor);
		margin: {
			top: 20px;
			bottom: 20px;
		}
	}
}
.miran-post-navigation {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	margin-top: 30px;
	padding: {
		top: 30px;
		bottom: 30px;
	}
	border: {
		top: 1px solid #eeeeee;
		bottom: 1px solid #eeeeee;
	}
}
.prev-link-wrapper {
	flex: 0 0 50%;
	max-width: 50%;
	padding-right: 15px;

	a {
		display: flex;
		align-items: center;

		&:hover {
			.image-prev {
				&::after {
					opacity: 1;
					visibility: visible;
				}
				.post-nav-title {
					opacity: 1;
					visibility: visible;
				}
			}
			.prev-link-info-wrapper {
				color: var(--mainColor);
			}
		}
	}
	.image-prev {
		display: inline-block;
		min-width: 100px;
		width: 100px;
		border-radius: 5px;
		overflow: hidden;
		vertical-align: top;
		margin-right: 20px;
		position: relative;
		transition: var(--transition);

		img {
			border-radius: 5px;
		}
		&::after {
			display: block;
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 5px;
			opacity: 0;
			background-color: var(--mainColor);
			visibility: hidden;
			transition: var(--transition);
		}
		.post-nav-title {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			margin: 0 auto;
			text-align: center;
			text-transform: uppercase;
			z-index: 2;
			color: var(--whiteColor);
			opacity: 0;
			visibility: hidden;
			transition: var(--transition);
			font: {
				size: var(--fontSize);
				weight: 700;
			}
		}
	}
	.prev-link-info-wrapper {
		color: var(--blackColor);
		transition: var(--transition);
	}
	.prev-title {
		display: inline-block;
		font: {
			weight: 700;
			size: 15.5px;
		}
	}
	.meta-wrapper {
		display: block;
		color: var(--optionalColor);
		text-transform: capitalize;
		margin-top: 6px;
		font: {
			weight: 500;
			size: 14px;
		}
	}
}
.next-link-wrapper {
	flex: 0 0 50%;
	max-width: 50%;
	text-align: right;
	padding: {
		left: 15px;
	}
	a {
		display: flex;
		align-items: center;

		&:hover {
			.image-next {
				&::after {
					opacity: 1;
					visibility: visible;
				}
				.post-nav-title {
					opacity: 1;
					visibility: visible;
				}
			}
			.next-link-info-wrapper {
				color: var(--mainColor);
			}
		}
	}
	.image-next {
		display: inline-block;
		min-width: 100px;
		width: 100px;
		border-radius: 5px;
		overflow: hidden;
		vertical-align: top;
		margin-left: 20px;
		position: relative;
		transition: var(--transition);

		img {
			border-radius: 5px;
		}
		&::after {
			display: block;
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 5px;
			opacity: 0;
			background-color: var(--mainColor);
			visibility: hidden;
			transition: var(--transition);
		}
		.post-nav-title {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			margin: 0 auto;
			text-align: center;
			text-transform: uppercase;
			z-index: 2;
			color: var(--whiteColor);
			opacity: 0;
			visibility: hidden;
			transition: var(--transition);
			font: {
				size: var(--fontSize);
				weight: 700;
			}
		}
	}
	.next-link-info-wrapper {
		transition: var(--transition);
		color: var(--blackColor);
	}
	.next-title {
		display: inline-block;
		font: {
			weight: 700;
			size: 15.5px;
		}
	}
	.meta-wrapper {
		display: block;
		color: var(--optionalColor);
		text-transform: capitalize;
		margin-top: 6px;
		font: {
			weight: 500;
			size: 14px;
		}
	}
}
.comments-area {
	margin-top: 30px;

	.comments-title {
		line-height: initial;
		margin-bottom: 30px;
		font-size: 22px;
	}
	ol,
	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	.comment-list {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	.children {
		margin-left: 20px;
	}
	.comment-body {
		border-bottom: 1px dashed #eeeeee;
		padding-left: 70px;
		color: var(--blackColor);
		margin-bottom: 20px;
		padding-bottom: 20px;

		.reply {
			margin-top: 15px;

			a {
				border: 1px dashed #ded9d9;
				color: var(--blackColor);
				display: inline-block;
				padding: 5px 20px;
				border-radius: 30px;
				font: {
					size: 13px;
					weight: 600;
				}
				&:hover {
					color: var(--whiteColor);
					background-color: var(--mainColor);
					border-color: var(--mainColor);
				}
			}
		}
	}
	.comment-author {
		font-size: 16px;
		margin-bottom: 6px;
		position: relative;
		z-index: 2;

		.avatar {
			height: 50px;
			left: -65px;
			position: absolute;
			width: 50px;
		}
		.fn {
			font-weight: 600;
		}
		.says {
			display: none;
		}
	}
	.comment-metadata {
		margin-bottom: 0.7em;
		color: var(--optionalColor);
		letter-spacing: 0.01em;
		text-transform: uppercase;
		font: {
			size: 13px;
			weight: 400;
		}
		a {
			display: inline-block;
			color: var(--optionalColor);

			&:hover {
				color: var(--mainColor);
			}
		}
	}
	.comment-respond {
		margin-top: 30px;

		.comment-reply-title {
			margin-bottom: 0;
			font-size: 22px;

			#cancel-comment-reply-link {
				display: inline-block;
			}
		}
		.comment-form {
			overflow: hidden;
		}
		.comment-notes {
			margin: {
				bottom: 20px;
				top: 10px;
			}
			.required {
				color: red;
			}
		}
		.comment-form-comment {
			float: left;
			width: 100%;
		}
		label {
			display: none;
		}
		input[type="date"],
		input[type="time"],
		input[type="datetime-local"],
		input[type="week"],
		input[type="month"],
		input[type="text"],
		input[type="email"],
		input[type="url"],
		input[type="password"],
		input[type="search"],
		input[type="tel"],
		input[type="number"],
		textarea {
			display: block;
			width: 100%;
			background-color: #f4f4f4;
			border: none;
			padding: 1px 0 0 15px;
			height: 50px;
			outline: 0;
			border-radius: 3px;
			color: var(--blackColor);
			font-size: var(--fontSize);

			&::placeholder {
				transition: var(--transition);
				color: var(--optionalColor);
			}
			&:focus {
				&::placeholder {
					color: transparent;
				}
			}
		}
		textarea {
			height: auto !important;
			padding-top: 15px;
		}
		.comment-form-author {
			float: left;
			width: 50%;
			padding-right: 10px;
			margin-bottom: 20px;
		}
		.comment-form-email {
			float: left;
			width: 50%;
			padding-left: 12px;
			margin-bottom: 20px;
		}
		.comment-form-url {
			float: left;
			width: 100%;
			margin-bottom: 20px;
		}
		.comment-form-cookies-consent {
			width: 100%;
			float: left;
			position: relative;
			padding-left: 20px;
			margin: {
				bottom: 20px;
				top: 15px;
			}
			input {
				position: absolute;
				left: 0;
				top: 6px;
			}
			label {
				display: inline-block;
				margin: 0;
				color: var(--optionalColor);
				font: {
					weight: normal;
					size: var(--fontSize);
				}
			}
		}
		.form-submit {
			float: left;
			width: 100%;

			input {
				background: var(--mainColor);
				border: none;
				color: var(--whiteColor);
				padding: 12px 30px 10px;
				display: inline-block;
				cursor: pointer;
				text-transform: capitalize;
				transition: var(--transition);
				border-radius: 0;
				font: {
					weight: 600;
					size: var(--fontSize);
				}
				&:hover,
				&:focus {
					color: var(--whiteColor);
					background: var(--mainColor2);
				}
			}
		}
	}
}

/*================================================
Claim Your Business Area CSS
=================================================*/
.claim-your-business-content {
	h2 {
		font-size: 30px;
		margin-bottom: 12px;
	}
	form {
		background-color: var(--whiteColor);
		border-radius: 5px;
		border: 1px solid var(--mainColor);
		padding: 40px;
		margin-top: 40px;
		max-width: 500px;

		.form-group {
			margin-bottom: 18px;
		}
		label {
			display: block;
			color: #666666;
			margin-bottom: 10px;
			font: {
				weight: 500;
				size: var(--fontSize);
			}
		}
		.default-btn {
			margin-top: 7px;
		}
	}
}

/*================================================
Team Area CSS
=================================================*/
.single-team-member {
	margin-bottom: 30px;
	text-align: center;

	.member-image {
		position: relative;

		.social {
			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			margin: 0 auto;
			bottom: 0;
			list-style-type: none;
			padding-left: 0;
			margin-bottom: 0;
			opacity: 0;
			visibility: hidden;
			transition: var(--transition);

			li {
				display: inline-block;

				a {
					margin: 0 2px;
					display: inline-block;

					i {
						display: inline-block;
						width: 35px;
						height: 35px;
						line-height: 35px;
						border-radius: 50%;
						background-color: var(--mainColor);
						color: var(--whiteColor);
						font-size: 18px;
						transition: var(--transition);

						&.bxl-facebook {
							background-color: #3b5998;
						}
						&.bxl-twitter {
							background-color: #1da1f2;
						}
						&.bxl-linkedin {
							background-color: #0077b5;
						}
						&:hover {
							background-color: var(--mainColor);
							color: var(--whiteColor);
						}
					}
				}
			}
		}
	}
	.member-content {
		margin-top: 25px;

		h3 {
			margin-bottom: 0;
			font-size: 20px;
		}
		span {
			display: block;
			color: var(--mainColor);
			margin-top: 10px;
		}
		p {
			margin-top: 10px;
		}
	}
	&:hover {
		.member-image {
			.social {
				bottom: 15px;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

/*================================================
Manage Your Business Area CSS
=================================================*/
.manage-your-business-area {
	position: relative;
	z-index: 1;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		z-index: -1;
		background-color: var(--whiteColor);
		height: 60px;
	}
	&::after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		background-color: #f9f9f9;
		height: 60px;
	}
}
.manage-your-business-image {
	box-shadow: 7px 7px #0ec6c6;
	margin-right: 80px;
}
.manage-your-business-content {
	h2 {
		margin-bottom: 15px;
		font: {
			weight: 800;
			size: 35px;
		}
	}
	p {
		color: var(--blackColor);
	}
	.default-btn {
		margin-top: 10px;
		box-shadow: 4px 4px #0ec6c6;
		border-color: #0ec6c6;

		&:hover {
			border-color: var(--blackColor);
			box-shadow: 4px 4px var(--blackColor);
		}
	}
}

/*================================================
App Download Area CSS
=================================================*/
.app-download-area {
	position: relative;
	z-index: 1;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background-color: var(--whiteColor);
		z-index: -1;
		height: 90px;
	}
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: var(--whiteColor);
		z-index: -1;
		height: 70px;
	}
}
.app-download-content {
	h2 {
		font-size: 30px;
		margin-bottom: 12px;
	}
	p {
		color: var(--blackColor);
		line-height: initial;
	}
	.btn-box {
		margin-top: 30px;

		.playstore-btn {
			position: relative;
			background-color: #202020;
			display: inline-block;
			border-radius: 5px;
			color: var(--whiteColor);
			margin-right: 15px;
			font: {
				size: 14px;
				weight: 400;
			}
			padding: {
				top: 12px;
				bottom: 12px;
				left: 60px;
				right: 25px;
			}
			span {
				display: block;
				margin-top: -3px;
				font: {
					size: 18px;
					weight: 700;
				}
			}
			img {
				position: absolute;
				left: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
			&:hover {
				background-color: var(--whiteColor);
				color: var(--blackColor);
				transform: translateY(-3px);
			}
		}
		.applestore-btn {
			position: relative;
			background-color: #202020;
			display: inline-block;
			border-radius: 5px;
			color: var(--whiteColor);
			font: {
				size: 14px;
				weight: 400;
			}
			padding: {
				top: 12px;
				bottom: 12px;
				left: 65px;
				right: 25px;
			}
			span {
				display: block;
				margin-top: -3px;
				font: {
					size: 18px;
					weight: 700;
				}
			}
			img {
				position: absolute;
				left: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
			&:hover {
				background-color: var(--whiteColor);
				color: var(--blackColor);
				transform: translateY(-3px);
			}
		}
	}
}
.app-download-image {
	text-align: center;

	img {
		animation: moveleftbounce 5s linear infinite;
	}
}
@keyframes moveleftbounce {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}

/*================================================
Timeline Area CSS
=================================================*/
.main-timeline {
	padding-top: 25px;
	overflow: hidden;
	position: relative;

	&::before {
		content: "";
		width: 7px;
		height: 100%;
		background: #dfdde3;
		margin: 0 auto;
		position: absolute;
		top: 80px;
		left: 0;
		right: 0;
	}
	.timeline {
		width: 50%;
		float: left;
		padding: 20px 60px;
		border-top: 7px solid #dfdde3;
		border-right: 7px solid #dfdde3;
		border-radius: 0 30px 0 0;
		position: relative;
		right: -3.5px;

		&:nth-child(2n) {
			border-right: none;
			border-left: 7px solid #dfdde3;
			border-radius: 30px 0 0 0;
			right: auto;
			left: -3.5px;

			.icon {
				left: auto;
				right: 0;
			}
		}
		&:nth-child(2) {
			margin-top: 130px;
		}
		&:nth-child(odd) {
			margin: -130px 0 30px 0;
		}
		&:nth-child(even) {
			margin-bottom: 80px;
		}
		&:first-child,
		&:last-child:nth-child(even) {
			margin: 0 0 30px 0;
		}
		&:nth-child(2n) .timeline-content,
		&:nth-child(2n) .timeline-content:before,
		&:nth-child(2n) .timeline-content:after,
		&:nth-child(2n) .icon {
			background: var(--blackColor);
		}
		&:nth-child(2n) .icon {
			box-shadow: 0 0 0 2px var(--blackColor);
		}
		&:nth-child(3n) .timeline-content,
		&:nth-child(3n) .timeline-content:before,
		&:nth-child(3n) .timeline-content:after,
		&:nth-child(3n) .icon {
			background: #e56b1f;
		}
		&:nth-child(3n) .icon {
			box-shadow: 0 0 0 2px #e56b1f;
		}
		&:nth-child(4n) .timeline-content,
		&:nth-child(4n) .timeline-content:before,
		&:nth-child(4n) .timeline-content:after,
		&:nth-child(4n) .icon {
			background: #e42c6a;
		}
		&:nth-child(4n) .icon {
			box-shadow: 0 0 0 2px #e42c6a;
		}
		&:nth-child(5n) .timeline-content,
		&:nth-child(5n) .timeline-content:before,
		&:nth-child(5n) .timeline-content:after,
		&:nth-child(5n) .icon {
			background: #3778c2;
		}
		&:nth-child(5n) .icon {
			box-shadow: 0 0 0 2px #3778c2;
		}
	}
	.icon {
		display: block;
		width: 50px;
		height: 50px;
		line-height: 49px;
		border-radius: 50%;
		background: var(--mainColor);
		text-align: center;
		color: var(--whiteColor);
		position: absolute;
		top: -30px;
		left: 0;
		font: {
			size: 25px;
			weight: 700;
		}
	}
	.timeline-content {
		display: block;
		padding: 40px 20px 25px;
		border-radius: 20px;
		background: var(--mainColor);
		color: var(--whiteColor);
		position: relative;

		&:hover {
			text-decoration: none;
			color: var(--whiteColor);
		}
		&::before,
		&::after {
			content: "";
			display: block;
			width: 10px;
			height: 50px;
			border-radius: 10px;
			background: var(--mainColor);
			border: 1px solid var(--whiteColor);
			position: absolute;
			top: -35px;
			left: 50px;
		}
		&::after {
			left: auto;
			right: 50px;
		}
	}
	.title {
		color: var(--whiteColor);
		margin-bottom: 12px;
		font: {
			size: 22px;
			weight: 700;
		}
	}
	.description {
		color: var(--whiteColor);
		opacity: 0.95;
	}
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-content {
	h2 {
		font-size: 30px;
		margin-bottom: 12px;
	}
	p {
		color: var(--blackColor);
		line-height: initial;
	}
	form {
		margin-top: 30px;
		position: relative;

		.input-newsletter {
			display: block;
			width: 100%;
			font-size: 15px;
			height: 55px;
			padding-left: 20px;
			border: 1px solid var(--mainColor);
			border-radius: 0;
			background-color: var(--whiteColor);
			color: var(--blackColor);

			&::placeholder {
				color: #999999;
				transition: var(--transition);
			}
			&:focus {
				&::placeholder {
					color: transparent;
				}
			}
		}
		button {
			position: absolute;
			right: 0;
			top: 0;
			height: 55px;
			background-color: var(--mainColor);
			color: var(--whiteColor);
			transition: var(--transition);
			border: none;
			padding: 12px 40px;
			font: {
				size: var(--fontSize);
				weight: 600;
			}
			&:hover {
				color: var(--whiteColor);
				background-color: var(--blackColor);
			}
		}
	}
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
	padding: {
		bottom: 130px;
		top: 250px;
	}
	background: {
		color: var(--blackColor);
		position: center center;
		size: cover;
		repeat: no-repeat;
	}
	&.page-title-style-two {
		padding: {
			top: 140px;
			bottom: 120px;
		}
	}
	&.page-title-bg1 {
		background-image: url(../images/page-title/page-title1.jpg);
	}
	&.page-title-bg2 {
		background-image: url(../images/page-title/page-title2.jpg);
	}
	&.page-title-bg3 {
		background-image: url(../images/page-title/page-title3.jpg);
	}
	&.page-title-bg4 {
		background-image: url(../images/page-title/page-title4.jpg);
	}
	&.page-title-bg5 {
		background-image: url(../images/page-title/page-title5.jpg);
	}
}
.page-title-content {
	text-align: center;

	h2 {
		font-size: 37px;
		margin-bottom: 0;
		color: var(--whiteColor);
	}
	ul {
		padding-left: 0;
		list-style-type: none;
		margin: {
			bottom: 0;
			top: 22px;
		}
		li {
			display: inline-block;
			color: var(--whiteColor);
			font-weight: 500;
			position: relative;
			margin: {
				left: 10px;
				right: 10px;
			}
			a {
				color: var(--whiteColor);
				display: inline-block;
			}
			&::before {
				content: "\f11c";
				position: absolute;
				right: -18px;
				top: 3.2px;
				font: {
					family: Flaticon;
					size: 11px;
				}
			}
			&:last-child {
				&::before {
					display: none;
				}
			}
		}
	}
}
.page-title-bg {
	background-color: #f6f6f6;
	text-align: center;
	padding: {
		top: 80px;
		bottom: 80px;
	}
	h2 {
		margin-bottom: 0;
		font: {
			size: 40px;
			weight: 800;
		}
	}
	form {
		background-color: var(--whiteColor);
		box-shadow: 5px 5px #30eded;
		border-radius: 5px;
		max-width: 900px;
		z-index: 2;
		padding: {
			left: 20px;
			right: 12px;
		}
		margin: {
			left: auto;
			top: 30px;
			right: auto;
		}
		.form-group {
			margin: 0;
			position: relative;
			border-right: 1px solid #30eded;
			margin-left: 12px;

			label {
				margin-bottom: 0;
				position: absolute;
				left: 0;
				top: 19px;
				line-height: 1;
				font-size: 23px;
				color: #646464;
				z-index: 2;
			}
			&.category-select {
				border-right: none;
				padding-right: 20px;
			}
			.form-control {
				border: none !important;
				color: var(--blackColor);
				box-shadow: unset !important;
				background-color: transparent !important;
				height: 60px;
				line-height: 60px;
				font: {
					size: var(--fontSize);
					weight: 400;
				}
				padding: {
					top: 2px;
					bottom: 0;
					left: 30px;
					right: 15px;
				}
				&::placeholder {
					transition: var(--transition);
					color: #666666;
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
			.nice-select {
				border-radius: 0;
				border: none;
				float: unset;
				height: 60px;
				line-height: 60px;
				color: #666666;
				font: {
					size: 15px;
					weight: 400;
				}
				padding: {
					left: 30px;
					right: 15px;
					bottom: 0;
					top: 1px;
				}
				&:after {
					border-color: var(--mainColor);
					height: 8px;
					margin-top: -5px;
					right: 0;
					width: 8px;
				}
				.list {
					background-color: var(--whiteColor);
					border-radius: 0;
					box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
					list-style-type: none;
					border: none;
					width: 100%;
					height: 210px;
					overflow-y: scroll;
					margin: {
						bottom: 0;
						top: 0;
					}
					padding: {
						left: 0;
						top: 10px;
						bottom: 10px;
					}
					.option {
						line-height: 38px;
						min-height: 38px;
						color: var(--blackColor);
						position: relative;
						transition: var(--transition);
						padding: {
							left: 38px;
							right: 15px;
						}
						font: {
							size: var(--fontSize);
							weight: 500;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
						&.focus,
						&.selected.focus {
							background-color: transparent !important;
							color: var(--blackColor);
						}
						&::before {
							content: "\ea0f";
							position: absolute;
							left: 12px;
							top: -1px;
							opacity: 0;
							visibility: hidden;
							transition: var(--transition);
							color: var(--mainColor);
							font: {
								family: "boxicons";
								size: 20px;
							}
						}
						&.selected {
							&::before {
								opacity: 1;
								visibility: visible;
							}
							&:hover {
								background-color: var(--mainColor) !important;
								color: var(--whiteColor);

								&::before {
									color: var(--whiteColor);
								}
							}
						}
					}
				}
			}
		}
		.col-lg-4 {
			.form-group {
				margin-left: 0;
			}
		}
		.submit-btn {
			padding-left: 5px;

			button {
				display: block;
				width: 100%;
				background-color: var(--mainColor);
				border: none;
				color: var(--whiteColor);
				font-size: var(--fontSize);
				font-weight: 600;
				padding: 12px 15px;
				border-radius: 5px;
				transition: var(--transition);

				&:hover {
					background-color: var(--blackColor);
					color: var(--whiteColor);
				}
			}
		}
	}
	&.map-home-area {
		text-align: left;
		padding: {
			top: 0;
			bottom: 0;
		}
		#main-full-map {
			height: 600px;
		}
	}
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-tabs {
	.nav-tabs {
		border: none;
		text-align: center;
		display: block;
		flex-wrap: unset;
		margin-bottom: 40px;

		.nav-item {
			display: inline-block;
			margin: {
				bottom: 0;
				left: -3px;
				right: -3px;
			}
			.nav-link {
				display: block;
				background-color: var(--whiteColor);
				margin: 0;
				border: none;
				color: var(--blackColor);
				border-radius: 30px;
				padding: 14px 35px;
				position: relative;
				z-index: 1;
				transition: var(--transition);
				font: {
					size: var(--fontSize);
					weight: 600;
				}
				&::before {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					border-radius: 30px;
					background-color: #f9f9f9;
					z-index: -1;
					margin: 5px;
					transition: var(--transition);
				}
				&:hover {
					color: var(--whiteColor);

					&::before {
						opacity: 1;
						visibility: visible;
						background-color: var(--mainColor);
					}
				}
			}
			// &:first-child {
			//     .nav-link {
			//         border-radius: 30px 0 0 30px;
			//     }
			// }
			// &:last-child {
			//     .nav-link {
			//         border-radius: 0 30px 30px 0;
			//     }
			// }
		}
	}
}
.single-pricing-box {
	margin-bottom: 30px;
	position: relative;
	z-index: 1;
	border-radius: 5px;
	background-color: var(--whiteColor);
	padding: 40px 30px;

	h3 {
		margin-bottom: 0;
		background-color: #f9f9f9;
		text-align: center;
		font-size: 20px;
		padding: 12px 12px 11px;
	}
	.pricing-features {
		margin-bottom: 20px;
		padding-top: 30px;

		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				margin-bottom: 15px;
				color: #4b5280;
				position: relative;
				padding-left: 26px;

				&:last-child {
					margin-bottom: 0;
				}
				i {
					position: absolute;
					left: 0;
					top: 3px;
					width: 16px;
					height: 16px;
					background-color: var(--mainColor);
					color: var(--whiteColor);
					border-radius: 50%;
					line-height: 16px;
					text-align: center;
					display: inline-block;
					font-size: 13px;

					&.action-close {
						background-color: red;
						padding-top: 1px;
					}
				}
			}
		}
	}
	.price {
		font: {
			size: 35px;
			weight: 700;
		}
		span {
			display: block;
			color: #4b5280;
			margin-top: -2px;
			font: {
				size: 16px;
				weight: 500;
			}
		}
	}
	.default-btn {
		margin-top: 20px;
	}
}

/*================================================
Partner Area CSS
=================================================*/
.partner-item {
	text-align: center;

	a {
		display: block;
		background-color: var(--whiteColor);
		border-right: 1px solid #eeeeee;
		padding: 20px;

		img {
			width: auto !important;
			display: inline-block !important;
			opacity: 0.5;
			transition: var(--transition);
		}
		&:hover {
			img {
				opacity: 1;
			}
		}
	}
}

/*================================================
Funfacts Area CSS
=================================================*/
.single-funfacts {
	margin-bottom: 30px;
	position: relative;
	padding-left: 65px;

	i {
		position: absolute;
		left: 0;
		top: 0;
		font-size: 50px;
		color: var(--mainColor);
	}
	p {
		margin-bottom: 7px;
		line-height: initial;
		font-weight: 600;
		color: var(--blackColor);
	}
	h3 {
		margin-bottom: -13px;
		font: {
			size: 45px;
			weight: 700;
		}
		.odometer-formatting-mark {
			display: none;
		}
	}
}

/*================================================
Products Area CSS
=================================================*/
.single-products-box {
	position: relative;
	margin-bottom: 30px;

	.products-image {
		text-align: center;
		position: relative;
		overflow: hidden;

		a {
			position: relative;
			display: block;

			img {
				transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
			}
		}
		.sale-tag {
			position: absolute;
			left: 20px;
			top: 20px;
			background-color: red;
			color: var(--whiteColor);
			width: 55px;
			height: 55px;
			line-height: 55px;
			text-align: center;
			border-radius: 50%;
			z-index: 2;
			text-transform: uppercase;
			font: {
				weight: 700;
				size: 14px;
			}
		}
		.new-tag {
			position: absolute;
			right: 20px;
			top: 20px;
			background-color: green;
			color: var(--whiteColor);
			text-align: center;
			z-index: 2;
			padding: 1px 7px;
			text-transform: uppercase;
			font: {
				weight: 700;
				size: 14px;
			}
		}
	}
	.products-content {
		margin-top: 25px;
		position: relative;

		h3 {
			margin-bottom: 0;
			font-size: 20px;
		}
		.price {
			margin-top: 13px;
			font: {
				size: 16px;
				weight: 600;
			}
			.old-price {
				text-decoration: line-through;
				color: #999999;
				font-weight: 600;
			}
		}
		.add-to-cart {
			position: absolute;
			right: 0;
			bottom: 0;
			text-transform: uppercase;
			transition: var(--transition);
			display: inline-block;
			color: var(--optionalColor);
			font: {
				size: 14px;
				weight: 600;
			}
			&:hover {
				color: var(--mainColor);
			}
		}
	}
	&:hover {
		.products-image {
			a {
				img {
					transform: scale3d(1.1, 1.1, 1.1) translateZ(0);
				}
			}
		}
	}
}
.miran-grid-sorting {
	margin-bottom: 40px;

	.result-count {
		p {
			.count {
				font-weight: 500;
				color: var(--blackColor);
			}
		}
	}
	.ordering {
		text-align: right;

		label {
			display: inline-block;
			margin-bottom: 0;
			color: var(--optionalColor);
			margin-right: 5px;
			font-weight: 500;
		}
		.nice-select {
			display: inline-block;
			width: 215px;
			float: unset;
			background: #f8f8f8;
			border-color: #eeeeee;
			color: var(--blackColor);
			transition: var(--transition);
			padding: 0 0 0 12px;
			height: 45px;
			line-height: 45px;
			font: {
				size: var(--fontSize);
				weight: 600;
			}
			.list {
				background-color: var(--whiteColor);
				border-radius: 5px;
				box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
				list-style-type: none;
				border: none;
				width: 100%;
				margin: {
					bottom: 0;
					top: 0;
				}
				padding: {
					left: 0;
					top: 10px;
					bottom: 10px;
				}
				.option {
					line-height: 38px;
					min-height: 38px;
					color: var(--blackColor);
					position: relative;
					transition: var(--transition);
					padding: {
						left: 32px;
						right: 25px;
					}
					font: {
						size: var(--fontSize);
						weight: 600;
					}
					&:hover {
						background-color: var(--mainColor) !important;
						color: var(--whiteColor);

						&::before {
							color: var(--whiteColor);
						}
					}
					&.focus,
					&.selected.focus {
						background-color: transparent !important;
						color: var(--blackColor);
					}
					&::before {
						content: "\ea0f";
						position: absolute;
						left: 10px;
						top: -3px;
						opacity: 0;
						visibility: hidden;
						transition: var(--transition);
						color: var(--mainColor);
						font: {
							family: "boxicons";
							size: 20px;
						}
					}
					&.selected {
						&::before {
							opacity: 1;
							visibility: visible;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
					}
				}
			}
			&:after {
				border-color: var(--blackColor);
				height: 8px;
				width: 8px;
				margin-top: -5px;
			}
			&:hover {
				border-color: var(--mainColor);
				background-color: transparent;
			}
		}
	}
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image {
	border-radius: 5px;

	a {
		display: block;
		border-radius: 5px;

		img {
			border-radius: 5px;
		}
	}
}
.products-details-desc {
	padding-left: 30px;

	h3 {
		margin-bottom: 15px;
		font-size: 22px;
	}
	.price {
		margin-bottom: 15px;
		color: var(--mainColor);
		font: {
			size: var(--fontSize);
			weight: 600;
		}
		.old-price {
			text-decoration: line-through;
			color: #828893;
			font-weight: normal;
		}
	}
	.products-review {
		margin-bottom: 18px;

		.rating {
			display: inline-block;
			padding-right: 5px;
			font-size: 16px;

			i {
				color: #ffba0a;
				display: inline-block;
				margin-right: -2px;
			}
		}
		.rating-count {
			display: inline-block;
			color: var(--blackColor);
			border-bottom: 1px solid var(--blackColor);
			line-height: initial;
			position: relative;
			top: -3px;
			font-weight: 600;

			&:hover {
				color: var(--mainColor);
				border-color: var(--mainColor);
			}
		}
	}
	.products-meta {
		margin-top: 20px;

		span {
			display: block;
			color: var(--blackColor);
			margin-bottom: 10px;
			font-weight: 600;

			span {
				display: inline-block;
				margin-bottom: 0;
				font-weight: 500;

				&.sku {
					color: var(--mainColor);
				}
				&.in-stock {
					color: var(--optionalColor);
				}
			}
			a {
				display: inline-block;
				color: var(--optionalColor);
				font-weight: 600;

				&:hover,
				&:focus {
					color: var(--mainColor);
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.products-add-to-cart {
		margin-top: 25px;

		.input-counter {
			max-width: 130px;
			min-width: 130px;
			margin-right: 10px;
			text-align: center;
			display: inline-block;
			position: relative;

			span {
				position: absolute;
				top: 0;
				background-color: transparent;
				cursor: pointer;
				color: #d0d0d0;
				width: 50px;
				height: 100%;
				line-height: 55px;
				transition: var(--transition);
				font-size: 25px;

				&.minus-btn {
					left: 0;
				}
				&.plus-btn {
					right: 0;
				}
				&:hover {
					color: var(--mainColor);
				}
			}
			input {
				height: 50px;
				color: var(--blackColor);
				outline: 0;
				display: block;
				border: none;
				background-color: #f8f8f8;
				text-align: center;
				width: 100%;
				font: {
					size: 17px;
					weight: 600;
				}
				&::placeholder {
					color: var(--blackColor);
				}
			}
		}
		.default-btn {
			box-shadow: unset;
			background-color: var(--mainColor);
			color: var(--whiteColor);
			border-color: var(--mainColor);
			position: relative;
			top: -2px;

			&:hover {
				background-color: var(--mainColor2);
				border-color: var(--mainColor2);
				color: var(--whiteColor);
			}
		}
	}
	.products-share {
		margin-top: 30px;

		.social {
			padding-left: 0;
			list-style-type: none;
			margin-bottom: 0;

			li {
				display: inline-block;

				span {
					display: inline-block;
					margin-right: 3px;
					font-weight: 700;
					position: relative;
					top: -2px;
				}
				a {
					display: block;
					width: 32px;
					height: 32px;
					line-height: 34px;
					border-radius: 50%;
					background-color: var(--mainColor);
					color: var(--whiteColor);
					border: 1px solid var(--mainColor);
					text-align: center;
					font-size: 17px;
					margin-left: 2px;

					&:hover,
					&:focus {
						color: var(--mainColor);
						background-color: transparent;
					}
					&.facebook {
						background-color: #3b5998;
						border-color: #3b5998;
						color: var(--whiteColor);

						&:hover,
						&:focus {
							color: #3b5998;
							background-color: transparent;
						}
					}
					&.twitter {
						background-color: #1da1f2;
						border-color: #1da1f2;
						color: var(--whiteColor);

						&:hover,
						&:focus {
							color: #1da1f2;
							background-color: transparent;
						}
					}
					&.linkedin {
						background-color: #007bb5;
						border-color: #007bb5;
						color: var(--whiteColor);

						&:hover,
						&:focus {
							color: #007bb5;
							background-color: transparent;
						}
					}
					&.instagram {
						background-color: #c13584;
						border-color: #c13584;
						color: var(--whiteColor);

						&:hover,
						&:focus {
							color: #c13584;
							background-color: transparent;
						}
					}
				}
			}
		}
	}
}
.products-details-tabs {
	margin-top: 50px;

	.nav {
		text-align: center;
		padding-left: 0;
		margin-bottom: 40px;
		list-style-type: none;
		display: block;

		.nav-item {
			display: inline-block;
			margin: {
				left: 15px;
				right: 15px;
			}
			.nav-link {
				color: #cccccc;
				border: none;
				border-bottom: 1px solid #eeeeee;
				padding: 0;
				background-color: transparent;
				position: relative;
				padding-bottom: 8px;
				font: {
					size: 20px;
					weight: 700;
				}
				&::before {
					content: "";
					position: absolute;
					left: 0;
					width: 0;
					height: 3px;
					background-color: var(--mainColor);
					transition: var(--transition);
					bottom: -2px;
				}
				&:hover,
				&.active {
					color: var(--blackColor);

					&::before {
						width: 100%;
					}
				}
			}
		}
	}
	.tab-content {
		.tab-pane {
			max-width: 720px;
			margin: {
				left: auto;
				right: auto;
			}
			ul {
				padding-left: 0;
				list-style-type: none;
				margin: {
					top: 20px;
					bottom: 0;
				}
				li {
					margin-bottom: 12px;
					position: relative;
					color: var(--optionalColor);
					padding-left: 15px;
					font-weight: 500;

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 8px;
						width: 7px;
						height: 7px;
						border-radius: 50%;
						background-color: var(--blackColor);
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.products-reviews {
				h3 {
					margin-bottom: 0;
					display: inline-block;
					margin-right: 15px;
					font-size: 20px;
				}
				.rating {
					display: inline-block;

					span {
						font-size: 16px;
						color: #cecfd2;
						margin-right: -2px;

						&.checked {
							color: orange;
						}
					}
				}
				.rating-count {
					margin: {
						top: 10px;
						bottom: 20px;
					}
					span {
						display: block;
						color: var(--optionalColor);
					}
				}
				.row {
					overflow: hidden;
					margin: {
						left: 0;
						right: 0;
					}
				}
				.side {
					float: left;
					width: 9%;
					margin-top: 10px;

					div {
						font-weight: 600;
					}
				}
				.middle {
					margin-top: 14px;
					float: left;
					width: 82%;
				}
				.right {
					text-align: right;
				}
				.bar-container {
					width: 100%;
					background-color: #f1f1f1;
					text-align: center;
					color: var(--whiteColor);
					border-radius: 5px;
				}
				.bar-5 {
					width: 100%;
					height: 18px;
					background-color: #4caf50;
					border-radius: 5px;
				}
				.bar-4 {
					width: 75%;
					height: 18px;
					background-color: #2196f3;
					border-radius: 5px;
					border-radius: 5px;
				}
				.bar-3 {
					width: 50%;
					height: 18px;
					background-color: #00bcd4;
					border-radius: 5px;
				}
				.bar-2 {
					width: 25%;
					height: 18px;
					background-color: #ff9800;
					border-radius: 5px;
				}
				.bar-1 {
					width: 0;
					height: 18px;
					background-color: #f44336;
					border-radius: 5px;
				}
			}
			.products-review-comments {
				margin-top: 40px;

				h3 {
					border-bottom: 1px solid #f3f3f3;
					padding-bottom: 10px;
					font-size: 20px;
					margin: {
						top: 0;
						bottom: 10px;
					}
				}
				.user-review {
					border-bottom: 1px solid #f3f3f3;
					padding: 20px 0 20px 110px;
					position: relative;

					img {
						position: absolute;
						left: 0;
						top: 20px;
						width: 90px;
						height: 90px;
						border-radius: 5px;
					}
					.sub-comment {
						margin-bottom: 8px;
						font-weight: 600;
					}
					.review-rating {
						display: block;
						margin-bottom: 8px;

						.review-stars {
							display: inline-block;

							i {
								color: #cecfd2;
								font-size: 18px;
								display: inline-block;
								margin-right: -3px;

								&.checked {
									color: orange;
								}
							}
						}
						span {
							color: var(--blackColor);
							position: relative;
							top: -2px;
							font-weight: 600;
							margin-left: 5px;
						}
					}
				}
			}
			.review-form-wrapper {
				margin-top: 30px;

				h3 {
					margin-bottom: 10px;
					font-size: 20px;
				}
				.comment-notes {
					span {
						color: red;
					}
				}
				form {
					margin-top: 20px;

					.form-group {
						margin-bottom: 25px;
						text-align: left;
					}
					.rating {
						text-align: left;
						overflow: hidden;
						max-width: 115px;
						margin: {
							top: -5px;
							bottom: 20px;
						}
						label {
							float: right;
							position: relative;
							width: 23px;
							height: 23px;
							cursor: pointer;

							&:not(:first-of-type) {
								padding-right: 5px;
							}
							&:before {
								content: "\2605";
								transition: var(--transition);
								font-size: 27px;
								color: #cccccc;
								line-height: 1;
							}
						}
						input {
							display: none;
						}
						input:checked ~ label:before,
						&:not(:checked) > label:hover:before,
						&:not(:checked) > label:hover ~ label:before {
							color: #f6b500;
						}
					}
					.comment-form-cookies-consent {
						text-align: left;
						margin-bottom: 0;

						[type="checkbox"]:checked,
						[type="checkbox"]:not(:checked) {
							display: none;
						}
						[type="checkbox"]:checked + label,
						[type="checkbox"]:not(:checked) + label {
							position: relative;
							padding-left: 28px;
							cursor: pointer;
							line-height: 20px;
							display: inline-block;
							margin-bottom: 0;
							color: var(--optionalColor);
							font-weight: 500;
						}
						[type="checkbox"]:checked + label:before,
						[type="checkbox"]:not(:checked) + label:before {
							content: "";
							position: absolute;
							left: 0;
							top: 0;
							width: 19px;
							height: 19px;
							transition: all 0.2s ease;
							border: 1px solid #f5f5f5;
							border-radius: 3px;
							background: #f5f5f5;
						}
						[type="checkbox"]:checked + label:after,
						[type="checkbox"]:not(:checked) + label:after {
							content: "";
							width: 8px;
							height: 8px;
							background: var(--mainColor);
							position: absolute;
							top: 5.5px;
							left: 6px;
							-webkit-transition: all 0.2s ease;
							transition: all 0.2s ease;
						}
						[type="checkbox"]:not(:checked) + label:after {
							opacity: 0;
							-webkit-transform: scale(0);
							transform: scale(0);
						}
						[type="checkbox"]:checked + label:after {
							opacity: 1;
							-webkit-transform: scale(1);
							transform: scale(1);
						}
						[type="checkbox"]:hover + label:before {
							border-color: var(--mainColor);
						}
						[type="checkbox"]:checked + label:before {
							border-color: var(--mainColor);
						}
					}
					button {
						margin-top: 22px;
						border: none;
						display: inline-block;
						text-align: center;
						overflow: hidden;
						color: var(--whiteColor);
						background-color: var(--mainColor);
						transition: var(--transition);
						border-radius: 5px;
						padding: 11px 40px;
						font: {
							weight: 700;
							size: var(--fontSize);
						}
						&:hover {
							background-color: var(--blackColor);
							color: var(--whiteColor);
						}
					}
				}
			}
		}
	}
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table {
	table {
		margin-bottom: 0;

		thead {
			tr {
				th {
					border-bottom-width: 0px;
					vertical-align: middle;
					padding: 0 0 15px;
					text-transform: uppercase;
					border: none;
					white-space: nowrap;
					font: {
						size: 16px;
						weight: 700;
					}
				}
			}
		}
		tbody {
			tr {
				td {
					vertical-align: middle;
					color: var(--optionalColor);
					white-space: nowrap;
					font: {
						weight: 600;
						size: var(--fontSize);
					}
					padding: {
						left: 0;
						right: 0;
					}
					border: {
						color: #eaedff;
						left: none;
						right: none;
					}
					&.product-thumbnail {
						a {
							display: block;

							img {
								width: 80px;
							}
						}
					}
					&.product-name {
						a {
							display: inline-block;
						}
					}
					&.product-subtotal {
						.remove {
							color: red;
							float: right;
							position: relative;
							top: -1px;
							font-size: 18px;
						}
					}
					&.product-quantity {
						.input-counter {
							max-width: 130px;
							min-width: 130px;
							text-align: center;
							display: inline-block;
							position: relative;

							span {
								position: absolute;
								top: 0;
								background-color: transparent;
								cursor: pointer;
								color: #d0d0d0;
								width: 40px;
								height: 100%;
								line-height: 50px;
								transition: var(--transition);
								font-size: 22px;

								&.minus-btn {
									left: 0;
								}
								&.plus-btn {
									right: 0;
								}
								&:hover {
									color: var(--mainColor);
								}
							}
							input {
								height: 45px;
								color: var(--blackColor);
								outline: 0;
								display: block;
								border: none;
								background-color: #f8f8f8;
								text-align: center;
								width: 100%;
								font: {
									size: 17px;
									weight: 600;
								}
								&::placeholder {
									color: var(--blackColor);
								}
							}
						}
					}
					&.product-subtotal {
						overflow: hidden;
					}
				}
			}
		}
	}
}
.cart-buttons {
	margin-top: 30px;

	.shopping-coupon-code {
		position: relative;
		max-width: 530px;

		.form-control {
			height: 50px;
		}
		button {
			position: absolute;
			right: 0;
			top: 0;
			height: 50px;
			background: var(--blackColor);
			color: var(--whiteColor);
			border: none;
			padding: 0 25px;
			line-height: 48px;
			outline: 0;
			transition: var(--transition);
			font: {
				size: 16px;
				weight: 600;
			}
			&:hover {
				background-color: var(--mainColor);
			}
		}
	}
}
.cart-totals {
	background: var(--whiteColor);
	padding: 40px;
	max-width: 500px;
	box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
	border-radius: 5px;
	margin: {
		top: 60px;
		left: auto;
		right: auto;
	}
	h3 {
		margin-bottom: 25px;
		font-size: 22px;
	}
	ul {
		padding-left: 0;
		margin: 0 0 25px;
		list-style-type: none;

		li {
			border: 1px solid #eaedff;
			padding: 10px 15px;
			color: var(--blackColor);
			overflow: hidden;
			font: {
				weight: 600;
				size: var(--fontSize);
			}
			&:first-child {
				border: {
					bottom: none;
				}
			}
			&:last-child {
				font-size: 19px;
				border: {
					top: none;
				}
				span {
					color: var(--blackColor);
					font-weight: 700;
				}
			}
			span {
				float: right;
				color: var(--optionalColor);
				font: {
					weight: normal;
				}
			}
		}
	}
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
	box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
	background: var(--whiteColor);
	padding: 15px 20px;
	border-top: 3px solid var(--mainColor);
	position: relative;
	margin-bottom: 40px;

	i {
		color: var(--mainColor);
		margin-right: 2px;
		font-size: 20px;
		position: relative;
		top: 3px;
	}
	span {
		display: inline-block;
		color: var(--mainColor);
		font: {
			weight: 600;
			size: 16px;
		}
		a {
			display: inline-block;
		}
	}
}
.billing-details {
	.title {
		margin-bottom: 30px;
		position: relative;
		padding-bottom: 10px;
		border-bottom: 1px solid #eeeeee;
		font-size: 22px;

		&::before {
			content: "";
			position: absolute;
			background: var(--mainColor);
			bottom: -1px;
			left: 0;
			width: 50px;
			height: 1px;
		}
	}
	.form-group {
		margin-bottom: 25px;

		label {
			display: block;
			margin-bottom: 8px;
			font: {
				weight: 600;
				size: 14px;
			}
			.required {
				color: red;
			}
		}
		.nice-select {
			display: block;
			float: unset;
			background: #f8f8f8;
			border-color: #eeeeee;
			color: var(--blackColor);
			transition: var(--transition);
			padding: 0 0 0 12px;
			height: 45px;
			line-height: 45px;
			width: 100%;
			font: {
				size: var(--fontSize);
				weight: 600;
			}
			.list {
				background-color: var(--whiteColor);
				border-radius: 5px;
				box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
				list-style-type: none;
				border: none;
				width: 100%;
				margin: {
					bottom: 0;
					top: 0;
				}
				padding: {
					left: 0;
					top: 10px;
					bottom: 10px;
				}
				.option {
					line-height: 38px;
					min-height: 38px;
					color: var(--blackColor);
					position: relative;
					transition: var(--transition);
					padding: {
						left: 32px;
						right: 25px;
					}
					font: {
						size: var(--fontSize);
						weight: 600;
					}
					&:hover {
						background-color: var(--mainColor) !important;
						color: var(--whiteColor);

						&::before {
							color: var(--whiteColor);
						}
					}
					&.focus,
					&.selected.focus {
						background-color: transparent !important;
						color: var(--blackColor);
					}
					&::before {
						content: "\ea0f";
						position: absolute;
						left: 10px;
						top: -3px;
						opacity: 0;
						visibility: hidden;
						transition: var(--transition);
						color: var(--mainColor);
						font: {
							family: "boxicons";
							size: 20px;
						}
					}
					&.selected {
						&::before {
							opacity: 1;
							visibility: visible;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
					}
				}
			}
			&:after {
				border-color: var(--blackColor);
				height: 8px;
				width: 8px;
				margin-top: -5px;
			}
			&:hover {
				border-color: var(--mainColor);
				background-color: transparent;
			}
		}
	}
	.form-check {
		margin-bottom: 20px;

		.form-check-label {
			color: var(--blackColor);
			font-weight: 600;
		}
		label {
			position: relative;
			left: -3px;
			top: 0;
			font-weight: 600;
		}
		.form-check-input {
			margin-top: 0.2rem;
		}
	}
	.col-lg-12 {
		&:last-child {
			.form-group {
				margin-bottom: 0;
			}
		}
	}
}
.order-details {
	.title {
		margin-bottom: 30px;
		position: relative;
		padding-bottom: 10px;
		border-bottom: 1px solid #eeeeee;
		font-size: 22px;

		&::before {
			content: "";
			position: absolute;
			background: var(--mainColor);
			bottom: -1px;
			left: 0;
			width: 50px;
			height: 1px;
		}
	}
	.order-table {
		table {
			margin-bottom: 0;

			thead {
				tr {
					th {
						border-bottom-width: 0;
						vertical-align: middle;
						border-color: #eaedff;
						padding: 12px 20px 10px;
						white-space: nowrap;
						text-transform: uppercase;
						font: {
							weight: 700;
							size: var(--fontSize);
						}
					}
				}
			}
			tbody {
				tr {
					td {
						vertical-align: middle;
						color: var(--optionalColor);
						white-space: nowrap;
						border-color: #eaedff;
						font: {
							size: var(--fontSize);
							weight: 600;
						}
						padding: {
							left: 20px;
							right: 20px;
							top: 15px;
							bottom: 13px;
						}
						&.product-name {
							a {
								display: inline-block;
							}
						}
						&.order-subtotal,
						&.order-shipping,
						&.total-price {
							span {
								color: var(--blackColor);
								font-weight: 700;
							}
						}
						&.shipping-price,
						&.order-subtotal-price,
						&.product-subtotal {
							color: var(--blackColor);
							font-weight: 700;
						}
					}
				}
			}
		}
	}
	.payment-box {
		background-color: var(--whiteColor);
		box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
		margin-top: 30px;
		padding: 30px;

		.payment-method {
			p {
				[type="radio"] {
					&:checked,
					&:not(:checked) {
						display: none;
					}
				}
				[type="radio"] {
					&:checked,
					&:not(:checked) {
						+ label {
							padding-left: 27px;
							cursor: pointer;
							display: block;
							color: var(--blackColor);
							position: relative;
							margin-bottom: 8px;
							font-weight: 700;

							&::before {
								content: "";
								position: absolute;
								left: 0;
								top: 4px;
								width: 18px;
								height: 18px;
								border: 1px solid #dddddd;
								border-radius: 50%;
								background: var(--whiteColor);
							}
							&::after {
								content: "";
								width: 12px;
								height: 12px;
								background: var(--mainColor);
								position: absolute;
								top: 7px;
								left: 3px;
								border-radius: 50%;
								transition: var(--transition);
							}
						}
					}
				}
				[type="radio"] {
					&:not(:checked) {
						+ label {
							&::after {
								opacity: 0;
								visibility: hidden;
								transform: scale(0);
							}
						}
					}
				}
				[type="radio"] {
					&:checked {
						+ label {
							&::after {
								opacity: 1;
								visibility: visible;
								transform: scale(1);
							}
						}
					}
				}
			}
		}
		.default-btn {
			margin-top: 15px;

			i {
				margin-right: 8px;
			}
		}
	}
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
	position: relative;
	margin-bottom: 30px;
	z-index: 1;
	border-radius: 5px;
	overflow: hidden;
	cursor: zoom-in;

	a {
		display: block;
		border-radius: 5px;

		img {
			transition: var(--transition);
		}
	}
	&:hover {
		a {
			img {
				transform: scale(1.1);
			}
		}
	}
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-item {
	margin-bottom: 30px;

	h3 {
		font-size: 20px;
		margin-bottom: 15px;
		line-height: 1.4;
	}
}

/*================================================
Author Area CSS
=================================================*/
.author-sidebar {
	.user-profile {
		img {
			width: 110px;
			height: 110px;
			border-radius: 50%;
			box-shadow: 0 9px 26px rgba(58, 87, 135, 0.2);
			border: 5px solid var(--whiteColor);
		}
		.title {
			margin-left: 20px;

			h4 {
				font-size: 22px;
				margin-bottom: 7px;
			}
			.sub-title {
				display: block;
				color: var(--mainColor);
				font: {
					weight: 500;
				}
			}
			.rating {
				margin-top: 7px;

				.bx {
					color: #cecfd2;
					font-size: 16px;
					margin-right: 2px;

					&.checked {
						color: #efc02f;
					}
				}
				.rating-count {
					position: relative;
					top: 1px;
					font: {
						size: 16px;
						weight: 500;
					}
				}
			}
		}
	}
	.user-contact-info {
		margin-top: 35px;
		background-color: #f9f9f9;
		border-radius: 3px;
		padding: 25px;

		h3 {
			font-size: 20px;
			margin-bottom: 25px;
			border-bottom: 1px solid #e8e8e8;
			padding-bottom: 10px;
		}
		.user-contact {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				margin-bottom: 9px;
				font-weight: 500;

				a {
					display: inline-block;
					position: relative;
					color: var(--optionalColor);
					padding-left: 25px;

					i {
						position: absolute;
						left: 0;
						top: 2px;
						color: var(--mainColor);
						font-size: 18px;
					}
					&:hover {
						color: var(--mainColor);
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.social-profiles {
			padding-left: 0;
			list-style-type: none;
			margin: {
				top: 20px;
				bottom: 0;
			}
			li {
				margin-bottom: 9px;
				font-weight: 500;

				a {
					display: inline-block;
					position: relative;
					color: var(--optionalColor);
					padding-left: 25px;

					i {
						position: absolute;
						left: 0;
						top: 2px;
						font-size: 18px;
					}
					&.facebook {
						color: #3b5998;
					}
					&.twitter {
						color: #1da1f2;
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		form {
			margin-top: 25px;

			.form-control {
				background-color: var(--whiteColor) !important;
				box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12) !important;
			}
		}
	}
}
.author-all-listings {
	h2 {
		font-size: 22px;
		margin-bottom: 30px;
	}
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
	margin-top: 35px;
	padding-bottom: 35px !important;

	.page-numbers {
		width: 40px;
		height: 40px;
		background-color: var(--whiteColor);
		box-shadow: 3px 3px #d5d5d5;
		color: var(--optionalColor);
		text-align: center;
		display: inline-block;
		border-radius: 50%;
		line-height: 38px;
		position: relative;
		border: 1px solid #d5d5d5;
		margin: {
			left: 3px;
			right: 3px;
		}
		a{
			display: block;
		}
		font: {
			weight: 700;
			size: 18px;
		}
		&:hover,
		&.current {
			color: var(--whiteColor);
			background-color: var(--mainColor);
		}
		i {
			position: relative;
			top: 2px;
		}
		&.prev {
			box-shadow: -2px 3px var(--mainColor);
			border-color: var(--mainColor);

			&:hover {
				color: var(--blackColor);
				background-color: var(--whiteColor);
			}
		}
		&.next {
			box-shadow: 2px 3px var(--mainColor);
			border-color: var(--mainColor);

			&:hover {
				color: var(--blackColor);
				background-color: var(--whiteColor);
			}
		}
	}
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
	padding-left: 10px;

	&.widget-left-sidebar {
		padding: {
			right: 10px;
			left: 0;
		}
	}
	.widget {
		background-color: var(--whiteColor);
		padding: 20px;
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}
		.widget-title {
			margin-bottom: 25px;
			position: relative;
			padding-bottom: 7px;
			border-bottom: 1px solid #eeeeee;
			font: {
				size: 20px;
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 50px;
				height: 1px;
				bottom: -1px;
				background-color: var(--mainColor);
			}
		}
	}
	.widget_search {
		form {
			position: relative;

			.screen-reader-text {
				display: none;
			}
			label {
				display: block;
				margin-bottom: 0;
			}
			.search-field {
				height: 50px;
				color: var(--blackColor);
				background-color: #f2f4f5;
				display: block;
				width: 100%;
				border-radius: 5px;
				padding: 2px 0 0 15px;
				border: none;
				transition: var(--transition);
				font-weight: 400;

				&::placeholder {
					color: var(--optionalColor);
					transition: var(--transition);
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
				&[type="search"]::-ms-clear,
				&[type="search"]::-ms-reveal,
				&[type="search"]::-webkit-search-decoration {
					display: none;
				}
			}
			button {
				border: none;
				background-color: var(--whiteColor);
				color: var(--mainColor);
				height: 40px;
				width: 40px;
				position: absolute;
				right: 5px;
				padding: 0;
				transition: var(--transition);
				top: 5px;
				font-size: 20px;

				i {
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}
				&:hover,
				&:focus {
					border-radius: 5px;
					background-color: var(--mainColor);
					color: var(--whiteColor);
				}
			}
		}
	}
	.widget_miran_posts_thumb {
		position: relative;
		overflow: hidden;

		.item {
			overflow: hidden;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
			.thumb {
				float: left;
				height: 80px;
				overflow: hidden;
				display: block;
				position: relative;
				width: 80px;
				margin-right: 15px;
				z-index: 1;

				.fullimage {
					width: 80px;
					height: 80px;
					display: inline-block;
					border-radius: 5px;
					background: {
						size: cover !important;
						repeat: no-repeat;
						position: center center !important;
					}
					&.bg1 {
						background-image: url(../images/blog/blog4.jpg);
					}
					&.bg2 {
						background-image: url(../images/blog/blog5.jpg);
					}
					&.bg3 {
						background-image: url(../images/blog/blog6.jpg);
					}
				}
				&::before,
				&::after {
					transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
					content: "";
					background-color: var(--whiteColor);
					position: absolute;
					z-index: 1;
					top: 50%;
					left: 50%;
					opacity: 0;
					transform: translate(-50%, -50%);
				}
				&::before {
					width: 40px;
					height: 1px;
					left: 100%;
				}
				&::after {
					height: 40px;
					width: 1px;
					top: 0;
				}
			}
			.info {
				overflow: hidden;
				margin-top: 5px;

				span {
					display: block;
					color: var(--optionalColor);
					text-transform: uppercase;
					margin: {
						top: -2px;
						bottom: 5px;
					}
					font: {
						size: 13px;
					}
				}
				.title {
					margin-bottom: 0;
					line-height: 1.4;
					font: {
						size: 16px;
					}
					a {
						display: inline-block;
					}
				}
			}
			&:hover {
				.thumb {
					&::before,
					&::after {
						opacity: 1;
						top: 50%;
						left: 50%;
					}
				}
			}
		}
	}
	.widget_categories {
		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				position: relative;
				margin-bottom: 12px;
				overflow: hidden;
				color: var(--blackColor);
				padding-left: 16px;
				font: {
					weight: 600;
					size: var(--fontSize);
				}
				&:last-child {
					margin-bottom: 0;
				}
				&::before {
					background: var(--mainColor);
					height: 8px;
					width: 8px;
					content: "";
					left: 0;
					top: 7px;
					position: absolute;
				}
				a {
					color: var(--blackColor);
					display: inline-block;

					&:hover {
						color: var(--mainColor);
					}
				}
				.post-count {
					font-size: 14px;
					display: inline-block;
					position: relative;
					top: 1px;
				}
			}
		}
	}
	.widget_tag_cloud {
		.widget-title {
			margin-bottom: 17px;
		}
	}
	.tagcloud {
		a {
			display: inline-block;
			background: #f5f5f5;
			color: var(--blackColor);
			padding: 7px 15px;
			border: none;
			border-radius: 3px;
			font: {
				weight: 600;
				size: 14px !important;
			}
			margin: {
				top: 8px;
				right: 4px;
			}
			&:hover,
			&:focus {
				color: var(--whiteColor);
				background-color: var(--mainColor);
			}
		}
	}
	.widget_instagram {
		ul {
			padding-left: 0;
			list-style-type: none;
			display: flex;
			flex-wrap: wrap;
			margin: {
				bottom: 0;
				left: -5px;
				right: -5px;
				top: -10px;
			}
			li {
				flex: 0 0 50%;
				max-width: 50%;
				text-align: center;
				padding: {
					left: 5px;
					right: 5px;
					top: 10px;
				}
				.box {
					position: relative;
					z-index: 1;
					overflow: hidden;

					.link-btn {
						display: block;
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						z-index: 3;
					}
					i {
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						font-size: 30px;
						transition: var(--transition);
						opacity: 0;
						visibility: hidden;
						color: var(--whiteColor);
						z-index: 2;
					}
					img {
						transition: var(--transition);
					}
					&::before {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						z-index: 1;
						background-color: var(--mainColor);
						transition: var(--transition);
						opacity: 0;
						visibility: hidden;
					}
					&:hover {
						&::before {
							opacity: 0.5;
							visibility: visible;
						}
						img {
							transform: scale(1.3);
						}
						i {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
	}
}

/*================================================
404 Error Area CSS
=================================================*/
.error-content {
	text-align: center;

	h3 {
		font-size: 35px;
		margin: {
			bottom: 20px;
			top: 45px;
		}
	}
	p {
		max-width: 550px;
		margin: {
			left: auto;
			right: auto;
			bottom: 0;
		}
	}
	.default-btn {
		margin-top: 30px;
	}
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
	height: 100vh;
	position: relative;
	z-index: 1;
	background: #f6f7fb;
	background: {
		image: url(../images/coming-soon-bg.jpg);
		position: top center;
		size: cover;
		repeat: no-repeat;
	}
}
.coming-soon-content {
	max-width: 700px;
	background: var(--whiteColor);
	border-radius: 5px;
	overflow: hidden;
	text-align: center;
	padding: 40px 60px;
	box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
	margin: {
		left: auto;
		right: auto;
	}
	.logo {
		display: inline-block;
	}
	h2 {
		font-size: 40px;
		margin: {
			top: 30px;
			bottom: 0;
		}
	}
	#timer {
		margin-top: 40px;

		div {
			background-color: var(--blackColor);
			color: var(--whiteColor);
			width: 100px;
			height: 105px;
			border-radius: 5px;
			font: {
				size: 35px;
				weight: 700;
			}
			margin: {
				left: 10px;
				right: 10px;
			}
			span {
				display: block;
				margin-top: -2px;
				font: {
					size: 15px;
					weight: 600;
				}
			}
		}
	}
	form {
		position: relative;
		max-width: 500px;
		margin: {
			left: auto;
			right: auto;
			top: 40px;
		}
		.form-group {
			margin-bottom: 25px;
			width: 100%;
			position: relative;

			.label-title {
				margin-bottom: 0;
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				pointer-events: none;
				width: 100%;
				height: 100%;
				color: var(--mainColor);

				i {
					position: absolute;
					left: 0;
					transition: var(--transition);
					top: 9px;
					font: {
						size: 22px;
					}
				}
				&::before {
					content: "";
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 0;
					height: 2px;
					transition: var(--transition);
					background: var(--mainColor);
				}
			}
			.input-newsletter {
				border-radius: 0;
				border: none;
				border-bottom: 2px solid #eeeeee;
				padding: 0 0 0 32px;
				color: var(--blackColor);
				height: 45px;
				display: block;
				width: 100%;
				transition: var(--transition);
				font: {
					size: 16px;
					weight: 400;
				}
				&::placeholder {
					color: #a1a1a1;
					transition: var(--transition);
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
		}
		.default-btn {
			border-radius: 0;

			&::before {
				border-radius: 0;
			}
		}
		.validation-danger {
			margin-top: 15px;
			color: red;
		}
		.validation-success {
			margin-top: 15px;
		}
		p {
			margin: {
				bottom: 0;
				top: 25px;
			}
		}
	}
}

/*================================================
Contact Area CSS
=================================================*/
.contact-info-box {
	margin-bottom: 30px;
	position: relative;
	padding-left: 100px;
	z-index: 1;

	.icon {
		width: 75px;
		height: 85px;
		background-color: #f7f7f7;
		border-radius: 3px;
		position: absolute;
		text-align: center;
		left: 0;
		font-size: 40px;
		color: var(--mainColor);
		transition: var(--transition);
		top: 50%;
		transform: translateY(-50%);

		i {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	h3 {
		margin-bottom: 10px;
		font-size: 20px;
	}
	p {
		margin-bottom: 2px;
		font-weight: 500;

		&:last-child {
			margin-bottom: 0;
		}
	}
	.back-icon {
		position: absolute;
		right: 0;
		bottom: -15px;
		z-index: -1;
		color: var(--blackColor);
		line-height: 1;
		opacity: 0.04;
		font-size: 100px;
		transform: rotate(-5deg);
	}
	&:hover {
		.icon {
			background-color: var(--mainColor);
			color: var(--whiteColor);
		}
	}
}
.contact-image {
	text-align: center;
}
.contact-form {
	padding: 40px;
	margin-left: 15px;
	box-shadow: 0 0 20px rgba(158, 158, 158, 0.16);
	background-color: var(--whiteColor);

	form {
		.form-group {
			textarea.form-control {
				height: auto;
			}
		}
		.help-block {
			ul {
				margin: {
					bottom: 0;
					top: 12px;
				}
				li {
					color: red;
				}
			}
		}
		.default-btn {
			margin-top: 5px;
		}
		#msgSubmit {
			margin: 0 !important;

			&.text-danger,
			&.text-success {
				margin-top: 15px !important;
			}
		}
	}
}
#map {
	iframe {
		width: 100%;
		height: 500px;
		margin-bottom: -7px;
		border: none;
	}
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
	padding-top: 100px;
	position: relative;
	z-index: 1;
}
.single-footer-widget {
	margin-bottom: 30px;

	h3 {
		font-size: 20px;
		margin-bottom: 25px;
	}
	.link-list {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			margin-bottom: 12px;
			color: var(--optionalColor);
			font-weight: 600;

			a {
				position: relative;
				display: inline-block;
				color: var(--optionalColor);

				i {
					position: absolute;
					left: 0;
					top: 1.7px;
					color: var(--mainColor);
					font-size: 13px;
					opacity: 0;
					visibility: hidden;
					transition: var(--transition);
				}
				&:hover {
					color: var(--mainColor);
					padding-left: 17px;

					i {
						opacity: 1;
						visibility: visible;
					}
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.languages-switch {
		margin-bottom: 30px;

		.nice-select {
			background-color: #f1f1f1;
			border-radius: 3px;
			border: none;
			float: unset;
			height: 45px;
			line-height: 45px;
			color: var(--mainColor);
			font: {
				size: var(--fontSize);
				weight: 600;
			}
			padding: {
				top: 1px;
				bottom: 0;
				left: 12px;
				right: 12px;
			}
			&::after {
				border-color: #838383;
				height: 11px;
				margin-top: -6px;
				right: 20px;
				width: 11px;
			}
			.list {
				background-color: var(--whiteColor);
				border-radius: 5px;
				box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
				list-style-type: none;
				border: none;
				width: 100%;
				margin: {
					bottom: 0;
					top: 0;
				}
				padding: {
					left: 0;
					top: 10px;
					bottom: 10px;
				}
				.option {
					line-height: 38px;
					min-height: 38px;
					color: var(--blackColor);
					position: relative;
					transition: var(--transition);
					padding: {
						left: 38px;
						right: 15px;
					}
					font: {
						size: var(--fontSize);
						weight: 600;
					}
					&:hover {
						background-color: var(--mainColor) !important;
						color: var(--whiteColor);

						&::before {
							color: var(--whiteColor);
						}
					}
					&.focus,
					&.selected.focus {
						background-color: transparent !important;
						color: var(--blackColor);
					}
					&::before {
						content: "\ea0f";
						position: absolute;
						left: 12px;
						top: -1px;
						opacity: 0;
						visibility: hidden;
						transition: var(--transition);
						color: var(--mainColor);
						font: {
							family: "boxicons";
							size: 20px;
						}
					}
					&.selected {
						&::before {
							opacity: 1;
							visibility: visible;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
					}
				}
			}
		}
	}
	.country-switch {
		.nice-select {
			background-color: #f1f1f1;
			border-radius: 3px;
			border: none;
			float: unset;
			height: 45px;
			line-height: 45px;
			color: var(--mainColor);
			font: {
				size: var(--fontSize);
				weight: 600;
			}
			padding: {
				top: 1px;
				bottom: 0;
				left: 12px;
				right: 12px;
			}
			&::after {
				border-color: #838383;
				height: 11px;
				margin-top: -6px;
				right: 20px;
				width: 11px;
			}
			.list {
				background-color: var(--whiteColor);
				border-radius: 5px;
				box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
				list-style-type: none;
				border: none;
				width: 100%;
				margin: {
					bottom: 0;
					top: 0;
				}
				padding: {
					left: 0;
					top: 10px;
					bottom: 10px;
				}
				.option {
					line-height: 38px;
					min-height: 38px;
					color: var(--blackColor);
					position: relative;
					transition: var(--transition);
					padding: {
						left: 38px;
						right: 15px;
					}
					font: {
						size: var(--fontSize);
						weight: 600;
					}
					&:hover {
						background-color: var(--mainColor) !important;
						color: var(--whiteColor);

						&::before {
							color: var(--whiteColor);
						}
					}
					&.focus,
					&.selected.focus {
						background-color: transparent !important;
						color: var(--blackColor);
					}
					&::before {
						content: "\ea0f";
						position: absolute;
						left: 12px;
						top: -1px;
						opacity: 0;
						visibility: hidden;
						transition: var(--transition);
						color: var(--mainColor);
						font: {
							family: "boxicons";
							size: 20px;
						}
					}
					&.selected {
						&::before {
							opacity: 1;
							visibility: visible;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
					}
				}
			}
		}
	}
}
.footer-image {
	margin-top: -100px;
	position: relative;
	z-index: -1;
}
.copyright-area {
	text-align: center;
	border-top: 1px solid #eeeeee;
	margin-top: 35px;
	padding-top: 30px;

	p {
		line-height: initial;
		color: #989898;

		span {
			color: var(--blackColor);
			font-weight: 600;
		}
		a {
			color: var(--mainColor);
			font-weight: 600;

			&:hover {
				color: var(--blackColor);
			}
		}
	}
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
	position: fixed;
	cursor: pointer;
	bottom: 20px;
	right: 20px;
	background-color: var(--mainColor);
	color: var(--whiteColor);
	z-index: 4;
	width: 43px;
	text-align: center;
	height: 45px;
	opacity: 0;
	visibility: hidden;
	font-size: 27px;
	transition: var(--transition);
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

	i {
		position: absolute;
		right: 0;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
		margin: {
			left: auto;
			right: auto;
		}
	}
	&.active {
		opacity: 1;
		visibility: visible;
		bottom: 20px;
	}
	&:hover {
		background-color: var(--blackColor);
		color: var(--whiteColor);
	}
}

/*================================================
All Modal CSS
=================================================*/
.loginRegisterModal {
	.modal-dialog {
		max-width: 500px;
		margin: {
			top: 0;
			bottom: 0;
		}
		.modal-content {
			background-color: var(--whiteColor);
			border: none;
			border-radius: 0;
			box-shadow: 5px 5px var(--mainColor);
			border: 1px solid var(--mainColor);
			padding: 40px;
			position: relative;

			button.close {
				position: absolute;
				right: -22px;
				top: -22px;
				opacity: 1 !important;
				visibility: visible !important;
				background-color: red;
				width: 32px;
				height: 32px;
				text-align: center;
				text-shadow: unset;
				border-radius: 50%;
				line-height: 32px;
				color: var(--whiteColor);
				padding: 3px 0 0;
			}
			.nav-tabs {
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;
				border-bottom: 1px solid #f0f0f0;

				.nav-item {
					margin: {
						bottom: 0;
						right: 30px;
					}
					.nav-link {
						padding: 0 0 8px;
						border: none;
						color: #666666;
						transition: var(--transition);
						position: relative;
						font: {
							size: 18px;
							weight: 600;
						}
						&::before {
							content: "";
							position: absolute;
							left: 0;
							bottom: -2px;
							width: 100%;
							height: 3px;
							transition: var(--transition);
							transform: scaleX(0);
							background-color: var(--mainColor);
						}
						&:hover,
						&.active {
							color: var(--blackColor);

							&::before {
								transform: scaleX(1);
							}
						}
					}
				}
			}
			.tab-content {
				margin-top: 30px;

				.miran-login {
					.login-with-account {
						margin-bottom: 30px;

						span {
							display: block;
							color: #666666;
							font-weight: 500;
						}
						ul {
							padding-left: 0;
							list-style-type: none;
							display: flex;
							flex-wrap: wrap;
							margin: {
								bottom: 0;
								top: 15px;
								left: -7px;
								right: -7px;
							}
							li {
								flex: 0 0 50%;
								max-width: 50%;
								padding: {
									left: 7px;
									right: 7px;
								}
								a {
									display: block;
									text-align: center;
									background-color: var(--blackColor);
									color: var(--whiteColor);
									border-radius: 5px;
									font-weight: 600;
									padding: {
										top: 8px;
										bottom: 11px;
										left: 25px;
										right: 25px;
									}
									i {
										font-size: 22px;
										line-height: 1;
										position: relative;
										top: 4px;
										margin-right: 2px;
									}
									&.facebook {
										background-color: #4267b2;
										color: var(--whiteColor);

										&:hover {
											background-color: #6d8bc8;
											color: var(--whiteColor);
										}
									}
									&.twitter {
										background-color: #1da1f2;
										color: var(--whiteColor);

										&:hover {
											background-color: #0982cc;
											color: var(--whiteColor);
										}
									}
								}
							}
						}
					}
					.sub-title {
						display: block;
						color: #666666;
						font-weight: 500;
						margin-bottom: 20px;
						z-index: 1;
						position: relative;

						&::before {
							content: "";
							position: absolute;
							right: 0;
							top: 11px;
							height: 1px;
							width: 100%;
							z-index: -1;
							background: #f0f0f0;
						}
						span {
							background-color: var(--whiteColor);
							padding-right: 15px;
						}
					}
					form {
						.form-group {
							margin-bottom: 15px;

							.form-control {
								border: 1px solid #d5d5d5 !important;
								border-radius: 5px;
								background-color: var(--whiteColor) !important;
								padding-top: 9px;
							}
						}
						button {
							display: block;
							background-color: var(--mainColor);
							color: var(--whiteColor);
							border: none;
							font-weight: 600;
							display: block;
							width: 100%;
							border-radius: 5px;
							padding: 14px 30px 13px;
							transition: var(--transition);

							&:hover {
								background-color: var(--blackColor);
								color: var(--whiteColor);
							}
						}
					}
					.dont-account {
						display: block;
						color: #666666;
						margin-top: 20px;

						a {
							font-weight: 500;
							color: var(--mainColor);

							&:hover {
								color: var(--blackColor);
							}
						}
					}
				}
				.miran-register {
					.register-with-account {
						margin-bottom: 30px;

						span {
							display: block;
							color: #666666;
							font-weight: 500;
						}
						ul {
							padding-left: 0;
							list-style-type: none;
							display: flex;
							flex-wrap: wrap;
							margin: {
								bottom: 0;
								top: 15px;
								left: -7px;
								right: -7px;
							}
							li {
								flex: 0 0 50%;
								max-width: 50%;
								padding: {
									left: 7px;
									right: 7px;
								}
								a {
									display: block;
									text-align: center;
									background-color: var(--blackColor);
									color: var(--whiteColor);
									border-radius: 5px;
									font-weight: 600;
									padding: {
										top: 8px;
										bottom: 11px;
										left: 25px;
										right: 25px;
									}
									i {
										font-size: 22px;
										line-height: 1;
										position: relative;
										top: 4px;
										margin-right: 2px;
									}
									&.facebook {
										background-color: #4267b2;
										color: var(--whiteColor);

										&:hover {
											background-color: #6d8bc8;
											color: var(--whiteColor);
										}
									}
									&.twitter {
										background-color: #1da1f2;
										color: var(--whiteColor);

										&:hover {
											background-color: #0982cc;
											color: var(--whiteColor);
										}
									}
								}
							}
						}
					}
					.sub-title {
						display: block;
						color: #666666;
						font-weight: 500;
						margin-bottom: 20px;
						z-index: 1;
						position: relative;

						&::before {
							content: "";
							position: absolute;
							right: 0;
							top: 11px;
							height: 1px;
							width: 100%;
							z-index: -1;
							background: #f0f0f0;
						}
						span {
							background-color: var(--whiteColor);
							padding-right: 15px;
						}
					}
					form {
						.form-group {
							margin-bottom: 15px;

							.form-control {
								border: 1px solid #d5d5d5 !important;
								border-radius: 5px;
								background-color: var(--whiteColor) !important;
								padding-top: 9px;
							}
						}
						button {
							display: block;
							background-color: var(--mainColor);
							color: var(--whiteColor);
							border: none;
							font-weight: 600;
							display: block;
							width: 100%;
							border-radius: 5px;
							padding: 14px 30px 13px;
							transition: var(--transition);

							&:hover {
								background-color: var(--blackColor);
								color: var(--whiteColor);
							}
						}
					}
					.already-account {
						display: block;
						color: #666666;
						margin-top: 20px;

						a {
							font-weight: 500;
							color: var(--mainColor);

							&:hover {
								color: var(--blackColor);
							}
						}
					}
				}
			}
		}
	}
}

/*================================================
Dashboard CSS
=================================================*/
.main-content {
	transition: var(--transition);
	overflow: hidden;
	background-color: #fafafa;
	min-height: 100vh;
	padding: {
		right: 30px;
		left: 280px;
		top: 120px;
	}
	.navbar-area {
		position: fixed;
		background-color: var(--whiteColor);
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
		z-index: 999;
		height: auto;
		top: 0;
		left: 250px;
		width: calc(100% - 250px);
		animation: unset !important;

		.miran-nav {
			padding: {
				left: 30px;
				right: 30px;
			}
			.navbar {
				.navbar-nav {
					margin: {
						left: 0;
						right: 0;
					}
					.nav-item {
						.dropdown-menu {
							li {
								.dropdown-menu {
									left: 250px;

									li {
										.dropdown-menu {
											left: -250px;

											li {
												.dropdown-menu {
													left: 250px;

													li {
														.dropdown-menu {
															left: -250px;

															li {
																.dropdown-menu {
																	left: 250px;

																	li {
																		.dropdown-menu {
																			left: -250px;
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.others-option {
					margin-left: auto;

					.profile-nav-item {
						.dropdown-toggle {
							padding: 0;
							color: var(--optionalColor);
							font-weight: 500;

							&::after {
								display: none;
							}
							&::before {
								content: "\f106";
								position: absolute;
								right: 0;
								transition: var(--transition);
								top: 10px;
								font: {
									size: 15px;
									family: Flaticon;
								}
							}
							span {
								top: 1px;
								color: var(--optionalColor);

								&::before {
									display: none;
								}
							}
							&:hover {
								&::before {
									color: var(--mainColor);
								}
								span {
									color: var(--mainColor);
								}
							}
						}
						.menu-profile {
							padding-right: 20px;

							img {
								width: 40px;
								height: 40px;
								display: inline-block;
								margin-right: 8px;
							}
						}
						&.dropdown {
							.dropdown-menu {
								padding: 15px 15px 10px;
								min-width: 230px;
								max-width: 280px;
								position: absolute;
								right: 0;
								left: auto;
								border-radius: 2px;
								margin-top: 20px;
								border: none;
								display: block;
								transition: var(--transition);
								opacity: 0;
								visibility: hidden;
								box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
								background-color: var(--whiteColor);

								.dropdown-header {
									padding: 0;
									border-bottom: 1px solid #f2f4f9;
									margin: {
										left: -15px;
										right: -15px;
									}
									.figure {
										position: relative;

										img {
											width: 80px;
											height: 80px;
										}
									}
									.name {
										display: block;
										color: var(--blackColor);
										margin-bottom: 2px;
										font: {
											size: 17px;
											weight: 600;
										}
										&::before {
											display: none;
										}
									}
									.email {
										color: var(--optionalColor);
										font-size: 14px;
									}
								}
								.dropdown-body {
									ul {
										padding-left: 0;
										list-style-type: none;
										margin-bottom: 0;

										.nav-item {
											margin-left: 0;

											.nav-link {
												color: var(--blackColor);
												padding: 5px 15px 5px 38px;
												position: relative;
												font: {
													size: 14px;
													weight: 500;
												}
												span {
													color: var(--blackColor);
													display: block;
													font-weight: 500;

													&::before {
														display: none;
													}
												}
												i {
													color: var(--mainColor);
													transition: var(
														--transition
													);
													position: absolute;
													left: 15px;
													top: 7px;
													font-size: 16px;
												}
												&:hover {
													span {
														color: var(--mainColor);
													}
													i {
														transform: rotateY(
															-180deg
														);
													}
												}
											}
										}
									}
								}
								.dropdown-footer {
									margin: 10px -15px 0;
									padding: 10px 15px 0;
									border-top: 1px solid #eeeeee;

									ul {
										padding-left: 0;
										list-style-type: none;
										margin-bottom: 0;

										.nav-item {
											.nav-link {
												color: red;
												padding: 5px 15px 5px 38px;
												position: relative;
												font: {
													size: 14px;
													weight: 500;
												}
												span {
													color: red;
													display: block;
													font-weight: 500;

													&::before {
														display: none;
													}
												}
												i {
													color: red;
													transition: var(
														--transition
													);
													position: absolute;
													left: 15px;
													top: 7px;
													font-size: 16px;
												}
												&:hover {
													i {
														transform: rotateY(
															-180deg
														);
													}
												}
											}
										}
									}
								}
								&.show {
									margin-top: 10px;
									opacity: 1;
									visibility: visible;
								}
							}
						}
					}
				}
			}
		}
	}
	.listing-area {
		background-color: var(--whiteColor);
		box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
		padding: 25px 20px 0;
		border-radius: 5px;
		margin-bottom: 30px;

		.nav {
			list-style-type: none;
			border: none;
			border-bottom: 1px solid #eeeeee;
			margin: {
				bottom: 30px;
				left: -20px;
				right: -20px;
			}
			padding: {
				left: 20px;
				right: 20px;
			}
			.nav-item {
				margin: {
					bottom: 21px;
					right: 25px;
				}
				.nav-link {
					position: relative;
					color: var(--blackColor);
					border: none;
					background-color: transparent;
					padding: 0;
					margin: 0;
					font: {
						size: var(--fontSize);
						weight: 500;
					}
					&:hover,
					&.active {
						color: var(--mainColor);
					}
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.pagination-area {
			margin-top: 0;
		}
	}
	.single-listings-box {
		.listings-footer {
			border-top: 1px solid #eeeeee;
			padding: 20px;

			.default-btn {
				padding: {
					top: 11px;
					bottom: 11px;
				}
			}
		}
	}
	.responsive-burger-menu {
		cursor: pointer;
		transition: var(--transition);
		position: relative;
		z-index: 9999;
		width: 70%;

		span {
			height: 1px;
			width: 25px;
			background: var(--blackColor);
			display: block;
			margin: 6px 0;
			transition: all 0.5s ease-in-out;
		}
		&.active {
			span {
				&.top-bar {
					transform: rotate(45deg);
					transform-origin: 10% 10%;
				}
				&.middle-bar {
					opacity: 0;
				}
				&.bottom-bar {
					transform: rotate(-45deg);
					transform-origin: 10% 90%;
					margin-top: 5px;
				}
			}
		}
	}
	.others-option-for-responsive {
		.dot-menu {
			right: 55px;
			top: -28px;
		}
		.others-option {
			.profile-nav-item {
				.dropdown-toggle {
					padding: 0;
					color: var(--optionalColor);
					font-weight: 500;

					&::after {
						display: none;
					}
					&::before {
						content: "\f106";
						position: absolute;
						right: 20px;
						transition: var(--transition);
						top: 10px;
						font: {
							size: 15px;
							family: Flaticon;
						}
					}
					span {
						top: 1px;
						color: var(--optionalColor);

						&::before {
							display: none;
						}
					}
					&:hover {
						&::before {
							color: var(--mainColor);
						}
						span {
							color: var(--mainColor);
						}
					}
				}
				.menu-profile {
					padding-right: 26px;

					img {
						width: 40px;
						height: 40px;
						display: inline-block;
						margin-right: 8px;
					}
				}
				&.dropdown {
					.dropdown-menu {
						padding: 15px 15px 10px;
						position: absolute;
						left: -15px !important;
						border-radius: 2px;
						border: none;
						display: block;
						width: 230px;
						transition: var(--transition);
						opacity: 0;
						visibility: hidden;
						box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
						background-color: var(--whiteColor);

						border-top: 1px solid #eeeeee;

						.dropdown-header {
							padding: 0;
							border-bottom: 1px solid #f2f4f9;
							margin: {
								left: -15px;
								right: -15px;
							}
							.figure {
								position: relative;

								img {
									width: 80px;
									height: 80px;
								}
							}
							.name {
								display: block;
								color: var(--blackColor);
								margin-bottom: 2px;
								font: {
									size: 17px;
									weight: 600;
								}
								&::before {
									display: none;
								}
							}
							.email {
								color: var(--optionalColor);
								font-size: 14px;
							}
						}
						.dropdown-body {
							ul {
								padding-left: 0;
								list-style-type: none;
								margin-bottom: 0;

								.nav-item {
									margin-left: 0;

									.nav-link {
										color: var(--blackColor);
										padding: 5px 15px 5px 38px;
										position: relative;
										font: {
											size: 14px;
											weight: 500;
										}
										span {
											color: var(--blackColor);
											display: block;
											font-weight: 500;

											&::before {
												display: none;
											}
										}
										i {
											color: var(--mainColor);
											transition: var(--transition);
											position: absolute;
											left: 15px;
											top: 7px;
											font-size: 16px;
										}
										&:hover {
											span {
												color: var(--mainColor);
											}
											i {
												transform: rotateY(-180deg);
											}
										}
									}
								}
							}
						}
						.dropdown-footer {
							margin: 10px -15px 0;
							padding: 10px 15px 0;
							border-top: 1px solid #eeeeee;

							ul {
								padding-left: 0;
								list-style-type: none;
								margin-bottom: 0;

								.nav-item {
									.nav-link {
										color: red;
										padding: 5px 15px 5px 38px;
										position: relative;
										font: {
											size: 14px;
											weight: 500;
										}
										span {
											color: red;
											display: block;
											font-weight: 500;

											&::before {
												display: none;
											}
										}
										i {
											color: red;
											transition: var(--transition);
											position: absolute;
											left: 15px;
											top: 7px;
											font-size: 16px;
										}
										&:hover {
											i {
												transform: rotateY(-180deg);
											}
										}
									}
								}
							}
						}
						&.show {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
		.container {
			max-width: 100% !important;

			.container {
				max-width: 260px !important;
			}
		}
	}
	.copyrights-area {
		padding: {
			top: 5px;
			bottom: 30px;
		}
		p {
			line-height: initial;
			margin-bottom: 0;

			i {
				position: relative;
				left: 0;
				top: 1px;
			}
			a {
				font-weight: 500;
			}
		}
	}
}
.notification-alert {
	margin-bottom: 30px;
	background-color: #e6f3d8;
	border: none;
	padding: 20px 45px 19px 25px;
	color: #5f9025;
	border-radius: 5px;

	strong {
		font-weight: 600;
	}
	.close {
		top: 6px;
		transition: var(--transition);
	}
}
.breadcrumb-area {
	border-radius: 5px;
	display: flex;
	align-items: center;
	margin-bottom: 40px;

	h1 {
		margin-bottom: 0;
		position: relative;
		color: var(--blackColor);
		padding-right: 13px;
		font: {
			size: 22px;
			weight: 600;
		}
		&::before {
			content: "";
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			height: 16px;
			width: 1px;
			background: #cecece;
		}
	}
	.breadcrumb {
		background-color: transparent;
		border-radius: 0;
		margin-bottom: 0;
		padding: {
			left: 12px;
			right: 0;
			bottom: 0;
			top: 0;
		}
		.item {
			color: var(--optionalColor);
			margin-right: 25px;
			position: relative;
			font-size: 16px;

			a {
				display: block;
				color: var(--optionalColor);

				&:hover {
					color: var(--mainColor);
				}
			}
			&::before {
				content: "\ea1d";
				position: absolute;
				right: -21px;
				top: 4px;
				line-height: 18px;
				color: var(--optionalColor);
				font: {
					family: "boxicons" !important;
					weight: normal;
					style: normal;
					variant: normal;
					size: 18px;
				}
			}
			&:last-child {
				margin-right: 0;

				&::before {
					display: none;
				}
			}
		}
	}
}
/*start-stats-box-css*/
.stats-card-box {
	margin-bottom: 30px;
	padding: 35px 25px 34px 115px;
	border-radius: 5px;
	position: relative;
	background-color: var(--mainColor);

	.icon-box {
		width: 70px;
		height: 70px;
		border-radius: 50%;
		background-color: var(--whiteColor);
		color: var(--mainColor);
		position: absolute;
		left: 25px;
		top: 47%;
		transform: translateY(-47%);
		box-shadow: 1px 5px 24px 0 rgba(14, 198, 198, 0.15);
		text-align: center;
		font-size: 40px;

		i {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.sub-title {
		color: var(--whiteColor);
		display: block;
		margin-bottom: 3px;
		font: {
			size: 16px;
			weight: 500;
		}
		.wallet-currency {
			border-radius: 3px;
			background: rgba(255, 255, 255, 0.2);
			padding: 3px 7px;
			font: {
				weight: 500;
				size: var(--fontSize);
			}
		}
	}
	h3 {
		margin-bottom: 0;
		color: var(--whiteColor);
		font-size: 35px;
	}
}
.col-lg-3,
.col-lg-4 {
	&:nth-child(2) {
		.stats-card-box {
			background-color: #64bc36;

			.icon-box {
				color: #64bc36;
			}
		}
	}
	&:nth-child(3) {
		.stats-card-box {
			background-color: #ffae00;

			.icon-box {
				color: #ffae00;
			}
		}
	}
	&:nth-child(4) {
		.stats-card-box {
			background-color: #f3103c;

			.icon-box {
				color: #f3103c;
			}
		}
	}
}
/*recent-activities-box-css*/
.recent-activities-box {
	margin-bottom: 30px;
	background-color: var(--whiteColor);
	box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
	border-radius: 5px;

	h3 {
		margin-bottom: 0;
		padding: 20px 25px 19px;
		border-bottom: 1px dashed #eeeeee;
		font: {
			size: 17px;
			weight: 600;
		}
	}
	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			border: none;
			margin-bottom: 0;
			border-bottom: 1px dashed #eeeeee;
			border-radius: 0;
			transition: var(--transition);
			color: var(--optionalColor);
			position: relative;
			padding: {
				left: 75px;
				right: 45px;
				top: 25px;
				bottom: 25px;
			}
			.icon {
				height: 40px;
				width: 40px;
				color: #777777;
				text-align: center;
				line-height: 44px;
				border-radius: 50%;
				transition: var(--transition);
				background-color: #eeeeee;
				position: absolute;
				font-size: 20px;
				top: 50%;
				transform: translateY(-50%);
				left: 25px;
			}
			strong {
				font-weight: 600;
				color: var(--blackColor);
			}
			&:last-child {
				border-bottom: none;
			}
			.close {
				padding: 0;
				top: 50%;
				right: 20px;
				transform: translateY(-50%);
				color: red;
				transition: var(--transition);
				opacity: 0;
				visibility: hidden;
			}
			.rating {
				color: var(--whiteColor);
				background-color: var(--mainColor);
				text-align: center;
				line-height: 25px;
				height: 25px;
				width: 45px;
				display: inline-block;
				margin: 0;
				position: relative;
				border-radius: 30px;
				font: {
					size: 14px;
					weight: 600;
				}
				&:before {
					content: attr(data-rating);
				}
				&.high {
					background-color: #64bc36;
				}
				&.mid {
					background-color: var(--mainColor);
				}
				&.low {
					background-color: red;
				}
			}
			&:hover {
				background-color: #f9f9f9;

				.icon {
					background-color: var(--mainColor);
					color: var(--whiteColor);
				}
				.close {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}
/*invoices-box-css*/
.invoices-box {
	margin-bottom: 30px;
	background-color: var(--whiteColor);
	box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
	border-radius: 5px;

	h3 {
		margin-bottom: 0;
		padding: 20px 25px 19px;
		border-bottom: 1px dashed #eeeeee;
		font: {
			size: 17px;
			weight: 600;
		}
	}
	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			border: none;
			margin-bottom: 0;
			border-bottom: 1px dashed #eeeeee;
			border-radius: 0;
			transition: var(--transition);
			color: var(--optionalColor);
			position: relative;
			padding: {
				left: 80px;
				right: 170px;
				top: 26px;
				bottom: 26px;
			}
			.icon {
				height: 40px;
				width: 40px;
				color: #777777;
				text-align: center;
				line-height: 43px;
				border-radius: 50%;
				transition: var(--transition);
				background-color: #eeeeee;
				position: absolute;
				font-size: 20px;
				top: 50%;
				transform: translateY(-50%);
				left: 25px;
			}
			span {
				display: block;
				color: var(--blackColor);
				margin-top: 5px;
				font: {
					size: 16px;
					weight: 600;
				}
			}
			.default-btn {
				position: absolute;
				right: 25px;
				top: 50%;
				transform: translateY(-50%);
				background-color: #e9e9e9;
				box-shadow: unset;
				border-radius: 30px;
				border: none;
				padding: 7px 20px;
				opacity: 0;
				visibility: hidden;
				font: {
					size: 14px;
					weight: 600;
				}
				&:hover {
					color: var(--whiteColor);
					background-color: var(--mainColor);
				}
			}
			&:last-child {
				border-bottom: none;
			}
			&:hover {
				background-color: #f9f9f9;

				.icon {
					background-color: var(--mainColor);
					color: var(--whiteColor);
				}
				.default-btn {
					opacity: 1;
					visibility: visible;
				}
			}
			ul {
				li {
					padding: 0;
					background-color: transparent;
					border: none;
					display: inline-block;
					margin-right: 11px;
					font: {
						size: 14px;
						weight: 500;
					}
					&:last-child {
						margin-right: 0;

						&::before {
							display: none;
						}
					}
					&::before {
						content: "";
						width: 1px;
						height: 12px;
						background-color: #eeeeee;
						position: absolute;
						top: 4px;
						right: -9px;
					}
					&.unpaid {
						color: red;
					}
					&.paid {
						color: #64bc36;
					}
				}
			}
		}
	}
}
/*bookings-listings-box-css*/
.bookings-listings-box {
	margin-bottom: 30px;
	background-color: var(--whiteColor);
	box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
	border-radius: 5px;

	h3 {
		margin-bottom: 0;
		padding: 20px 25px 19px;
		border-bottom: 1px dashed #eeeeee;
		font: {
			size: 17px;
			weight: 600;
		}
	}
	table {
		margin-bottom: 0;

		thead {
			th {
				white-space: nowrap;
				vertical-align: middle;
				color: var(--blackColor);
				padding: 11px 25px 10px;
				border: {
					bottom: 1px dashed #eeeeee;
					top: none;
					right: 1px dashed #eeeeee;
				}
				font: {
					size: var(--fontSize);
					weight: 600;
				}
			}
		}
		tbody {
			td {
				white-space: nowrap;
				vertical-align: middle;
				padding: 30px 25px;
				border: {
					bottom: 1px dashed #eeeeee;
					right: 1px dashed #eeeeee;
					top: 1px dashed #eeeeee;
				}
				&.name {
					position: relative;

					img {
						width: 80px;
						height: 80px;
						border-radius: 50%;
					}
					.info {
						margin-top: 15px;

						span {
							display: block;
							margin-bottom: 8px;
							font: {
								size: 18px;
								weight: 600;
							}
						}
						ul {
							padding-left: 0;
							margin-bottom: 0;
							list-style-type: none;

							li {
								color: var(--optionalColor);
								display: inline-block;
								margin-right: 15px;
								position: relative;
								font: {
									size: 14px;
									weight: 500;
								}
								a {
									color: var(--optionalColor);
									display: inline-block;

									&:hover {
										color: var(--mainColor);
									}
								}
								&::before {
									content: "";
									position: absolute;
									right: -10px;
									top: 5px;
									width: 1px;
									height: 12px;
									background-color: #eeeeee;
								}
								&:last-child {
									margin-right: 0;

									&::before {
										display: none;
									}
								}
							}
						}
					}
					.default-btn {
						border-radius: 30px;
						box-shadow: unset;
						background-color: #eeeeee;
						font-size: 13px;
						border: none;
						margin-top: 14px;
						position: relative;
						padding: {
							top: 8px;
							bottom: 8px;
							left: 40px;
							right: 20px;
						}
						i {
							position: absolute;
							left: 20px;
							top: 50%;
							transform: translateY(-50%);
							font-size: 15px;
							margin-top: -0.5px;
						}
						&:hover {
							background-color: var(--mainColor);
							color: var(--whiteColor);
						}
					}
				}
				&.details {
					h4 {
						margin-bottom: 15px;
						font-size: 17px;

						.bookings-status {
							border-radius: 30px;
							color: var(--whiteColor);
							padding: 3px 10px 3.5px;
							position: relative;
							background-color: var(--mainColor);
							top: -2px;
							margin-left: 2px;
							font: {
								weight: 500;
								size: 12px;
							}
							&.approved {
								background-color: #64bc36;
							}
							&.canceled {
								background-color: red;
							}
						}
					}
					ul {
						padding-left: 0;
						list-style-type: none;
						margin-bottom: 0;

						li {
							margin-top: 12px;
							color: var(--optionalColor);
							font-size: 14px;

							span {
								color: var(--blackColor);
								font-weight: 500;
							}
							i {
								color: var(--mainColor);
								position: relative;
								top: 0.8px;
							}
							a {
								color: var(--optionalColor);

								&:hover {
									color: var(--mainColor);
								}
							}
							strong {
								font-weight: 600;
								color: var(--blackColor);
							}
							.paid {
								color: #64bc36;
							}
							.unpaid {
								color: red;
							}
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
				&.action {
					.default-btn {
						border-radius: 30px;
						box-shadow: unset;
						background-color: #eeeeee;
						font-size: 13px;
						border: none;
						position: relative;
						padding: {
							top: 8px;
							bottom: 8px;
							left: 40px;
							right: 20px;
						}
						&:hover {
							background-color: var(--mainColor);
							color: var(--whiteColor);
						}
						i {
							position: absolute;
							left: 20px;
							top: 50%;
							transform: translateY(-50%);
							font-size: 15px;
						}
						&.danger {
							margin-left: 10px;

							&:hover {
								background-color: red;
								color: var(--whiteColor);
							}
						}
					}
				}
			}
		}
	}
}
/*invoices-box-css*/
.earnings-box {
	margin-bottom: 30px;
	background-color: var(--whiteColor);
	box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
	border-radius: 5px;

	h3 {
		margin-bottom: 0;
		padding: 20px 25px 19px;
		border-bottom: 1px dashed #eeeeee;
		font: {
			size: 17px;
			weight: 600;
		}
		.comission-taken {
			border-radius: 3px;
			color: #888;
			background: #f4f4f4;
			display: inline-block;
			padding: 5px 10px;
			position: relative;
			margin-left: 5px;
			top: -1px;
			font: {
				size: 14px;
				weight: normal;
			}
		}
	}
	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			border: none;
			margin-bottom: 0;
			border-bottom: 1px dashed #eeeeee;
			border-radius: 0;
			transition: var(--transition);
			color: var(--optionalColor);
			position: relative;
			padding: {
				left: 75px;
				right: 25px;
				top: 25px;
				bottom: 25px;
			}
			.icon {
				height: 40px;
				width: 40px;
				color: #777777;
				text-align: center;
				line-height: 43px;
				border-radius: 50%;
				transition: var(--transition);
				background-color: #eeeeee;
				position: absolute;
				font-size: 20px;
				top: 50%;
				transform: translateY(-50%);
				left: 20px;
			}
			span {
				display: block;
				color: var(--blackColor);
				margin-top: 8px;
				font: {
					size: 16.5px;
					weight: 600;
				}
			}
			ul {
				margin-bottom: -4px;

				li {
					padding: 0;
					background-color: transparent;
					border: none;
					display: inline-block;
					font-size: 14.5px;
					margin: {
						right: 12px;
						bottom: 4px;
					}
					&:last-child {
						margin-right: 0;

						&::before {
							display: none;
						}
					}
					&::before {
						content: "";
						width: 1px;
						height: 12px;
						background-color: #eeeeee;
						position: absolute;
						top: 4px;
						right: -9px;
					}
					&.unpaid,
					&.fee-price {
						color: red;
					}
					&.paid,
					&.price {
						color: #64bc36;
					}
					strong {
						font-weight: 600;
					}
				}
			}
			&:last-child {
				border-bottom: none;
			}
			&:hover {
				background-color: #f9f9f9;

				.icon {
					background-color: var(--mainColor);
					color: var(--whiteColor);
				}
				.default-btn {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}
/*my-profile-box-css*/
.my-profile-box {
	margin-bottom: 30px;
	background-color: var(--whiteColor);
	box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
	border-radius: 5px;

	h3 {
		margin-bottom: 0;
		padding: 20px 25px 19px;
		border-bottom: 1px dashed #eeeeee;
		font: {
			size: 17px;
			weight: 600;
		}
	}
	form {
		padding: 25px;

		.row {
			margin: {
				left: -5px;
				right: -5px;
			}
			.col-lg-6,
			.col-lg-12 {
				padding: {
					left: 5px;
					right: 5px;
				}
			}
		}
		.form-group {
			margin-bottom: 20px;
			position: relative;

			label {
				display: block;
				margin-bottom: 8px;
				color: var(--blackColor);
				font: {
					size: 14px;
					weight: 500;
				}
			}
			.form-control {
				color: #808080;

				&::placeholder {
					transition: var(--transition);
					color: #808080;
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
			.form-control.is-invalid{
				border-color: #dc3545 !important;
				padding-right: calc(1.5em + 0.75rem);
			}
		}
		button {
			display: block;
			width: 100%;
			background-color: var(--mainColor);
			color: var(--whiteColor);
			padding: 13px 30px 12.5px;
			box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
				0 1px 3px rgba(0, 0, 0, 0.08);
			border-radius: 5px;
			transition: var(--transition);
			border: none;
			font: {
				size: var(--fontSize);
				weight: 600;
			}
			&:hover {
				background-color: #3b99ca;
				color: var(--whiteColor);
			}
		}
	}
	.profile-box {
		max-width: 300px;
		position: relative;
		border-radius: 5px;

		img {
			border-radius: 5px;
		}
		.file-upload {
			display: inline-block;
			position: absolute;
			left: 15px;
			bottom: 15px;

			.inputfile {
				width: 0.1px;
				height: 0.1px;
				opacity: 0;
				overflow: hidden;
				position: absolute;
				z-index: -1;
			}
			.inputfile + label {
				color: var(--blackColor);
				background-color: var(--whiteColor);
				display: inline-block;
				margin-bottom: 0;
				transition: var(--transition);
				padding: 10px 25px 9px 48px;
				position: relative;
				border-radius: 30px;
				font: {
					size: var(--fontSize);
					weight: 600;
				}
			}
			i {
				position: absolute;
				left: 25px;
				top: 50%;
				transform: translateY(-50%);
				font-size: 16.5px;
				margin-top: -0.5px;
			}
			.inputfile:focus + label,
			.inputfile + label:hover {
				background-color: var(--mainColor);
				color: var(--whiteColor);
			}
			.inputfile + label {
				cursor: pointer;
			}
		}
	}
}
/*message-chat-css*/
.chat-content-area {
	margin-bottom: 30px;

	.sidebar-left {
		float: left;
		overflow-y: scroll;

		.sidebar {
			background-color: var(--whiteColor);
			box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
			padding: 25px;
			width: 300px;

			.chat-sidebar-header {
				padding-bottom: 25px;

				form {
					label {
						display: block;
						margin-bottom: 0;
						position: absolute;
						left: 15px;
						top: 11.3px;
						font-size: 18px;
						color: #a8a8a8;
					}
					.form-control {
						height: 45px;
						border-radius: 30px;
						background-color: #eef5f9;
						border: none;
						color: var(--blackColor);
						padding: 0 15px 0 40px;
						transition: var(--transition);
						box-shadow: unset;
						font: {
							size: 14px;
							weight: 400;
						}
						&:focus {
							box-shadow: unset;
							background-color: #eef5f9;

							&::placeholder {
								color: transparent !important;
							}
						}
						&::placeholder {
							color: #a8a8a8;
							transition: var(--transition);
						}
					}
				}
			}
			.sidebar-content {
				height: calc(100vh - 325px);

				.chat-menu {
					.list-group-user {
						li {
							cursor: pointer;
							margin-bottom: 20px;

							&:last-child {
								margin-bottom: 0;
							}
							.avatar {
								position: relative;

								.status-busy {
									background-color: #ff5b5c;
								}
								.status-online {
									background-color: #39da8a;
								}
								.status-away {
									background-color: #fdac41;
								}
								.status-offline {
									background-color: #475f7b;
								}
								[class*="status-"] {
									border-radius: 50%;
									width: 10px;
									height: 10px;
									position: absolute;
									left: 1px;
									top: 1px;
								}
							}
							h6 {
								margin-bottom: 3px;
								font: {
									size: 14.5px;
									weight: 600;
								}
							}
							span {
								color: #71738d;
								font-size: 14.5px;
							}
						}
					}
					.list-group-label {
						color: var(--blackColor);
						margin: {
							top: 25px;
							bottom: 20px;
						}
						padding-bottom: 5px;
						position: relative;
						border-bottom: 1px solid #eeeeee;
						font: {
							size: 16px;
							weight: 600;
						}
						&::before {
							width: 70px;
							height: 1px;
							content: "";
							position: absolute;
							left: 0;
							bottom: -1px;
							background-color: var(--mainColor);
						}
					}
				}
			}
		}
	}
	.content-right {
		float: right;
		width: calc(100% - 330px);

		.chat-list-wrapper {
			.chat-list {
				background-color: var(--whiteColor);
				box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);

				.simplebar-scrollbar {
					&::before {
						background: #aaaaaa;
					}
				}
				.chat-list-header {
					padding: 15px 20px;

					.header-left {
						h6 {
							white-space: nowrap;
							font: {
								size: 16px;
								weight: 600;
							}
						}
						.avatar {
							position: relative;

							.status-busy {
								background-color: #ff5b5c;
							}
							.status-online {
								background-color: #39da8a;
							}
							.status-away {
								background-color: #fdac41;
							}
							.status-offline {
								background-color: #475f7b;
							}
							[class*="status-"] {
								border-radius: 50%;
								width: 10px;
								height: 10px;
								position: absolute;
								left: -1px;
								top: -1px;
							}
						}
					}
					.header-right {
						position: relative;
						top: 1px;

						ul {
							li {
								display: inline-block;
								margin-left: 1px;

								&:first-child {
									margin-left: 0;
								}
								.favorite {
									font-size: 20px;
									cursor: pointer;
									color: #c7cfd6;
									position: relative;
									top: -1px;

									&.active {
										color: #fdac41;

										i {
											&::before {
												content: "\ee67";
											}
										}
									}
								}
								.dropdown {
									.dropdown-toggle {
										padding: 0;
										border: none;
										background-color: transparent;
										color: #727e8c;
										font-size: 22px;
										line-height: 15px;

										&::after {
											display: none;
										}
									}
									.dropdown-menu {
										box-shadow: 0 0.5rem 1rem
											rgba(0, 0, 0, 0.05);
										background-color: var(--whiteColor);
										border: none;
										border-radius: 0;
										margin-top: 35px;
										display: block;
										opacity: 0;
										visibility: hidden;
										transition: 0.3s;
										transform: unset !important;
										left: auto !important;
										right: 0;

										&.show {
											margin-top: 25px;
											opacity: 1;
											visibility: visible;
										}
										.dropdown-item {
											color: var(--blackColor);
											padding: 5px 15px 5px 36px;
											position: relative;
											font: {
												size: 14px;
											}
											i {
												color: #686868;
												transition: var(--transition);
												position: absolute;
												left: 15px;
												top: 7px;
												font: {
													size: 16px;
												}
											}
											&.active,
											&:active {
												color: var(--blackColor);
												background-color: transparent;
												background-color: #f8f9fa;
											}
											&:hover {
												i {
													transform: rotateY(-180deg);
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.chat-container {
					height: calc(100vh - 340px);
					background-color: #e3e6f1;
					padding: 25px 20px;
					overflow-y: scroll;
					background: {
						image: url(../images/chat-bg.png);
						position: center center;
						size: contain;
						repeat: repeat;
					}
					.chat-content {
						text-align: center;

						.chat {
							.chat-avatar {
								float: right;
							}
							.chat-body {
								overflow: hidden;

								.chat-message {
									position: relative;
									float: right;
									max-width: 420px;
									text-align: left;
									padding: 12px 16px;
									margin: 10px 21.28px 21.28px 0;
									clear: both;
									word-break: break-word;
									color: var(--whiteColor);
									background-color: #3578e5;
									border-radius: 5px;
									box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);

									p {
										color: var(--whiteColor);
										margin-bottom: 0;
										font-size: 14px;

										a {
											color: var(--whiteColor) !important;
										}
									}
									.time {
										position: absolute;
										bottom: -25px;
										right: 0;
										color: #6b7886;
										font-size: 0.8rem;
										white-space: nowrap;
									}
									&:first-child {
										margin-top: 0 !important;
									}
								}
							}
							&.chat-left {
								.chat-avatar {
									float: left;
								}
								.chat-body {
									.chat-message {
										text-align: left;
										float: left;
										margin: 10px 0 21.28px 21.28px;
										color: #727e8c;
										background-color: var(--whiteColor);
										box-shadow: 0 2px 6px 0
											rgba(0, 0, 0, 0.3);

										p {
											color: #727e8c;

											a {
												color: #727e8c !important;
											}
										}
									}
								}
							}
						}
						.badge-light {
							padding: 9px 20px 8px;
							text-transform: uppercase;
							font-weight: 500;
						}
					}
				}
				.chat-list-footer {
					background-color: #fafafa;
					padding: 15px 20px;

					.emoji-btn {
						padding: 0;
						border: none;
						background-color: #d4ebf7;
						border-radius: 50%;
						width: 35px;
						font-size: 19px;
						line-height: 39px;
						height: 35px;
						color: var(--mainColor);
						transition: var(--transition);

						&:hover,
						&:focus {
							background-color: var(--mainColor);
							color: var(--whiteColor);
						}
					}
					.file-attachment-btn {
						padding: 0;
						border: none;
						background-color: #d4ebf7;
						border-radius: 50%;
						width: 35px;
						font-size: 19px;
						line-height: 39px;
						height: 35px;
						color: var(--mainColor);
						transition: var(--transition);

						&:hover,
						&:focus {
							background-color: var(--mainColor);
							color: var(--whiteColor);
						}
					}
					form {
						position: relative;
						padding-right: 128px;

						.form-control {
							background-color: var(--whiteColor);
							height: 45px;
							border-radius: 30px;
							transition: var(--transition);
							padding-left: 15px;
							font-size: 14.5px;
							border: 1px solid #dfe3e7;
							color: var(--blackColor);

							&:focus {
								border-color: var(--mainColor);
								box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
							}
						}
						.send-btn {
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
							border: none;
							background-color: var(--mainColor);
							color: var(--whiteColor);
							transition: var(--transition);
							border-radius: 30px;
							height: 45px;
							padding: 1px 30px 0;
							box-shadow: 0 4px 12px 0 rgba(8, 141, 211, 0.2);
							font: {
								size: var(--fontSize);
								weight: 600;
							}
							&:hover {
								box-shadow: 0 4px 12px 0 rgba(8, 141, 211, 0.6);
							}
						}
					}
				}
			}
		}
	}
}
/*reviews-box-css*/
.visitor-reviews-box {
	margin-bottom: 30px;
	background-color: var(--whiteColor);
	box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
	border-radius: 5px;

	h3 {
		margin-bottom: 0;
		padding: 20px 25px 19px;
		border-bottom: 1px dashed #eeeeee;
		font: {
			size: 17px;
			weight: 600;
		}
	}
	.user-review {
		border-bottom: 1px solid #f3f3f3;
		padding: 25px 20px 25px 135px;
		position: relative;

		.user {
			position: absolute;
			left: 25px;
			top: 25px;
			width: 90px;
			height: 90px;
			border-radius: 5px;
		}
		.sub-comment {
			margin-bottom: 8px;
			font: {
				size: 16.5px;
				weight: 600;
			}
		}
		.review-rating {
			display: block;
			margin-bottom: 10px;

			.review-stars {
				display: inline-block;
				margin-right: 5px;

				i {
					color: #f2b827;
					font-size: 16px;
					display: inline-block;
					margin-right: -3px;
				}
			}
			span {
				color: var(--blackColor);
				position: relative;
				top: -2px;
				font-weight: 600;

				span {
					color: var(--optionalColor);
					top: 0;
					font-weight: 500;

					a {
						color: var(--optionalColor);

						&:hover {
							color: var(--mainColor);
						}
					}
				}
			}
		}
		.review-image {
			margin: {
				bottom: 10px;
				top: 20px;
			}
			.row {
				margin: {
					right: -8px;
					left: -8px;
				}
				.col-lg-3 {
					padding: {
						left: 8px;
						right: 8px;
					}
				}
			}
			a {
				border-radius: 5px;
				display: block;
				cursor: zoom-in;
				margin-bottom: 15px;

				img {
					border-radius: 5px;
				}
			}
		}
		.date {
			display: block;
			margin-bottom: 10px;
			color: var(--optionalColor);
			padding-left: 18px;
			position: relative;
			font: {
				size: 14.5px;
				weight: 500;
			}
			i {
				color: var(--mainColor);
				position: absolute;
				left: 0;
				top: 3px;
			}
		}
		p {
			font-size: 14.5px;
		}
		.btn-box {
			.default-btn {
				border-radius: 30px;
				box-shadow: unset;
				background-color: #eeeeee;
				font-size: 13px;
				border: none;
				position: relative;
				padding: {
					top: 8px;
					bottom: 8px;
					left: 40px;
					right: 20px;
				}
				&:hover {
					background-color: var(--mainColor);
					color: var(--whiteColor);
				}
				i {
					position: absolute;
					left: 20px;
					top: 50%;
					transform: translateY(-50%);
					font-size: 15px;
				}
				&.danger {
					margin-left: 10px;

					&:hover {
						background-color: red;
						color: var(--whiteColor);
					}
				}
			}
		}
	}
}
/*add-listings-box-css*/
.add-listings-box {
	margin-bottom: 30px;
	background-color: var(--whiteColor);
	box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
	border-radius: 5px;
	padding: 0 25px 0 25px;

	h3 {
		padding: 20px 25px 19px;
		border-bottom: 1px dashed #eeeeee;
		font: {
			size: 17px;
			weight: 600;
		}
		margin: {
			bottom: 25px;
			left: -25px;
			right: -25px;
		}
	}
	.form-group {
		margin-bottom: 25px;
		position: relative;

		label {
			display: block;
			margin-bottom: 12px;
			color: var(--blackColor);
			position: relative;
			padding-left: 25px;
			font-weight: 500;

			i {
				color: var(--mainColor);
				position: absolute;
				left: 0;
				top: 0.5px;
				font-size: 19px;

				&.bxl-facebook-square {
					color: #3b5998;
				}
				&.bxl-twitter {
					color: #1da1f2;
				}
				&.bxl-linkedin {
					color: #0077b5;
				}
			}
			span {
				color: var(--optionalColor);
				font-weight: 400;
			}
		}
		.form-control {
			height: 48px;
			background: #f9f9f9 !important;
			transition: var(--transition);
			border-radius: 5px;
			border: 1px solid #e5e7f2 !important;
			color: var(--blackColor) !important;
			font: {
				weight: 400;
				size: var(--fontSize);
			}
			padding: {
				top: 0;
				bottom: 0;
				right: 15px;
				left: 15px;
			}
			&::placeholder {
				transition: var(--transition);
				color: #7d93b2;
			}
			&:focus {
				border-color: var(--mainColor);

				&::placeholder {
					color: transparent;
				}
			}
		}
		textarea.form-control {
			padding-top: 15px;
			height: auto;
		}
		.form-control.is-invalid{
			border-color: #dc3545 !important;
			padding-right: calc(1.5em + 0.75rem);
		}
		.nice-select {
			float: unset;
			display: block;
			height: 48px;
			line-height: 48px;
			background: #f9f9f9;
			transition: var(--transition);
			border-radius: 5px;
			border: 1px solid #e5e7f2;
			color: #7d93b2;
			width: 100%;
			font: {
				weight: 400;
				size: var(--fontSize);
			}
			.list {
				background-color: var(--whiteColor);
				border-radius: 5px;
				box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
				list-style-type: none;
				border: none;
				width: 100%;
				margin: {
					bottom: 0;
					top: 0;
				}
				padding: {
					left: 0;
					top: 10px;
					bottom: 10px;
				}
				.option {
					line-height: 38px;
					min-height: 38px;
					color: var(--blackColor);
					position: relative;
					transition: var(--transition);
					padding: {
						left: 32px;
						right: 25px;
					}
					font: {
						size: var(--fontSize);
						weight: 600;
					}
					&:hover {
						background-color: var(--mainColor) !important;
						color: var(--whiteColor);

						&::before {
							color: var(--whiteColor);
						}
					}
					&.focus,
					&.selected.focus {
						background-color: transparent !important;
						color: var(--blackColor);
					}
					&::before {
						content: "\ea0f";
						position: absolute;
						left: 10px;
						top: -3px;
						opacity: 0;
						visibility: hidden;
						transition: var(--transition);
						color: var(--mainColor);
						font: {
							family: "boxicons";
							size: 20px;
						}
					}
					&.selected {
						&::before {
							opacity: 1;
							visibility: visible;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
					}
				}
			}
			&:after {
				border-color: var(--blackColor);
				height: 8px;
				width: 8px;
				margin-top: -5px;
				right: 15px;
			}
			&:focus {
				border-color: var(--mainColor);
				background-color: transparent;
			}
		}
		.facilities-list {
			padding-left: 0;
			margin-bottom: -15px;
			list-style-type: none;
			overflow: hidden;

			li {
				float: left;
				margin-right: 20px;
				position: relative;
				margin-bottom: 15px;

				.checkbox {
					margin-bottom: 0;
					padding-left: 0;
					cursor: pointer;
					display: inline-block;

					input {
						display: none;
					}
					input + span {
						line-height: 20px;
						height: 20px;
						padding-left: 20px;
						display: block;
						position: relative;
						font-weight: 500;
					}
					input + span:not(:empty) {
						padding-left: 28px;
					}
					input + span:before,
					input + span:after {
						content: "";
						width: 20px;
						height: 20px;
						display: block;
						border-radius: 50%;
						left: 0;
						top: -1px;
						position: absolute;
					}
					input + span:before {
						background: #eeeeee;
						transition: background 0.2s ease,
							transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2),
							-webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
					}
					input + span:after {
						background: #eeeeee;
						transition: transform 0.6s
								cubic-bezier(0.175, 0.885, 0.32, 1.4),
							-webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
					}
					input:checked + span:before {
						background: var(--mainColor);
					}
					input:checked + span:after {
						transform: scale(0.4);
						transition: transform 0.3s ease,
							-webkit-transform 0.3s ease;
					}
				}
			}
		}
		.sidebar-widgets {
			.box {
				background: #f6f6f6;
				border: 1px solid #eeeeee;
				border-radius: 5px;
				padding: 20px;
				position: relative;
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}
				.title {
					display: block;
					color: var(--blackColor);
					position: relative;
					font-weight: 600;
				}
				.switch {
					padding-left: 0;
					margin-bottom: 0;
					display: inline-block;
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translateY(-50%);
					cursor: pointer;

					input {
						display: none;
					}
					input + span {
						width: 48px;
						height: 28px;
						border-radius: 14px;
						-webkit-transition: all 0.3s ease;
						transition: all 0.3s ease;
						display: block;
						position: relative;
						background: var(--mainColor);
						box-shadow: 0 8px 16px -1px rgba(8, 141, 211, 0.2);
					}
					input + span:before,
					input + span:after {
						content: "";
						display: block;
						position: absolute;
						-webkit-transition: all 0.3s ease;
						transition: all 0.3s ease;
					}
					input + span:before {
						top: 5px;
						left: 5px;
						width: 18px;
						height: 18px;
						border-radius: 9px;
						border: 5px solid var(--whiteColor);
					}
					input + span:after {
						top: 5px;
						left: 32px;
						width: 6px;
						height: 18px;
						border-radius: 40%;
						transform-origin: 50% 50%;
						background: var(--whiteColor);
						opacity: 0;
					}
					input + span:active {
						transform: scale(0.92);
					}
					input:checked + span {
						background: #48ea8b;
						box-shadow: 0 8px 16px -1px rgba(72, 234, 139, 0.2);
					}
					input:checked + span:before {
						width: 0px;
						border-radius: 3px;
						margin-left: 27px;
						border-width: 3px;
						background: var(--whiteColor);
					}
					input:checked + span:after {
						animation: blobChecked 0.35s linear forwards 0.2s;
					}
					input:not(:checked) + span:before {
						animation: blob 0.85s linear forwards 0.2s;
					}
				}
			}
		}
	}
	.file-upload-box {
		margin-bottom: 30px;

		.dropzone {
			position: relative;
			border: 2px dashed #eeeeee;
			border-radius: 3px;
			background: rgba(14, 198, 198, 0.03);
			transition: all 0.3s linear;
			display: inline-block;
			width: 100%;
			margin: 0;

			button {
				color: var(--blackColor);
				position: relative;
				padding-top: 52px;
				font: {
					size: 16px;
					weight: 500;
				}
				&::before {
					content: "\eb8a";
					font-family: "boxicons";
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					color: var(--mainColor);
					font-size: 35px;
				}
			}
			&:hover {
				border-color: var(--mainColor);
			}
		}
	}
	.opening-day {
		margin-bottom: 25px;

		h5 {
			margin-bottom: 0;
			font: {
				size: var(--fontSize);
				weight: 600;
			}
		}
		.nice-select {
			float: unset;
			display: block;
			height: 48px;
			line-height: 48px;
			background: #f9f9f9;
			transition: var(--transition);
			border-radius: 5px;
			border: 1px solid #e5e7f2;
			color: var(--blackColor);
			width: 100%;
			font: {
				weight: 500;
				size: var(--fontSize);
			}
			.list {
				background-color: var(--whiteColor);
				border-radius: 5px;
				box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
				list-style-type: none;
				border: none;
				height: 210px;
				overflow-y: scroll;
				width: 100%;
				margin: {
					bottom: 0;
					top: 0;
				}
				padding: {
					left: 0;
					top: 10px;
					bottom: 10px;
				}
				.option {
					line-height: 38px;
					min-height: 38px;
					color: var(--blackColor);
					position: relative;
					transition: var(--transition);
					padding: {
						left: 32px;
						right: 25px;
					}
					font: {
						size: var(--fontSize);
						weight: 600;
					}
					&:hover {
						background-color: var(--mainColor) !important;
						color: var(--whiteColor);

						&::before {
							color: var(--whiteColor);
						}
					}
					&.focus,
					&.selected.focus {
						background-color: transparent !important;
						color: var(--blackColor);
					}
					&::before {
						content: "\ea0f";
						position: absolute;
						left: 10px;
						top: -3px;
						opacity: 0;
						visibility: hidden;
						transition: var(--transition);
						color: var(--mainColor);
						font: {
							family: "boxicons";
							size: 20px;
						}
					}
					&.selected {
						&::before {
							opacity: 1;
							visibility: visible;
						}
						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);

							&::before {
								color: var(--whiteColor);
							}
						}
					}
				}
			}
			&:after {
				border-color: var(--blackColor);
				height: 8px;
				width: 8px;
				margin-top: -5px;
				right: 15px;
			}
			&:focus {
				border-color: var(--mainColor);
				background-color: transparent;
			}
		}
	}
}
.add-listings-btn {
	margin-bottom: 30px;

	button {
		display: inline-block;
		background-color: var(--mainColor);
		color: var(--whiteColor);
		padding: 12.5px 30px;
		box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
			0 1px 3px rgba(0, 0, 0, 0.08);
		border-radius: 5px;
		transition: var(--transition);
		border: none;
		font: {
			size: var(--fontSize);
			weight: 600;
		}
		&:hover {
			background-color: #3b99ca;
			color: var(--whiteColor);
		}
	}
}
/*invoice-css*/
.invoice-area {
	background-color: var(--whiteColor);
	box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
	border-radius: 5px;
	padding: 25px;
	margin-bottom: 30px;
}
.invoice-header {
	margin-bottom: 25px;

	h3 {
		font-size: 22px;
		margin-bottom: 18px;
	}
	p {
		font-weight: 500;
	}
}
.invoice-middle {
	margin-bottom: 25px;

	h4 {
		font-size: 17px;
		margin-bottom: 20px;
	}
	h5 {
		margin-bottom: 12px;
		font: {
			size: var(--fontSize);
			weight: 500;
		}
		sub {
			margin-left: 50px;
			bottom: 0;
			width: 90px;
			display: inline-block;
			color: #6084a4;
			font: {
				weight: 500;
				size: var(--fontSize);
			}
		}
	}
	span {
		font-weight: 500;
	}
}
.invoice-table {
	table {
		margin-bottom: 0;

		thead {
			th {
				text-align: center;
				vertical-align: middle;
				border-bottom: 1px solid #dee2e6;
				font: {
					size: var(--fontSize);
					weight: 600;
				}
				padding: {
					top: 10px;
					bottom: 10px;
					left: 15px;
					right: 15px;
				}
			}
		}
		tbody {
			td {
				vertical-align: middle;
				color: #6084a4;
				border-width: 1px;
				font-size: 14.5px;
				padding: {
					top: 10px;
					bottom: 10px;
					left: 15px;
					right: 15px;
				}
				&.total,
				&.total-price {
					color: var(--blackColor);
					font-size: 17px;
				}
				strong {
					font-weight: 600;
					color: var(--blackColor);
				}
			}
		}
	}
}
.invoice-btn-box {
	margin-top: 25px;

	.default-btn {
		transition: var(--transition);
		text-decoration: none;
		padding: 13px 35px 12px;
		border-radius: 30px;
		text-transform: uppercase;
		border: none;
		display: inline-block;
		transition: var(--transition);
		background-color: var(--mainColor);
		color: var(--whiteColor);
		box-shadow: unset;
		font: {
			size: 14px;
			weight: 600;
		}
		&:hover,
		&:focus {
			background-color: var(--blackColor);
			color: var(--whiteColor);
		}
	}
}
/*sidemenu-css*/
.sidemenu-area {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 250px;
	z-index: 9999;
	transition: var(--transition);

	.sidemenu-header {
		background: var(--whiteColor);
		padding: 0 20px;
		border-bottom: 1px solid #f2f4f9;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 85px;
		width: 100%;
		transition: var(--transition);

		.navbar-brand {
			padding: 0;
		}
		.responsive-burger-menu {
			cursor: pointer;
			transition: var(--transition);

			span {
				height: 1px;
				width: 25px;
				background: var(--blackColor);
				display: block;
				margin: 6px 0;
				transition: all 0.5s ease-in-out;
			}
			&.active {
				span {
					&.top-bar {
						transform: rotate(45deg);
						transform-origin: 10% 10%;
					}
					&.middle-bar {
						opacity: 0;
					}
					&.bottom-bar {
						transform: rotate(-45deg);
						transform-origin: 10% 90%;
						margin-top: 5px;
					}
				}
			}
		}
	}
	.sidemenu-body {
		max-height: calc(100% - 80px);
		position: relative;
		height: 100%;
		box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.1);
		background: var(--whiteColor);
		overflow: hidden;

		.sidemenu-nav {
			padding: 20px 0;
			list-style-type: none;
			margin-bottom: 0;

			.nav-item-title {
				color: #686868;
				display: block;
				text-transform: uppercase;
				margin-bottom: 7px;
				font: {
					size: 13px;
					weight: 400;
				}
				padding: {
					left: 20px;
					right: 20px;
				}
				&:not(:first-child) {
					margin-top: 20px;
				}
			}
			.nav-item {
				.nav-link {
					color: #7e7e7e;
					padding: 10px 20px 9px;
					position: relative;
					z-index: 1;
					font-size: var(--fontSize);

					.icon {
						color: var(--blackColor);
						transition: var(--transition);
						display: inline-block;
						margin-right: 5px;
						font-size: 18px;
						position: relative;
						top: 2px;
					}
					.badge {
						background-color: #43d39e;
						color: var(--whiteColor);
						transition: var(--transition);
						margin-left: 4px;
						position: relative;
						font-weight: normal;
						top: -1px;
						padding: 4px 6px 4px 5px;

						&.yellow {
							background-color: #b9be1c;
						}
						&.red {
							background-color: #ee3535;
						}
					}
					&.disabled {
						color: #a3a3a3;

						i {
							color: #a3a3a3;
						}
					}
					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						height: 100%;
						width: 0;
						transition: var(--transition);
						background: var(--mainColor);
					}
					&.collapsed-nav-link {
						position: relative;

						&::after {
							content: "\ea17";
							position: absolute;
							right: 15px;
							top: 9px;
							color: #7e7e7e;
							transition: var(--transition);
							font: {
								family: "boxicons" !important;
								weight: normal;
								style: normal;
								variant: normal;
								size: 18px;
							}
						}
					}
					&:hover {
						background-color: #fafafa;
						color: var(--mainColor);

						&::before {
							width: 3px;
						}
						.icon {
							transform: rotateY(-180deg);
							color: var(--mainColor);
						}
					}
				}
				&.active {
					.nav-link {
						background-color: #fafafa;
						color: var(--mainColor);

						&::before {
							width: 3px;
						}
						.icon {
							color: var(--mainColor);
						}
						&.collapsed-nav-link {
							&::after {
								transform: rotate(-180deg);
							}
						}
					}
				}
				.sidemenu-nav-second-level {
					list-style-type: none;
					margin-bottom: 0;
					background-color: #fafafa;
					padding-left: 45px;

					.nav-item {
						.nav-link {
							color: #7e7e7e;
							background-color: transparent !important;
							padding: {
								left: 0;
								right: 0;
								bottom: 5px;
							}
							.icon {
								color: var(--blackColor);
							}
							&::before {
								display: none;
							}
							&.collapsed-nav-link {
								&::after {
									transform: rotate(0deg);
								}
							}
							&:hover {
								color: var(--mainColor);

								.icon {
									color: var(--mainColor);
								}
							}
						}
						&.mm-active {
							.nav-link {
								color: var(--mainColor);

								.icon {
									color: var(--mainColor);
								}
								&.collapsed-nav-link {
									&::after {
										transform: rotate(-90deg);
									}
								}
							}
						}
						.sidemenu-nav-third-level {
							list-style-type: none;
							padding-left: 15px;
							margin-bottom: 0;

							.nav-item {
								.nav-link {
									color: #7e7e7e;
									background-color: transparent !important;
									padding: {
										left: 0;
										right: 0;
										bottom: 5px;
									}
									.icon {
										color: var(--blackColor);
									}
									&::before {
										display: none;
									}
									&.collapsed-nav-link {
										&::after {
											transform: rotate(0deg);
										}
									}
									&:hover {
										color: var(--mainColor);

										.icon {
											color: var(--mainColor);
										}
									}
								}
								&.mm-active {
									.nav-link {
										color: var(--mainColor);

										.icon {
											color: var(--mainColor);
										}
										&.collapsed-nav-link {
											&::after {
												transform: rotate(-90deg);
											}
										}
									}
								}
							}
						}
						&:last-child {
							padding-bottom: 15px;
						}
					}
				}
			}
		}
	}
	&.toggle-sidemenu-area {
		width: 55px;

		.sidemenu-header {
			padding: 0 10px;

			.navbar-brand {
				display: block !important;

				span {
					opacity: 0;
					visibility: hidden;
				}
				img {
					position: relative;
					top: -3px;
				}
			}
			.burger-menu {
				opacity: 0;
				visibility: hidden;
			}
		}
		.sidemenu-body {
			.sidemenu-nav {
				.nav-item-title {
					display: none;
				}
				.nav-item {
					.nav-link {
						.menu-title {
							display: none;
						}
						&::after {
							display: none;
						}
						.badge {
							display: none;
						}
					}
					.sidemenu-nav-second-level {
						padding-left: 20px;

						.nav-item {
							.sidemenu-nav-third-level {
								padding-left: 0;
							}
						}
					}
				}
			}
		}
	}
}

.listings-area {
	.react-tabs__tab-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		list-style: none;
	}
}

.shorting-menu {
	.react-tabs__tab-list {
		.react-tabs__tab--selected {
			button {
				background: #0ec6c6;
				color: #fff;
			}
		}
	}
}

.shorting-menu .filter {
	display: flex;
}

.listings-widget-area {
	.widget_filters {
		ul {
			li {
				margin-right: 0;
			}
		}
	}
}

.side-full-map {
	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}
}

.map-home-area {
	.full-width-map {
		iframe {
			width: 100%;
			height: 600px;
			border: none;
		}
	}
}

.page-title-bg {
	&.map-home-area {
		#main-full-map {
			position: relative;
			z-index: 1;
		}
	}
}

.page-title-bg {
	&.map-home-area {
		#main-full-map {
			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background-color: #000;
				opacity: 0.45;
				z-index: 2;
			}
		}
	}
}

.sidemenu-area {
	.sidemenu-body {
		.sidemenu-nav {
			.nav-item {
				a {
					color: #7e7e7e;
					padding: 10px 20px 9px;
					position: relative;
					z-index: 1;
					font-size: var(--fontSize);
					display: block;
					padding: 0.5rem 1rem;
					&:hover {
						background-color: #fafafa;
						color: var(--mainColor);
					}
				}
				.active {
					background-color: #fafafa;
					color: var(--mainColor);
				}
				.icon {
					color: var(--blackColor);
					-webkit-transition: var(--transition);
					transition: var(--transition);
					display: inline-block;
					margin-right: 5px;
					font-size: 18px;
					position: relative;
					top: 2px;
				}
			}
		}
	}
}

.sidemenu-area {
	.sidemenu-body {
		.sidemenu-nav {
			overflow-y: scroll;
		}
	}
}

.sidemenu-area {
	.sidemenu-body {
		.sidemenu-nav {
			.show {
				display: block;
			}
			.sidemenu-nav-display {
				display: none;
			}
		}
	}
}

.products-details-tabs {
	.react-tabs__tab-list {
		display: flex;
		justify-content: center;
		list-style: none;
	}
}

.products-details-tabs {
	.nav {
		.nav-link {
			&::before {
				content: "";
				position: absolute;
				left: 0;
				width: 0;
				height: 3px;
				background-color: var(--mainColor);
				-webkit-transition: var(--transition);
				transition: var(--transition);
				bottom: -2px;
			}
		}
	}
}

.products-details-tabs {
	.nav {
		.react-tabs__tab--selected {
			.nav-link {
				&::before {
					width: 100%;
				}
			}
		}
	}
}

.products-details-tabs {
	.nav {
		.react-tabs__tab--selected {
			.nav-link {
				&:hover {
					&::before {
						width: 100%;
					}
				}
			}
		}
	}
}

.products-details-tabs {
	.nav {
		.react-tabs__tab--selected {
			a {
				color: var(--blackColor) !important;
			}
		}
	}
}

.products-details-tabs {
	.nav {
		.nav-link {
			&:hover {
				color: var(--blackColor);
			}
		}
	}
}

.pricing-tabs {
	.react-tabs__tab-list {
		display: flex;
		justify-content: center;
		list-style: none;
	}
}

.pricing-tabs {
	.nav-tabs {
		.monthly-button {
			border-radius: 30px 0 0 30px !important;
		}
	}
}

.pricing-tabs {
	.nav-tabs {
		.annual-button {
			border-radius: 0 30px 30px 0 !important;
		}
	}
}

.pricing-tabs {
	.nav-tabs {
		.react-tabs__tab--selected {
			.nav-item {
				a {
					&::before {
						opacity: 1;
						visibility: visible;
						background-color: var(--mainColor);
					}
				}
			}
		}
	}
}

.pricing-tabs {
	.nav-tabs {
		.react-tabs__tab--selected {
			.nav-item {
				.nav-link {
					color: var(--whiteColor);
				}
			}
		}
	}
}

.show {
	display: block !important;
}

.body_overlay {
	position: fixed;
	top: 0;
	left: 0;
	content: "";
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
	z-index: 1000;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.body_overlay {
	&.open {
		visibility: visible;
		opacity: 1;
	}
}

.modal-dialog {
	.react-tabs__tab-list {
		display: flex;
		list-style: none;
		margin-left: -37px;
		cursor: pointer;
	}
}

.loginRegisterModal {
	.modal-dialog {
		.modal-content {
			.nav-tabs {
				.react-tabs__tab--selected {
					.nav-item {
						a {
							color: var(--blackColor);
						}
					}
				}
			}
		}
	}
}

.loginRegisterModal {
	.modal-dialog {
		.modal-content {
			.nav-tabs {
				.react-tabs__tab--selected {
					.nav-item {
						a {
							&::before {
								-webkit-transform: scaleX(1);
								transform: scaleX(1);
							}
						}
					}
				}
			}
		}
	}
}

.form-group {
	.category-icon {
		position: absolute;
		top: 0px !important;
	}
}

.banner-form-select {
	background: none;
	border: none;
	cursor: pointer;
}

.country-switch {
	select {
		background: #f1f1f1;
		border: none;
		width: 100%;
		padding: 13px;
		color: #0ec6c6;
		font-weight: 600;
	}
}

.languages-switch {
	select {
		background: #f1f1f1;
		border: none;
		width: 100%;
		padding: 13px;
		color: #0ec6c6;
		font-weight: 600;
	}
}

.Cursor.Cursor--blinking {
	display: none;
}

.banner-wrapper-content {
	h1 {
		display: flex;
	}
}

.main-banner-content {
	.banner-one-heading {
		display: flex;
		justify-content: center;
	}
}

.banner-content {
	.banner-two-heading {
		display: flex;
	}
}

.typewrite {
    margin-right: 10px;
}

.listing-area {
	.react-tabs__tab-list {
		display: flex;
		list-style: none;
		margin-left: -30px;
	}
}

.react-tabs__tab-list {
	li {
		margin-right: 11px;
	}
}

.dashbaord-category-select {
	background: #f6f6f6;
	border: 1px solid #eee;
	width: 100%;
	padding: 14px;
}

.blog-select {
	background: none;
	border: none;
	font-weight: 600;
	cursor: pointer;
}

.shop-select {
	background: #eee;
	border: 1px solid #eee;
	padding: 9px;
	font-weight: 600;
}

.checkout-select {
	background: #eee;
	border: 1px solid #eee;
	width: 100%;
	padding: 12px;
	font-weight: 600;
}

.banner-form-select-two {
	background: none;
	border: none;
	color: #787878;
}

.banner-content {
	form {
		.form-group {
			&.category-select {
				text-align: center;
			}
		}
	}
}

.banner-wrapper-content {
	form {
		.form-group {
			&.category-select {
				border-right: none;
				padding-right: 0px;
				text-align: center;
			}
		}
	}
}

.banner-content {
	form {
		.form-group {
			&.category-select {
				padding-right: 0px;
			}
		}
	}
}

.banner-form-select-four {
	background: none;
	border: none;
	color: #707070;
	width: 91%;
	margin-left: 20px;
}

.banner-form-select-pagebanner {
	background: none;
	border: none;
	color: #5c5c5c;
}

.sidemenu-nav {
	&::-webkit-scrollbar {
		width: 7px;
	}
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background: #cfcfcf;
		border-radius: 20px;
	}
}

.sidemenu-nav {
	&::-webkit-scrollbar-thumb {
		&:hover {
			background: #888;
		}
	}
}

.sidebar-left {
	&::-webkit-scrollbar {
		width: 7px;
	}
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background: #cfcfcf;
		border-radius: 20px;
	}
}

.sidebar-left {
	&::-webkit-scrollbar-thumb {
		&:hover {
			background: #888;
		}
	}
}

.chat-container {
	&::-webkit-scrollbar {
		width: 7px;
	}
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background: #cfcfcf;
		border-radius: 20px;
	}
}

.chat-container {
	&::-webkit-scrollbar-thumb {
		&:hover {
			background: #888;
		}
	}
}

.nav-item {
	&:hover {
		.icon {
			transform: rotateY(-180deg);
			color: var(--mainColor);
		}
	}
}

.sidemenu-area {
	.sidemenu-body {
		.sidemenu-nav {
			.nav-item {
				.active {
					background-color: #fafafa;
					color: var(--mainColor);
				}
			}
		}
	}
}

.sidemenu-area {
	.sidemenu-body {
		.sidemenu-nav {
			.nav-item {
				.active {
					.nav-link {
						&::before {
							width: 3px;
						}
					}
				}
			}
		}
	}
}

.sidemenu-area {
	.sidemenu-body {
		.sidemenu-nav {
			.nav-item {
				.nav-link {
					&::before {
						-webkit-transition: var(--transition);
						transition: var(--transition);
						background: var(--mainColor);
					}
				}
			}
		}
	}
}

.sidemenu-area {
	.sidemenu-body {
		.sidemenu-nav {
			.nav-item {
				&.active {
					.nav-link {
						color: var(--mainColor);
					}
				}
			}
		}
	}
}

.sidemenu-area {
	.sidemenu-body {
		.sidemenu-nav {
			.active {
				&::before {
					content: "";
					width: 3px;
					background: var(--mainColor);
					position: absolute;
					top: 0px;
					z-index: 2;
					height: 42px;
					left: 0;
				}
			}
		}
	}
}

.badge {
	background: #43d39e;
	color: #fff;
	padding: 3px 6px;
	margin-left: 5px;
}

.active-section {
	.active {
		&::after {
			content: "";
			background: #fbfbfb;
			width: 3px;
			position: absolute;
			top: -2px;
			height: 44px;
			left: 0px;
			z-index: 2;
		}
	}
}

.drop-gallery-thumb {
	width: 10%;
	margin-left: 16px;
	img {
		border-radius: 7px;
	}
}
.dropzone {
	display: flex;
	align-items: center;
	height: 197px;
	flex-direction: column;
}
.gallery-flex {
	display: flex;
}

.dropzone h3 {
	border-bottom: 1px solid #eee;
	width: 100%;
}

.dashboard-hamburger {
	position: absolute;
	top: -27px;
}

.banner-form-select-popularplacefilter {
	background: none;
	border: none;
	margin-left: 23px;
}

.listings-area-three {
	overflow: hidden;
}

.loginRegisterModal {
	.modal-dialog {
		.modal-content {
			.nav-tabs {
				.nav-item {
					&.react-tabs__tab--selected {
						.nav-link {
							&.active {
								&::before {
									-webkit-transform: scaleX(1);
									transform: scaleX(1);
								}
							}
						}
					}
				}
			}
		}
	}
}

.modal-content {
	.react-tabs__tab--selected {
		.nav-link {
			&:after {
				content: "";
				width: 100%;
				position: absolute;
				top: 34px;
				border-bottom: 3px solid var(--mainColor);
				left: 0;
			}
		}
	}
}

.responsive-burger-menu {
	.bx-x {
		font-size: 25px;
		margin-top: 10px;
	}
}

.main-content {
	.listing-area {
		.nav {
			.react-tabs__tab--selected {
				.nav-link {
					color: var(--mainColor);
				}
			}
		}
	}
}

.listing-area {
	.nav-item {
		cursor: pointer;
	}
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--whiteColor);
	z-index: 999999;
	.preloader {
		width: 100px;
		height: 100px;
		display: inline-block;
		padding: 0px;
		text-align: left;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -25px;
		margin-top: -25px;
		span {
			position: absolute;
			display: inline-block;
			width: 100px;
			height: 100px;
			border-radius: 100%;
			background-color: var(--mainColor) !important;
			-webkit-animation: preloader 1.3s linear infinite;
			animation: preloader 1.3s linear infinite;
			&:last-child {
				-webkit-animation-delay: -0.8s;
				animation-delay: -0.8s;
			}
		}
	}
}

@keyframes preloader {
	0% {
		-webkit-transform: scale(0, 0);
		transform: scale(0, 0);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
		opacity: 0;
	}
}

@-webkit-keyframes preloader {
	0% {
		-webkit-transform: scale(0, 0);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1, 1);
		opacity: 0;
	}
}    

.Typist {
	span {
		color: #0ec6c6;
	}
}
.text-lightblueColor{
	color:var(--lightblueColor)
}
