@import '../helpers/colors.scss';

$themes: (
  darkTheme: (
    'text-color': $color-white,
    'bg-color': $primary-dark-black-color,
    'bg-color-green-black': $primary-dark-black-color,
    // Nav
    'menu-header-name-color': $color-white,
    'sidebar-rectangle-bg-color': $seconday-dark-gray-color,
    'sidebar-rectangle-icon-color':#6A6A6A,
    'sidebar-menu-title-color':$text-sidebar,
    'sidebar-menu-title-hover-color':$text-color-white,
    'single-listing-bg-color':$seconday-dark-gray-color,
    'form-control-bg-color':$seconday-dark-gray-color,
    'protest-detail-bg-color':$seconday-dark-gray-color,
    'progress-bg-color':#e9ecef,
    'comps-card-dark-color': $comps-card-dark-color,
    'comps-card-trash-btn-bg-color': #e9e9e9,
    'comps-card-trash-btn-icon-color': $color-black,
    'user-actions-border-top-color': #cce5ff,
    'user-actions-box-shadow': 0 2px 7px 0 #fff,
    'stripe-element-bg-color':  $seconday-dark-gray-color,
    'stripe-input-element-color': $color-white,
    'property-report-invoice-instruction-icon-color': $seconday-dark-gray-color,
    'property-report-invoice-instruction-icon-bg-color': #eeeeee,
  ),
  lightTheme: (
    'text-color': $color-black,
    'bg-color': $color-white,
    'bg-color-green-black': $primary-dark-green-color,
    'menu-header-name-color': $color-black,
    'sidebar-rectangle-bg-color': #eee,
    'sidebar-menu-title-color': $light-theme-text-sidebar,
    'sidebar-menu-title-hover-color':$color-black,
    'single-listing-bg-color':$light-theme-secondary-gray-color,
    'form-control-bg-color':$light-theme-secondary-gray-color,
    'protest-detail-bg-color':$light-theme-secondary-gray-color,
    'progress-bg-color':$color-black,
    'comps-card-dark-color': $light-theme-secondary-gray-color,
    'comps-card-trash-btn-bg-color': red,
    'comps-card-trash-btn-icon-color': $color-white,
    'user-actions-border-top-color': #cce5ff,
    // 'user-actions-box-shadow': 0 2px 48px 0 #000,
    // 'user-actions-box-shadow': 0 2px 48px 0 rgb(0 0 0 / 8%),
    'user-actions-box-shadow': 0 2px 48px 0 rgba(0, 0, 0, 0.08),
    'stripe-element-bg-color': #eee,
    'stripe-input-element-color': #32325d,
    'property-report-invoice-instruction-icon-color': $color-white,
    'property-report-invoice-instruction-icon-bg-color': $info-color,

  )
);

@mixin theme() {
  @each $theme, $map in $themes {
    $theme-map: $map !global;
    .#{$theme} & {
      @content;
    }
  }
  $theme-map: null !global;
}

@function theme-get($key) {
  @return map-get($theme-map, $key);
}