$color-white: #fff;
$color-black: #000;
$pink-color: #F14D5D;
$light-blue-color: #8D99FF;
$primary-green-color: #118C4F;
$primary-dark-green-color: #147846;
$primary-light-green-color: #8ef1bf;
$primary-dark-black-color: #151617;
$seconday-light-red-color: #FF6A6A;
$seconday-dark-gray-color: #212324;
$seconday-dark-black-color: #000000;
$seconday-gray-disable-color: #D6D6D6;
$dark-gray-color: #5d4f4f;
$comps-card-dark-color: #212529;
$light-grey-color: #d6e0f1;
$light-purple-color: #c3bde0;

$logo-blue-color:#4400af;

$text-color-white: #ffffff;
$text-color-body: #AAAAAA;
$text-color-border-line: #DEDEDE;
$text-sidebar:#666666;
// Form Border color
$form-border-color: #DEDEDE;
$danger-color: #dc3545;
$info-color: #3498db;

$light-theme-rectangle-bg-color : #777;
$light-theme-text-color:#7e7e7e;
$light-theme-text-sidebar: #7e7e7e;
$light-theme-secondary-gray-color : #eee;

