@import '../../../assets/scss/helpers/colors.scss';
@import '../../../assets/scss/theme/theme-definition.scss';
.noAuthPageLayout {

    /*======================
    1.1 Space margins and padding
    ========================*/

    .no-margin {
        margin: 0;
    }

    .no-padding {
        padding: 0;
    }

    .full-width {
        width: 100%;
    }

    .section-padding {
        padding: 80px 0;
    }

    .padding-10 {
        padding: 10px;
    }

    .padding-tb-10 {
        padding: 10px 0;
    }

    .padding-15 {
        padding: 15px;
    }

    .padding-20 {
        padding: 20px;
    }

    .main-sec {
        height: 60px;
        clear: both;
        width: 100%;
    }

    .mb-xl-20 {
        margin-bottom: 20px;
    }

    .pb-xl-20 {
        padding-bottom: 20px;
    }

    .mb-20 {
        margin-bottom: 20px !important;
    }
    .mb-30 {
        margin-bottom: 30px !important;
    }
    .mb-45 {
        margin-bottom: 45px !important;
    }
    .mb-40 {
        margin-bottom: 40px !important;
    }
    .ml-20{
        margin-left: 20px !important;
    }
    .mtp-25rem{
        margin-top: 2.5rem !important;
    }
    .mt-20{
        margin-top: 20px !important;
    }
    .mt-30{
        margin-top: 30px !important;
    }
    .mt-40{
        margin-top: 40px !important;
    }
    .mt-50{
        margin-top: 50px !important;
    }

    /*======================
    2.1 Page Settings
    ========================*/

    .inner-wrapper {
        height: inherit;
        position: relative;
    }

    .main-padding {
        // padding: 64px 100px;
        padding: 33px 100px;
    }

    .section-2 {
        // height: 130vh;
        height: 100%;
    }

    .main-banner {
        // height: 100vh;
        // height: 100vh;

        .main-img {
            width: 100%;
            // height: 100%;
            width: auto;
            height: 750px;
            max-height: 100%;
            // object-fit: cover;
            // object-position: center;
        }

        .footer-img {
            position: absolute;
            bottom: 0;
            max-width: 340px;
            right: 0;
        }
    }
    .loginPage{
        .main-banner {
            height: 100%;
            // min-height: 750px;
            // height: 750px;
            // height: 120vh;
        }
        .section-2 {
            // height: 120vh;
        }
    }
    .RegisterPage{
        .main-banner {
            height: 100%;
            // height: 120vh;
        }
        .section-2 {
            // height: 120vh;
        }
    }

    .inner-wrapper .main-page {
        .top-nav {
            position: absolute;
            top: 64px;
            display: flex;
            right: 70px;

            h5 {
                margin-right: 30px;
            }
        }

        .login-box {
            height: 100%;
            display: flex;
            align-items: center;

            .input-group-prepend {
                height: 47px;
                min-width: 44px;
                flex-basis: 44px;
                border: 1px solid #8f8fa1;
                border-left: 0;
                border-radius: 0px 4px 4px 0;
                display: none;

                .input-group-text {
                    line-height: 1.5;
                    font-size: 20px;
                    background: transparent;
                    border: none;
                }
            }

            .input-group {
                .input-group2 {
                    display: flex;
                    padding: 0 8px;
                }

                margin: 0 -4px;
            }

            .input-group-append {
                padding: 0 8px;

                button {
                    white-space: normal;
                }
            }
        }
    }

    /*=========================
  8. Login & register
  ===========================*/
    /* .user-page .login-sec {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translate(0px, -50%);
  } */

    .user-page .login-sec .login-box {
        max-width: 425px;
        width: auto;
        margin: 0 auto;
        margin-top: 0px;

        /* box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08); */
        /* border-radius: 3px; */
        /* background: rgb(117, 116, 116); */
        padding: 30px;
        margin-left: 0px;

        .checkbox-reset {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        span.terms {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            a {
                margin: 0 3px;
            }
        }

        .form-group .field-icon {
            float: right;
            margin-left: -25px;
            margin-top: -28px;
            position: relative;
            z-index: 2;
            right: 18px;
            cursor: pointer;
        }
    }

    /*==================
  1.5. Section-Colors-&-Backgrounds
  ====================*/

    .bg-light-white {
        background-color: #f4f4f4;
    }

    .bg-light-theme {
        background-color: #fbfbfc;
    }

    .bg-light-green {
        background-color: #ff0018;
    }

    .bg-red {
        background-color: #ff0000;
    }

    .bg-gradient-red {
        background-image: linear-gradient(to right, #f20000, #a20000);
    }

    .bg-yellow {
        background-color: #ffc000;
    }

    .bg-green {
        background-color: #4aa110;
    }

    .bg-gradient-green {
        background-image: linear-gradient(to bottom, #499a14, #476d2d);
    }

    .bg-gradient-orange {
        background-image: linear-gradient(to bottom, #c55a11, #ed7d31);
    }

    .bg-black {
        background-color: #000;
    }

    .bg-login-page {
        // background-color: #151617;
        @include theme() {
            background-color: theme-get('bg-color');
        }
    }
    .text-theme{
        // color: red;
        @include theme() {
            color: #fb6100;
            // color: theme-get('text-color');

        }
    }
    /*Colors*/
    .text-light-black {
        color: #000000de;
    }

    .text-orange {
        color: #fb6100;
    }

    .text-custom-white {
        color: #ffffff;
    }

    .text-light-white {
        color: #6b6b83;
    }
    .text-white {
        color: #fff;
    }

    .text-dark-white {
        color: #00000059;
    }

    .text-yellow {
        color: #fcb71b;
    }

    .text-light-green {
        color: $light-blue-color;
    }

    .text-success {
        color: #13aa37;
    }
    .text-light-grey {
        color: #AAAAAA;
    }

    .fs-12 {
        font-size: 12px;
    }

    .fs-14 {
        font-size: 14px;
    }

    .fs-16 {
        font-size: 16px;
    }

    .fs-18 {
        font-size: 18px;
    }

    .fs-20 {
        font-size: 18px;
    }

    .fs-32 {
        font-size: 32px;
    }

    .fw-100 {
        font-weight: 100;
    }

    .fw-400 {
        font-weight: 400;
    }

    .fw-500 {
        font-weight: 500;
    }

    .fw-600 {
        font-weight: 600;
    }

    .fw-700 {
        font-weight: 900;
    }

    /*==================
    1.3. Forms
    ====================*/
    .form-group {
        margin-bottom: 25px;
    }

    label {
        margin-bottom: 12px;
        line-height: normal;
    }

    .form-control {
        // border-radius: 0;
        // background-color: #f5f5f5 !important;
        // box-shadow: unset !important;
        // transition: var(--transition);
        // border: none !important;
        height: 55px;
        padding-left: 15px;
        // color: var(--blackColor);
        font-size: 14.5px;
        // font-weight: 400;
        font-size: 1rem;
        font-weight: 400;
        // color: #fff;
        // background-color: #212324 !important;
        @include theme() {
            background: theme-get('single-listing-bg-color');
        }
        // background-color: #fff !important;
        // border: 1px solid #212324 !important;
        border: 1px solid #212324;

        -webkit-appearance: none;
        // border-radius: .25rem;
        border-radius: .50rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .form-control.is-invalid, .was-validated .form-control:invalid{
        border-color: #dc3545; 
    }

    .form-control::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #4D4D4D;
        padding-left: 5px;
        font-size: 14px;
        opacity: 1;
        /* Firefox */
    }

    .form-control:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #4D4D4D;
        padding-left: 5px;
        font-size: 14px;
    }

    .form-control::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #4D4D4D;
        padding-left: 5px;
        font-size: 14px;
    }
    .form-control:focus {
        // color: #fff;
        // border-color: #118C4F !important;
        // border-color: #118C4F ;
        // outline: 0;
        // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
    }
    .copyrights-area-no-auth{
        margin-top: 20px;
        @include theme() {
            background: theme-get('bg-color');
        }
        p{
            line-height: initial;
            color: #989898;
            margin-bottom: 5px;
        }
        li{
            a{
                color: $pink-color;
            }
        }
        .list-inline-item{
            margin-right: 0.5rem;
        }
    }
    // .form-control:focus {
    //     color: #495057;
    //     background-color: #fff;
    //     border-color: #495057bd;
    //     outline: 0;
    //     box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
    // }

    // input[type=text]::placeholder {
    //     color: red;
    //     font-size: 14px;
    // }

    // 4D4D4D

    /* .form-control-submit {
      border: 1px solid #8f8fa1;
      border-radius: 4px;
      padding: 10px 16px;
      height: 100%;
  } */
    /* label.custom-checkbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
  }
  label.custom-checkbox {
      display: block;
      position: relative;
      padding-left: 25px;
      margin-bottom: 10px;
      cursor: pointer;
      user-select: none;
      line-height: normal;
      font-size: 14px;
  }
  label.custom-checkbox:last-child {
      margin-bottom: 10px;
  } */
    /* Create a custom checkbox */

    /* label.custom-checkbox .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #d4d4db;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 4px;
  } */
    /* On mouse-over, add a grey background color */

    label.custom-checkbox {
        &:hover input~.checkmark {
            background-color: #fff;
            border: 1px solid #ff0018;
        }

        input:checked~.checkmark {
            background-color: #ff0018;
            border: 1px solid #ff0018;
        }
    }

    /* When the checkbox is checked, add a blue background */

    /* Create the checkmark/indicator (hidden when not checked) */

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */

    label.custom-checkbox {
        input:checked~.checkmark:after {
            display: block;
        }

        .checkmark:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 9px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    .btnLogin {
        background-color: $pink-color;
        color: #fff;
        height: 55px;
        border-color: $pink-color;
    }

    .btnResendEmail {
        background-color: $logo-blue-color;
        color: #fff;
        height: 55px;
        border-color: $logo-blue-color;
    }

    // Socilka
    .stretch-card>.card {
        width: 100%;
        min-width: 100%
    }

    body {
        background-color: #f9f9fa
    }

    .flex {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto
    }

    @media (max-width:991.98px) {
        .padding {
            padding: 1.5rem
        }
    }

    @media (max-width:767.98px) {
        .padding {
            padding: 1rem
        }
    }

    .padding {
        padding: 3rem
    }

    .fa {
        padding: 14px;
        // padding: 16px;
        // font-size: 30px;
        // width: 30px;
        text-align: center;
        text-decoration: none;
        margin: 5px 2px;
        border-radius: 50%;
        .bx{
            font-size: 22px;
            position: relative;
            top: 4px;
        }
    }

    .fa:hover {
        opacity: 0.7;
    }

    .fa-facebook {
        
        background: #212324;
        // background: #3B5998;
        color: white;
    }

    .fa-twitter {
        background: #55ACEE;
        color: white;
    }

    .fa-google {
        background: #212324;
        // background: #dd4b39;
        color: white;
    }



    /* Style the checkmark/indicator */
}
@media only screen and (min-width: 320px) and (max-width: 1024px) {
    .noAuthPageLayout{
        .main-padding{
            display: flex;
            justify-content: center;
            padding: 0px;
        }
        .main-banner {
        
            .main-img {
                height: 650px;
            }
        }
    } 
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
    .noAuthPageLayout{
        .main-padding{
          display: block;
          justify-content: unset;
          padding: 0px;
        }
    } 
}
.text-theme{
    // color: red;
    @include theme() {
        // color: #fb6100;
        color: theme-get('text-color');

    }
}
.form-control {
    @include theme() {
        color: theme-get('text-color');
        background: theme-get('single-listing-bg-color');
    }
}
.form-control:focus {
    @include theme() {
        color: theme-get('text-color');
    }
}